import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, DateAdapter } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CustomDateAdapter } from 'src/app/shared/utils/custom-date-adapter';
import { AstreintesService } from 'src/app/services/astreintes.service';

import { MatDividerModule } from '@angular/material/divider';
import { ArianeNavComponent } from 'src/app/layout/ariane-nav/ariane-nav.component';
import localeFr from '@angular/common/locales/fr';
import { RoleService } from 'src/app/services/role.service';
import { RestrictedPageComponent } from 'src/app/shared/restricted-page/restricted-page.component';
import { HeuresSupplémentairesService } from 'src/app/services/heures-supplémentaires.service';
import { Subscription, take, Observable, of } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Papa as PaParse } from 'ngx-papaparse';

import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { CommunicationService } from 'src/app/shared/communication.service';
import { Duration, DateTime } from 'luxon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from 'src/environments/environment';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AgentsService } from 'src/app/services/agents.service';
import { DirectionService } from 'src/app/services/direction.service';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TabService } from 'src/app/core/services/tab.service';
import { CampagnesService } from 'src/app/services/campagnes.service';
import { SelectionService } from 'src/app/shared/data-table/data-selection.service';
import { MatBadgeModule } from '@angular/material/badge';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { DeleteModalComponent } from 'src/app/shared/delete-modal/delete-modal.component';

registerLocaleData(localeFr);
import { ExcelService } from 'src/app/core/services/excel.service';
import { DetailValidationComponent } from '../../validation/detail-validation/detail-validation.component';

@Component({
  selector: 'app-consultation',
  standalone: true,
  imports: [
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    MatTableModule,
    CommonModule,
    DataTableComponent,
    MatExpansionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    ArianeNavComponent,
    RestrictedPageComponent,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    NgxMaterialTimepickerModule,
    MatRadioModule
  ],
  templateUrl: './archives-saisies.component.html',
  styleUrls: ['./archives-saisies.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' }
    }
  ]
})
export class ArchivesSaisiesComponent implements OnInit {
  showSpinner = false;
  pipe = new DatePipe('fr-FR');
  arianeLabel: string = 'Visualisation des saisies HS / Astreintes';
  arianeIcon: string = 'history';
  arianeList = [
    {
      label: 'Administration',
      url: '/administration'
    },
    {
      label: 'Visualisation des saisies HS / Astreintes',
      url: '/archives-saisies'
    }
  ];
  userRoles: string[] = [];
  userProfile: any = {};
  dataSource: any;
  total: number | undefined;
  page_size: number | undefined;
  loading = false;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  btSupprMasseActif: boolean = false;

  // heures supplémentaires
  rowHeureSup: any = {};
  dataJSON_HS: any[] = [];
  dataTable_HS: any[] = [];
  hsPaginationUrl = environment.webService.server + environment.webService.url + environment.webService.api + '/heures-supplementaires';
  hslenght: number;
  totalRecords_HS: number = 0;
  panelOpenState: boolean = false;

  // astreintes
  rowAstreinte: any = {};
  dataJSON_Ast: any[] = [];
  dataTable_Ast: any[] = [];
  astPaginationUrl = environment.webService.server + environment.webService.url + environment.webService.api + '/astreintes';
  astlenght: number;
  totalRecords_Ast: number = 0;
  panelOpenStateAst: boolean = false;

  // vue globale
  dataJSON_VG: any[] = [];
  dataTable_VG: any[] = [];
  dataJSON_VG_agent: any[] = [];
  filtreActif_VG: boolean = false;

  form_filtre_VG: FormGroup = this.formBuilder.group({
    direction: ['']
  });

  // heures supplémentaires
  boutonSaisirActif: boolean = false;
  boutonSaisirAstreinteActif: boolean = false;
  campagneEnCours: boolean = false;
  agentFormControl = new FormControl();
  filteredOptions: any = [];
  val_ft_agent: any = null;
  val_ft_typeHS: any = null;
  val_ft_duree: any = null;
  val_ft_emetteur: any = null;
  val_ft_directionOrigine: any = null;
  val_ft_directionOrganisatrice: any = null;
  val_ft_statut: any = null;
  val_ft_campagne: any = null;
  val_ft_campagne_prec: any = null;
  val_ft_serviceFonctionnel: any = null;
  val_ft_motif: any = null;
  agentsList: any = [];
  directionList: any[] = [];
  campagneList: any = [];
  campagneListAst: any = [];
  directionEventList: any[] = [];
  listDirectionOrigine: any = [];
  listCampagne: any = [];
  listCampagneAst: any = [];
  FiltreHSActif: boolean = false;
  filtrerHS_libelle$: Observable<any> = of('Filtres avancés');

  serviceFonctionnelList: any[] = [];
  listService: any = [];
  selectedTabIndex: number;

  form_filtre_HS: FormGroup = this.formBuilder.group({
    agent: [''],
    typeHS: [''],
    dateRealisation: [''],
    emetteur: [''],
    campagne: [''],
    directionOrigine: [''],
    directionOrganisatrice: [''],
    serviceFonctionnel: [''],
    statut: [''],
    dateCreation: [''],
    motif: [''],
    heure_debut: [''],
    heure_fin: [''],
    duree: ['']
  });
  isHs: any = true;

  @ViewChild('autoCompleteDirection', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;
  @ViewChild('autoCompleteAgent', { read: MatAutocompleteTrigger })
  autoCompAgent: MatAutocompleteTrigger;
  @ViewChild('autoCompleteEvent', { read: MatAutocompleteTrigger })
  autoCompleteEvent: MatAutocompleteTrigger;
  @ViewChild('autoCompleteCamp', { read: MatAutocompleteTrigger })
  autoCompleteCamp: MatAutocompleteTrigger;
  @ViewChild('autoCompleteService', { read: MatAutocompleteTrigger })
  autoCompleteService: MatAutocompleteTrigger;

  // astreintes
  filteredOptionsAst: any = [];
  val_ft_ast_agent: any = null;
  val_ft_ast_type: any = null;
  val_ft_ast_nombre: any = null;
  val_ft_ast_emetteur: any = null;
  val_ft_ast_directionOrigine: any = null;
  val_ft_ast_directionOrganisatrice: any = null;
  val_ft_ast_statut: any = null;
  val_ft_ast_campagne: any = null;
  val_ft_ast_campagne_prec: any = null;
  val_ft_ast_serviceFonctionnel: any = null;
  agentsListAst: any = [];
  directionListAst: any[] = [];
  directionEventListAst: any[] = [];
  listDirectionOrigineAst: any = [];
  FiltreAstActif: boolean = false;
  filtrerAst_libelle$: Observable<any> = of('Filtres avancés');

  form_filtre_Ast: FormGroup = this.formBuilder.group({
    agent: [''],
    type: [''],
    emetteur: [''],
    directionOrigine: [''],
    directionOrganisatrice: [''],
    statut: [''],
    dateCreation: [''],
    dateDebut: [''],
    dateFin: [''],
    nombre: [''],
    campagne: [''],
    serviceFonctionnel: ['']
  });

  @ViewChild('autoCompleteAstDirection', { read: MatAutocompleteTrigger })
  autoCompleteAstDirection: MatAutocompleteTrigger;
  @ViewChild('autoCompleteAstAgent', { read: MatAutocompleteTrigger })
  autoCompleteAstAgent: MatAutocompleteTrigger;
  @ViewChild('autoCompleteAstEvent', { read: MatAutocompleteTrigger })
  autoCompleteAstEvent: MatAutocompleteTrigger;
  @ViewChild('autoCompleteAstCampagne', { read: MatAutocompleteTrigger })
  autoCompleteAstCampagne: MatAutocompleteTrigger;
  @ViewChild('autoCompleteAstService', { read: MatAutocompleteTrigger })
  autoCompleteAstService: MatAutocompleteTrigger;

  heureFinerror: boolean = false;
  private subscription: Subscription;

  /*     @ViewChild('autoCompleteInput', {read : MatAutocompleteTrigger})
  autoComplete:QueryList<MatAutocompleteTrigger>  */

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private roleService: RoleService,
    private HS_service: HeuresSupplémentairesService,
    private astreintesService: AstreintesService,
    private refreshService: CommunicationService,
    private detectorRef: ChangeDetectorRef,
    private paParse: PaParse,
    private agentsService: AgentsService,
    private directionService: DirectionService,
    private campagneServie: CampagnesService,
    private broadcast: Broadcast,
    private tabService: TabService,
    private excelService: ExcelService,
    private selectionService: SelectionService
  ) {
    this.getUserProfile();
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.selectedTabIndex = this.tabService.getSelectedTabIndex();

    this.verifCampagne();

    this.roleService.getRole().subscribe((roles) => {
      this.userRoles = roles;

      // activation du bouton saisir heures sup

      let index = Object.keys(roles).find((val: any) => roles[val] === 'dhesa-admin' || roles[val] === 'dhesa-assistant-cds');

      if (this.campagneEnCours) {
        this.boutonSaisirActif = index != undefined ? true : false;
        this.boutonSaisirAstreinteActif = index != undefined ? true : false;
      }

      // affichage du bouton supprimer en masse
      if (roles.includes('dhesa-admin')) {
        this.btSupprMasseActif = true;
      }

      // affichage du filtre de la vue globale
      let index_VG = Object.keys(roles).find(
        (val: any) => roles[val] === 'dhesa-admin' || roles[val] === 'dhesa-dg' || roles[val] === 'dhesa-gestionnaire-paie'
      );
      this.filtreActif_VG = index_VG != undefined ? true : false;
    });
    this.getListe_HS();
    this.getListe_astreinte();
    this.loadAgents();
    this.loadDirectionList();
    this.loadserviceFonctionnel();
    this.loadCampagneList();
  }
  showDetailsSaisieArchiveeHs(id: number) {
    this.dialog.open(DetailValidationComponent, {
      data: {
        type: 'hs',
        id: id
      },
      width: '960px',
      maxHeight: '800px'
    });
  }
  showDetailsSaisieArchiveeAstreintes(id: number) {
    this.dialog.open(DetailValidationComponent, {
      data: {
        type: 'astreinte',
        id: id
      },
      width: '960px',
      maxHeight: '800px'
    });
  }
  effaceFiltreCampagneAstreinte(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_Ast.controls[controlName].setValue('');
      this.loadCampagneList();
    }
  }

  scrollEvent = (event: any): void => {
    if (this.autoComplete.panelOpen) {
      this.autoComplete.updatePosition();
    }
    if (this.autoCompAgent.panelOpen) {
      this.autoCompAgent.updatePosition();
    }
    if (this.autoCompleteEvent.panelOpen) {
      this.autoCompleteEvent.updatePosition();
    }

    if (this.autoCompleteCamp.panelOpen) {
      this.autoCompleteCamp.updatePosition();
    }
    if (this.autoCompleteService.panelOpen) {
      this.autoCompleteService.updatePosition();
    }
  };
  // focusinmethod(){
  //   let b = document.body;
  //   b.style.overflow = "hidden";
  // }
  // focusoutmethod(){
  //   let b = document.body;
  //   b.style.overflow = "auto";
  // }

  // vérifie si une campagne est en cours
  verifCampagne() {
    let campagneActuelle: any = sessionStorage.getItem('campagneActuelle');
    let _campagneActuelle = JSON.parse(campagneActuelle);

    this.campagneEnCours = _campagneActuelle?.type == 'SAISIE_EN_COURS' ? true : false;

    // activation des boutons
    this.boutonSaisirActif = this.campagneEnCours;

    this.boutonSaisirAstreinteActif = this.campagneEnCours;
  }

  // récupération du profil utilisateur dans la session
  getUserProfile() {
    const ss_userProfile: any = sessionStorage.getItem('userProfile');
    this.userProfile = JSON.parse(ss_userProfile);
  }

  /*********************** HEURES SUPPLEMENTAIRES ***********************/

  hourDisplayedColumns: string[] = [
    /**
     * Dans la sous-liste plutôt
     */
    //'Campagne',

    'Agent',
    'Matricule',
    'Type',
    'Date création',
    'Date HS',
    'Heure début',
    'Heure fin',
    'Durée',
    'Emetteur',
    'Direction origine',
    'Direction évènement',
    'Motif',
    'Statut',
    'Actions'
  ];

  subTableDisplayedColumns: string[] = ['Campagne', 'Service origine'];

  hourCellStyleCss: any = {
    Statut: { 'text-align': 'center' },
    'Date HS': { 'text-align': 'center' },
    'Heure début': { 'text-align': 'center' },
    'Heure fin': { 'text-align': 'center' }
  };

  hourHeaderStyleCssSub: any = {
    Date: { 'text-align': 'center' },
    'Date création': { 'text-align': 'center' },
    'Heure début': { 'text-align': 'center' },
    'Heure fin': { 'text-align': 'center' },
    Durée: { 'text-align': 'center' }
  };

  hourCellStyleCssSub: any = {
    'Date HS': { 'text-align': 'center' }
  };

  // lecture des données pour les HS
  getListe_HS() {
    this.showSpinner = true;

    this.HS_service.getArchiveHSWithParams(environment.pagination.offset, environment.pagination.limit, this.filtrerRecherche_HS())
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.totalRecords_HS = result.totalRecords;
          this.dataJSON_HS = result.results;
          this.hslenght = result.totalRecords;
          this.dataTable_HS = this.transformData_HS();
          this.detectorRef.markForCheck();
        } else {
          console.log('error');
        }
      });
  }

  // recherche uniquement la campagne
  rechercheCampagne_HS(event: any) {
    if (event) {
      let val = event.target.value;
      let val_obs = this.val_ft_campagne;
      let val_prec = this.val_ft_campagne_prec;

      if (val && val != '' && val_prec?.id != val_obs?.id) {
        this.getListe_HS();
        this.val_ft_campagne_prec = this.val_ft_campagne;
      }
    }
  }

  // efface tous les filtre sauf la campagne
  resetRecherche_HS() {
    this.form_filtre_HS.reset();

    this.getListe_HS();
  }

  recherche_HS() {
    this.getListe_HS();
  }

  // concaténation des filtres de recherche
  filtrerRecherche_HS() {
    let _filtre: any = {};

    let agent = this.form_filtre_HS.controls['agent'].value;

    if (agent?.id != undefined) {
      _filtre['agent.nom'] = agent?.nom;
      _filtre['agent.nom'] += '&agent.prenom=' + agent?.prenom;
    }

    _filtre['typeHS'] = this.form_filtre_HS.controls['typeHS'].value;

    let dateCreation = this.form_filtre_HS.controls['dateCreation'].value;
    let dateCreation_dt = DateTime.fromJSDate(dateCreation).isValid ? DateTime.fromJSDate(dateCreation) : DateTime.fromISO(dateCreation);
    if (dateCreation_dt.isValid) {
      _filtre['dateCreation'] = dateCreation_dt.toFormat('yyyy-MM-dd') + 'T00:00:00';
      _filtre['dateCreation'] += '&dateCreation=' + dateCreation_dt.toFormat('yyyy-MM-dd') + 'T23:59:59'; // 2ème filtre pour rechercher l'égalité de la date
    }

    let dateRealisation = this.form_filtre_HS.controls['dateRealisation'].value;
    let dateRealisation_dt = DateTime.fromJSDate(dateRealisation).isValid
      ? DateTime.fromJSDate(dateRealisation)
      : DateTime.fromISO(dateRealisation);
    if (dateRealisation_dt.isValid) {
      _filtre['dateRealisation'] = dateRealisation_dt.toFormat('yyyy-MM-dd');
      _filtre['dateRealisation'] += '&dateRealisation=' + dateRealisation_dt.toFormat('yyyy-MM-dd'); // 2ème filtre pour rechercher l'égalité de la date
    }

    let heureDebut = this.form_filtre_HS.controls['heure_debut'].value;
    _filtre['heureDebut'] = heureDebut;

    let heureFin = this.form_filtre_HS.controls['heure_fin'].value;
    _filtre['heureFin'] = heureFin;

    let duree_val = this.form_filtre_HS.controls['duree'].value;
    if (duree_val != '' && duree_val != null) {
      let _duree = duree_val.split(':').map((e: string) => {
        return parseInt(e);
      });
      _filtre['duree'] = _duree.join(':');
    }

    _filtre['searchByEmetteur'] = this.form_filtre_HS.controls['emetteur'].value;

    let campagne = this.form_filtre_HS.controls['campagne'].value;

    _filtre['campagne.id'] = campagne?.id != undefined ? campagne?.id : '';

    let directionOrigine = this.form_filtre_HS.controls['directionOrigine'].value;
    _filtre['directionOrigine'] = directionOrigine?.id != undefined ? directionOrigine?.id : '';

    let directionOrganisatrice = this.form_filtre_HS.controls['directionOrganisatrice'].value;
    _filtre['directionOrganisatrice'] = directionOrganisatrice?.id != undefined ? directionOrganisatrice?.id : '';

    let serviceFonctionnel = this.form_filtre_HS.controls['serviceFonctionnel'].value;
    _filtre['serviceFonctionnel'] = serviceFonctionnel?.id != undefined ? serviceFonctionnel?.id : '';

    _filtre['motif'] = this.form_filtre_HS.controls['motif'].value;
    _filtre['statut'] = this.form_filtre_HS.controls['statut'].value;

    let _arg: any = [];

    for (let key in _filtre) {
      if (_filtre[key] != undefined && _filtre[key] != '') {
        _arg.push(key + '=' + _filtre[key]);
      }
    }

    let filtres = _arg.join('&');

    let filtre_actif = Object.keys(_filtre).filter((k) => _filtre[k] != '' && _filtre[k] != null);
    let nb_filtre_actif = filtre_actif.length; // on déduit le filtre campagne
    if (nb_filtre_actif > 0) {
      this.FiltreHSActif = true;
      this.filtrerHS_libelle$ = of('Filtré (' + nb_filtre_actif + ')');
    } else {
      this.FiltreHSActif = false;
      this.filtrerHS_libelle$ = of('Filtres avancés');
    }

    return filtres;
  }

  // efface le filtre avec la croix
  effaceFiltre(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_HS.controls[controlName].setValue('');
      this.loadserviceFonctionnel();
    }
  }
  effaceFiltreCampagneHS(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_HS.controls[controlName].setValue('');

      this.loadserviceFonctionnel();
    }
  }
  effaceAgentFiltre(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_HS.controls[controlName].setValue('');
      this.loadAgents();
    }
  }
  effaceDirectionFiltre(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_HS.controls[controlName].setValue('');
      this.loadDirectionList();
    }
  }
  effaceCampagneFiltre(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_HS.controls[controlName].setValue('');
      this.loadCampagneList();
    }
  }

  effaceEventFiltre(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_HS.controls[controlName].setValue('');
      this.loadDirectionList();
    }
  }

  agentdisplayFn(agent: any): string {
    return agent ? `${agent.nom} ${agent.prenom}` : '';
  }

  // /*   directionOriginedisplayFn(item: any): any {
  //   item ? `${item.libelle}` : '';
  // } */
  /*   directionEvenementdisplayFn(item: any): any {
    item ? `${item.libelle}` : '';
  } */
  /*  servicedisplayFn(element: any): any {
    element ? `${element.libelle}` : '';
  } */

  agentFilter(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.filteredOptions = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.filteredOptions = this.agentsList.filter((agent: any) =>
      (agent.nom + agent.prenom).toLowerCase().split(' ').join('').includes(filterValue)
    );
  }
  directionOrigineFilter(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.directionList = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.directionList = this.listDirectionOrigine.filter((item: any) =>
      item.libelle.toLowerCase().split(' ').join('').includes(filterValue)
    );
  }
  campagneFilter(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.campagneList = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.campagneList = this.listCampagne.filter((campagne: any) => campagne.nom.toLowerCase().split(' ').join('').includes(filterValue));
  }
  campagneFilterAst(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.campagneListAst = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.campagneListAst = this.listCampagneAst.filter((campagne: any) =>
      campagne.nom.toLowerCase().split(' ').join('').includes(filterValue)
    );
  }

  directionEvenementFilter(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.directionEventList = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.directionEventList = this.listDirectionOrigine.filter((item: any) =>
      item.libelle.toLowerCase().split(' ').join('').includes(filterValue)
    );
  }
  serviceFilter(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.serviceFonctionnelList = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.serviceFonctionnelList = this.listService.filter((element: any) =>
      element.libelle.toLowerCase().split(' ').join('').includes(filterValue)
    );
  }
  changedurationfilter() {
    const commonDate = DateTime.now();
    const startDateTime = DateTime.fromFormat(
      `${commonDate.toISODate()}T${this.form_filtre_HS.get('heure_debut')?.value}`,
      "yyyy-MM-dd'T'HH:mm"
    );
    let endDateTime = DateTime.fromFormat(`${commonDate.toISODate()}T${this.form_filtre_HS.get('heure_fin')?.value}`, "yyyy-MM-dd'T'HH:mm");
    if (startDateTime.isValid && endDateTime.isValid) {
      // Si l'heure de fin est antérieure à l'heure de début, ajoute un jour à l'heure de fin
      if (endDateTime <= startDateTime) {
        this.heureFinerror = true;
      } else {
        this.heureFinerror = false;
      }
    }
  }

  loadAgents() {
    this.agentsService
      .getAllAgents()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.agentsList = data /*.results*/;
          this.filteredOptions = [...this.agentsList];
        } else {
          console.log('error');
        }
      });
  }

  loadDirectionList() {
    this.directionService
      .getAllDirections()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          console.log('listDirectionOrigine', data);
          this.listDirectionOrigine = data.filter((o: any) => {
            if (o.libelle !== '') {
              return o;
            }
          });
          console.warn(this.listDirectionOrigine);

          this.directionList = [...this.listDirectionOrigine];
          this.directionEventList = [...this.listDirectionOrigine];
        } else {
          console.log('error');
        }
      });
  }
  loadCampagneList() {
    this.campagneServie
      .getAllCampagnes()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.listCampagne = data.results;
          this.campagneList = [...this.listCampagne];
          this.listCampagneAst = data.results;
          this.campagneListAst = [...this.listCampagneAst];
        } else {
          console.log('error');
        }
      });
  }
  directionOriginFilter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.directionList.filter((direction) => direction.libelle.toLowerCase().includes(filterValue));
  }
  directionOriginedisplayFn(direction?: any): string {
    return direction ? direction.libelle : undefined;
  }
  // campagnedisplayFn(value: string): any[] {
  //   const filterValue = value.toLowerCase();
  //   return this.campagneList.filter((campagne) => campagne.nom.toLowerCase().includes(filterValue));
  // }
  campagnedisplayFn(campagne?: any): string {
    return campagne ? campagne.nom : undefined;
  }

  directionEvenementdisplayFn(event?: any): any {
    return event ? event.libelle : undefined;
  }
  servicedisplayFn(element?: any): any {
    return element ? element.libelle : undefined;
  }
  onDirectionOrigineselected(event: MatAutocompleteSelectedEvent): void {
    this.form_filtre_HS.get('directionOrganisatrice')?.setValue(event.option.value.libelle);
  }

  loadserviceFonctionnel() {
    this.directionService
      .getAllServices()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.listService = data.filter((o: any) => {
            if (o.libelle !== '') {
              return o;
            }
          });

          this.serviceFonctionnelList = this.listService;
        } else {
          console.log('error');
        }
      });
  }

  transformData_HS(data = null, data2?: any) {
    if (data) {
      this.dataJSON_HS = data;
    }

    // data pour l'export
    let dataJSON = data2 ? data2 : this.dataJSON_HS;

    let dataHS: any[] = [];
    let roles = this.userRoles;
    let profil_admin = roles.includes('dhesa-admin');
    /*let profil_assistant_cds = roles.includes('dhesa-assistant-cds');
    let profil_assistant_responsable = roles.includes('dhesa-assistant-responsable');
    let profil_assistant_directeur = roles.includes('dhesa-assistant-directeur');
    let username = this.userProfile?.username;*/

    for (let key in dataJSON) {
      let dataJ = dataJSON[key];
      let agent = dataJ?.agent;
      let emetteur = '';
      if (dataJ?.emetteur?.nom && dataJ?.emetteur?.prenom) {
        emetteur = dataJ?.emetteur?.nom + ' ' + dataJ?.emetteur?.prenom;
      }
      let _data: any = {};

      _data['id'] = dataJ?.id;
      /**
       * On ajoute la campagne dans la sous-liste
       */

      _data['Agent'] = agent?.nom + ' ' + agent?.prenom;
      _data['Matricule'] = agent?.matricule;

      let typeCode = dataJ?.typeHS;
      let typeText = '';
      if (typeCode == 'FERIE') {
        typeText = 'Dim./Férié';
      } else if (typeCode == 'NUIT') {
        typeText = 'Nuit';
      } else if (typeCode == 'JOUR') {
        typeText = 'Jour';
      }
      _data['Type'] = typeText;
      if (!data) {
        _data['Date création'] = this.pipe.transform(dataJ?.dateCreation, 'shortDate');
        _data['Date HS'] = this.pipe.transform(dataJ?.dateRealisation, 'shortDate');
      }
      _data['Heure début'] = dataJ?.heureDebut.slice(0, -3);

      // arrondit les secondes en minutes
      let _heure_fin = dataJ?.heureFin.split(':').map((a: any) => Number(a));
      if (_heure_fin[2] != undefined && _heure_fin[2] > 30) {
        _heure_fin[2] = 0;
        if (_heure_fin[1] == 59) {
          _heure_fin[1] = 0;
          _heure_fin[0] = _heure_fin[0] == 23 ? 0 : _heure_fin[0] + 1;
        } else {
          _heure_fin[1] += 1;
        }
      }
      let d_heure_fin = Duration.fromObject({ hours: _heure_fin[0], minutes: _heure_fin[1], seconds: _heure_fin[2] });
      _data['Heure fin'] = d_heure_fin.toFormat('hh:mm');

      let _duree: any = dataJ?.duree.split(':');
      let hr = _duree[0].toString().length == 1 ? '0' + _duree[0] : _duree[0];
      let min = _duree[1].toString().length == 1 ? '0' + _duree[1] : _duree[1];
      _data['Durée'] = hr + ':' + min;

      _data['Emetteur'] = emetteur;
      _data['Direction origine'] = dataJ?.directionOrigine?.libelle;
      _data['Direction évènement'] = dataJ?.directionOrganisatrice?.libelle;
      _data['Motif'] = dataJ?.motif;

      let status_code = dataJ?.statut;
      let status_text = '';
      let status_class = '';

      if (status_code == 'A_VALIDER') {
        status_class = 'badge-gray';
        status_text = 'Avis non renseigné';
      } else if (status_code == 'VALIDEES') {
        status_class = 'badge-green-directeur';
        status_text = 'Avis favorable - Directeur';
      } else if (status_code == 'REFUSEES') {
        status_class = 'badge-red';
        status_text = 'Avis défavorable - Directeur';
      } else if (status_code == 'VALIDEES_N2') {
        status_class = 'badge-green-dga';
        status_text = 'Avis favorable - DG';
      } else if (status_code == 'REFUSEES_N2') {
        status_class = 'badge-red';
        status_text = 'Avis défavorable - DG';
      } else if (status_code == 'SAISIE_A_RENOUVELER') {
        status_class = 'badge-gray';
        status_text = 'Saisie à renouveler';
      } else if (status_code == 'A_VALIDER_RESPONSABLE') {
        status_class = 'badge-gray';
        status_text = 'Avis non renseigné (responsable)';
      } else if (status_code == 'VALIDEES_RESPONSABLE') {
        status_class = 'badge-green-directeur';
        status_text = 'Avis favorable - Responsable';
      } else if (status_code == 'REFUSEES_RESPONSABLE') {
        status_class = 'badge-red';
        status_text = 'Avis défavorable - Responsable';
      }

      _data['Statut'] = { class: status_class, text: status_text, object: true };
      _data['Statut_export'] = status_text; // champ pour la fonction export

      // activation du bouton éditer

      _data['editer_hidden'] = true;
      _data['delete_hidden'] = true;
      /*if (
        (profil_admin ||
          ((profil_assistant_cds || profil_assistant_responsable || profil_assistant_directeur) && dataJ?.emetteur?.login?.toUpperCase() == username?.toUpperCase())) &&
        this.campagneEnCours
      ) {
        _data['editer_hidden'] = false;
        _data['delete_hidden'] = false;
      }*/

      // désactivation du bouton supprimer si pas de campagne de type SAISIE
      /*if (!this.campagneEnCours) {
        _data['delete_hidden'] = true;
      }*/

      if (profil_admin) {
        _data['delete_hidden'] = false;
      }

      _data['subTable'] = [];
      let _subTable: any = {};

      _subTable['Campagne'] = dataJ?.campagne.nom;
      _subTable['Service origine'] = dataJ?.serviceFonctionnel?.libelle;

      _data['subTable'].push(_subTable);
      dataHS.push(_data);
    }

    return dataHS;
  }

  modaleConfirmSuppressionHS(type: string, nb: number) {
    let content = '';
    let content2 = '';

    if (type == 'hs') {
      content = 'La saisie HS a été supprimée avec succès ';
      content2 = `(${nb} HS supprimée(s)).`; // concernant x agent(s)
    } else {
      content = 'La saisie Astreinte a été supprimée avec succès ';
      content2 = `(${nb} astreinte(s) supprimée(s)).`; // concernant x agent(s)
    }

    this.dialog.open(ValidationModalComponent, {
      width: '540px',
      data: {
        title: 'Confirmation',
        content: content,
        content2: content2
      }
    });
  }

  // recherche la ligne des heures sup correspondant à l'id
  heureSupDataID(data: any, id: number) {
    let index = Object.keys(data).find((k) => data[k].id === id);

    if (index != null) {
      this.rowHeureSup = data[Number(index)];
    }
  }

  // exportation des lignes heures supp en CSV
  export_HS() {
    this.HS_service.getArchiveHSWithParams(0 /*page*/, this.totalRecords_HS /*size*/, this.filtrerRecherche_HS())
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          let dataTable_HS = this.transformData_HS(null, result.results);

          let _data_export: any[] = [];

          dataTable_HS.forEach((_ligne: any) => {
            let _data = _ligne;
            if (_ligne['subTable'] != undefined) {
              _data = { ..._data, ..._ligne['subTable'][0] };
              delete _data['subTable'];
            }
            delete _data['id'];
            delete _data['delete_hidden'];
            delete _data['editer_hidden'];
            _data['Statut'] = _data['Statut_export'];
            delete _data['Statut_export'];

            _data_export.push(_data);
          });

          this.convertExcel(_data_export, 'export_donnees_saisies_HS_');
        },
        error: (error) => {
          console.log('error:', error);
          let erreur_texte = error.statusText || error.error.detail || error.error || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Une erreur est survenue lors de l'export des heures supplémentaires.",
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  exporterLigne_HS(id: any) {
    let index = Object.keys(this.dataJSON_HS).find((k) => this.dataJSON_HS[Number(k)].id === id);

    if (index != null) {
      let result = this.dataJSON_HS[Number(index)];
      let _ligne: any = this.transformData_HS(null, [result])[0];
      let _data = _ligne;
      let _data_export: any[] = [];

      if (_ligne['subTable'] != undefined) {
        _data = { ..._data, ..._ligne['subTable'][0] };
        delete _data['subTable'];
      }
      delete _data['id'];
      delete _data['delete_hidden'];
      delete _data['editer_hidden'];
      _data['Statut'] = _data['Statut_export'];
      delete _data['Statut_export'];

      _data_export.push(_data);

      let agent = '';
      if (result?.agent?.nom != undefined) {
        agent = result?.agent?.nom + ' ' + result?.agent?.prenom;
        agent = agent.replace(' ', '_').toUpperCase() + '_';
      }

      this.convertExcel(_data_export, 'export_donnees_saisies_HS_' + agent);
    }
  }

  convertExcel(data: any, name: string) {
    const search = '/';
    const replaceWith = '-';
    const date = new Date().toLocaleDateString('en-GB').split(search).join(replaceWith);
    let fileName = `${name}${date}`;
    this.excelService.exportToExcel(data, `${fileName}.xlsx`, 'Sheet1');
  }

  exportPdf_HS(id: any) {
    this.HS_service.getPdf(id)
      .pipe(take(1))
      .subscribe({
        next: (result: any) => {
          const blob = new Blob([result], { type: 'application/pdf' });

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const search = '/';
          const replaceWith = '-';
          const date = new Date().toLocaleDateString('en-GB').split(search).join(replaceWith);
          let fileName = `hs_${date}`;
          link.download = fileName;

          // Append the link to the document
          document.body.appendChild(link);

          // Trigger the click event to start the download
          link.click();

          // Remove the link from the document
          document.body.removeChild(link);
        },
        error: (error) => {}
      });
  }

  /*********************** ASTREINTES ***********************/

  // lecture des données pour les astreintes
  getListe_astreinte() {
    this.showSpinner = true;

    this.astreintesService
      .getAstreinteArchiveWithParams(environment.pagination.offset, environment.pagination.limit, this.filtrerRecherche_astreinte())
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.totalRecords_Ast = result.totalRecords;
          this.dataJSON_Ast = result.results;
          this.astlenght = result.totalRecords;
          this.dataTable_Ast = this.transformData_astreintes();
          this.detectorRef.markForCheck();
        } else {
          console.log('error');
        }
      });
  }

  transformData_astreintes(data = null, data2?: any) {
    if (data) {
      this.dataJSON_Ast = data;
    }

    // data pour l'export
    let dataJSON = data2 ? data2 : this.dataJSON_Ast;

    let dataHS: any[] = [];
    let roles = this.userRoles;
    let profil_admin = roles.includes('dhesa-admin');
    /*let profil_assistant_cds = roles.includes('dhesa-assistant-cds');
    let profil_assistant_responsable = roles.includes('dhesa-assistant-responsable');
    let profil_assistant_directeur = roles.includes('dhesa-assistant-directeur');
    let username = this.userProfile?.username;*/

    for (let key in dataJSON) {
      let dataJ = dataJSON[key];
      let agent = dataJ?.agent;
      let emetteur = '';
      if (dataJ?.emetteur?.nom && dataJ?.emetteur?.prenom) {
        emetteur = dataJ?.emetteur?.nom + ' ' + dataJ?.emetteur?.prenom;
      }
      let _data: any = {};

      _data['id'] = dataJ?.id;
      /**
       * On ajoute la campagne dans la sous-liste
       */

      _data['Agent'] = agent?.nom + ' ' + agent?.prenom;
      _data['Matricule'] = agent?.matricule;

      let typeCode = dataJ?.type;
      let typeText = '';
      if (typeCode == 'JOUR') {
        typeText = 'Jour';
      } else if (typeCode == 'NUIT') {
        typeText = 'Nuit';
      } else if (typeCode == 'SEMAINE') {
        typeText = 'Semaine';
      } else if (typeCode == 'DIMANCHE_FERIE') {
        typeText = 'Dimanche/J.F';
      } else if (typeCode == 'CRECHE') {
        typeText = 'Crèche';
      } else if (typeCode == 'SAMEDI') {
        typeText = 'Samedi';
      } else if (typeCode == 'WEEKEND') {
        typeText = 'Week-end';
      }

      _data['Type'] = typeText;

      if (!data) {
        _data['Date création'] = this.pipe.transform(dataJ?.dateCreation, 'shortDate');
        _data['Date début'] = this.pipe.transform(dataJ?.dateDebut, 'shortDate');
        _data['Date fin'] = this.pipe.transform(dataJ?.dateFin, 'shortDate');
      }

      _data['Nombre'] = dataJ?.nombre;
      _data['Emetteur'] = emetteur;
      _data['Direction origine'] = dataJ?.directionOrigine?.libelle;
      _data['Direction évènement'] = dataJ?.directionOrganisatrice?.libelle;

      let status_code = dataJ?.statut;
      let status_text = {};
      if (status_code == 'A_VALIDER') {
        status_text = { class: 'badge-gray', text: 'Avis non renseigné', object: true };
      } else if (status_code == 'VALIDEES') {
        status_text = { class: 'badge-green-directeur', text: 'Avis favorable - Directeur', object: true };
      } else if (status_code == 'REFUSEES') {
        status_text = { class: 'badge-red', text: 'Avis défavorable - Directeur', object: true };
      } else if (status_code == 'VALIDEES_N2') {
        status_text = { class: 'badge-green-dga', text: 'Avis favorable - DG', object: true };
      } else if (status_code == 'REFUSEES_N2') {
        status_text = { class: 'badge-red', text: 'Avis défavorable - DG', object: true };
      } else if (status_code == 'SAISIE_A_RENOUVELER') {
        status_text = { class: 'badge-gray', text: 'Saisie à renouveler', object: true };
      } else if (status_code == 'A_VALIDER_RESPONSABLE') {
        status_text = { class: 'badge-gray', text: 'Avis non renseigné (responsable)', object: true };
      } else if (status_code == 'VALIDEES_RESPONSABLE') {
        status_text = { class: 'badge-green-directeur', text: 'Avis favorable - Responsable', object: true };
      } else if (status_code == 'REFUSEES_RESPONSABLE') {
        status_text = { class: 'badge-red', text: 'Avis défavorable - Responsable', object: true };
      }
      _data['Statut'] = status_text;

      // activation du bouton éditer

      _data['editer_hidden'] = true;
      _data['delete_hidden'] = true;
      /*if (
        (profil_admin ||
          ((profil_assistant_cds || profil_assistant_responsable || profil_assistant_directeur) && dataJ?.emetteur?.login?.toUpperCase() == username?.toUpperCase())) &&
        this.campagneEnCours
      ) {
        _data['editer_hidden'] = false;
        _data['delete_hidden'] = false;
      }*/

      // désactivation du bouton supprimer si pas de campagne de type SAISIE
      /*if (!this.campagneEnCours) {
      _data['delete_hidden'] = true;
      }*/

      if (profil_admin) {
        _data['delete_hidden'] = false;
      }

      _data['subTable'] = [];
      let _subTable: any = {};

      _subTable['Campagne'] = dataJ?.campagne.nom;
      _subTable['Service origine'] = dataJ?.serviceFonctionnel?.libelle;
      _subTable['Motif'] = dataJ?.motif;

      _data['subTable'].push(_subTable);
      dataHS.push(_data);
    }

    return dataHS;
  }

  // recherche la ligne des astreintes correspondant à l'id
  astreinteDataID(data: any, id: number) {
    let index = Object.keys(data).find((k) => data[k].id === id);

    if (index != null) {
      this.rowAstreinte = this.dataJSON_Ast[Number(index)];
    }
  }

  updatePaginationAstreinte(event: any) {
    if (event) {
      this.astreintesService
        .getAstreinteArchiveWithParams(event.offset, event.limit, this.filtrerRecherche_astreinte())
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.dataJSON_Ast = result.results;
            this.astlenght = result.totalRecords;
            this.dataTable_Ast = this.transformData_astreintes();
            this.detectorRef.markForCheck();
          } else {
            console.log('error');
          }
        });
    } else {
      this.astreintesService
        .getAstreinteArchiveWithParams(environment.pagination.offset, environment.pagination.limit, this.filtrerRecherche_astreinte())
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.dataJSON_Ast = result.results;
            this.astlenght = result.totalRecords;
            this.dataTable_Ast = this.transformData_astreintes();
            this.detectorRef.markForCheck();
          } else {
            console.log('error');
          }
        });
    }
  }
  exportPdf_Astreinte(id: any) {
    this.astreintesService
      .getPdfAstreinte(id)
      .pipe(take(1))
      .subscribe({
        next: (result: any) => {
          console.log(result);
          const blob = new Blob([result], { type: 'application/pdf' });

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const search = '/';
          const replaceWith = '-';
          const date = new Date().toLocaleDateString('en-GB').split(search).join(replaceWith);
          let fileName = `astreinte_${date}`;
          link.download = fileName;

          // Append the link to the document
          document.body.appendChild(link);

          // Trigger the click event to start the download
          link.click();

          // Remove the link from the document
          document.body.removeChild(link);
        },
        error: (error) => {}
      });
  }

  // recherche uniquement la campagne
  rechercheCampagne_astreinte(event: any) {
    if (event) {
      let val = event.target.value;
      let val_obs = this.val_ft_ast_campagne;
      let val_prec = this.val_ft_ast_campagne_prec;

      if (val && val != '' && val_prec?.id != val_obs?.id) {
        this.getListe_astreinte();
        this.val_ft_ast_campagne_prec = this.val_ft_ast_campagne;
      }
    }
  }

  // efface tous les filtre sauf la campagne
  resetRecherche_astreinte() {
    this.form_filtre_Ast.reset();

    this.recherche_astreinte();
  }

  recherche_astreinte() {
    this.getListe_astreinte();
  }

  // concaténation des filtres de recherche
  filtrerRecherche_astreinte() {
    let _filtre: any = {};

    let agent = this.form_filtre_Ast.controls['agent'].value;

    if (agent?.id != undefined) {
      _filtre['agent.nom'] = agent?.nom;
      _filtre['agent.nom'] += '&agent.prenom=' + agent?.prenom;
    }

    _filtre['type'] = this.form_filtre_Ast.controls['type'].value;

    let dateCreation = this.form_filtre_Ast.controls['dateCreation'].value;
    let dateCreation_dt = DateTime.fromJSDate(dateCreation).isValid ? DateTime.fromJSDate(dateCreation) : DateTime.fromISO(dateCreation);
    if (dateCreation_dt.isValid) {
      _filtre['dateCreation'] = dateCreation_dt.toFormat('yyyy-MM-dd') + 'T00:00:00';
      _filtre['dateCreation'] += '&dateCreation=' + dateCreation_dt.toFormat('yyyy-MM-dd') + 'T23:59:59'; // 2ème filtre pour rechercher l'égalité de la date
    }

    let dateDebut = this.form_filtre_Ast.controls['dateDebut'].value;
    let dateDebut_dt = DateTime.fromJSDate(dateDebut).isValid ? DateTime.fromJSDate(dateDebut) : DateTime.fromISO(dateDebut);
    let dateFin = this.form_filtre_Ast.controls['dateFin'].value;
    let dateFin_dt = DateTime.fromJSDate(dateFin).isValid ? DateTime.fromJSDate(dateFin) : DateTime.fromISO(dateFin);

    if (dateDebut_dt.isValid && !dateFin_dt.isValid) {
      _filtre['dateDebut'] = dateDebut_dt.toFormat('yyyy-MM-dd') + '&dateDebut=' + dateDebut_dt.toFormat('yyyy-MM-dd');
    } else if (dateFin_dt.isValid && !dateDebut_dt.isValid) {
      _filtre['dateFin'] = dateFin_dt.toFormat('yyyy-MM-dd') + '&dateFin=' + dateFin_dt.toFormat('yyyy-MM-dd');
    } else if (dateDebut_dt.isValid && dateFin_dt.isValid) {
      _filtre['dateDebut'] = dateDebut_dt.toFormat('yyyy-MM-dd');
      _filtre['dateFin'] = dateDebut_dt.toFormat('yyyy-MM-dd') + '&dateFin=' + dateFin_dt.toFormat('yyyy-MM-dd');
    }

    _filtre['nombre'] = this.form_filtre_Ast.controls['nombre'].value;
    _filtre['searchByEmetteur'] = this.form_filtre_Ast.controls['emetteur'].value;

    let campagne = this.form_filtre_Ast.controls['campagne'].value;

    _filtre['campagne.id'] = campagne?.id != undefined ? campagne?.id : '';

    let directionOrigine = this.form_filtre_Ast.controls['directionOrigine'].value;
    _filtre['directionOrigine'] = directionOrigine?.id != undefined ? directionOrigine?.id : '';

    let directionOrganisatrice = this.form_filtre_Ast.controls['directionOrganisatrice'].value;
    _filtre['directionOrganisatrice'] = directionOrganisatrice?.id != undefined ? directionOrganisatrice?.id : '';

    _filtre['statut'] = this.form_filtre_Ast.controls['statut'].value;

    let serviceFonctionnel = this.form_filtre_Ast.controls['serviceFonctionnel'].value;
    _filtre['serviceFonctionnel'] = serviceFonctionnel?.id != undefined ? serviceFonctionnel?.id : '';

    let _arg: any = [];

    for (let key in _filtre) {
      if (_filtre[key] != undefined && _filtre[key] != '') {
        _arg.push(key + '=' + _filtre[key]);
      }
    }

    let filtres = _arg.join('&');

    let filtre_actif = Object.keys(_filtre).filter((k) => _filtre[k] != '' && _filtre[k] != null);
    let nb_filtre_actif = filtre_actif.length; // on déduit le filtre campagne
    if (nb_filtre_actif > 0) {
      this.FiltreAstActif = true;
      this.filtrerAst_libelle$ = of('Filtré (' + nb_filtre_actif + ')');
    } else {
      this.FiltreAstActif = false;
      this.filtrerAst_libelle$ = of('Filtres avancés');
    }

    return filtres;
  }

  // efface le filtre avec la croix
  effaceFiltreAst(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_Ast.controls[controlName].setValue('');
    }
  }
  effaceAgentFiltreAst(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_Ast.controls[controlName].setValue('');
      this.loadAgents();
    }
  }
  effaceDirectionFiltreAst(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_Ast.controls[controlName].setValue('');
      this.loadDirectionList();
    }
  }
  effaceCampagneFiltreAst(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_Ast.controls[controlName].setValue('');
      this.loadCampagneList();
    }
  }
  effaceServiceFiltreAst(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_Ast.controls[controlName].setValue('');
      this.loadserviceFonctionnel();
    }
  }

  effaceEventFiltreAst(event: any, controlName: string) {
    event.stopPropagation();

    if (controlName != undefined && controlName != '') {
      this.form_filtre_Ast.controls[controlName].setValue('');
      this.loadDirectionList();
    }
  }

  astreintesDisplayedColumns: string[] = [
    'Agent',
    'Matricule',
    'Type',
    'Date création',
    'Date début',
    'Date fin',
    'Nombre',
    'Emetteur',
    'Direction origine',
    //'Direction évènement',
    'Statut',
    'Actions'
  ];

  astSubTableDisplayedColumns: string[] = ['Campagne', 'Service origine'];

  astCellStyleCss: any = {
    Statut: { 'text-align': 'center' },
    Nombre: { 'text-align': 'center' },
    'Date début': { 'text-align': 'center' },
    'Date fin': { 'text-align': 'center' }
  };

  astHeaderStyleCss: any = {
    Nombre: { 'text-align': 'center' },
    'Date début': { 'text-align': 'center' },
    'Date fin': { 'text-align': 'center' }
  };

  astCellStyleCssSub: any = {
    'Date HS': { 'text-align': 'center' }
  };

  subTablePenalitiesColumns: string[] = ['Date début', 'Date fin'];

  /*********************** AUTRES FONCTIONS ***********************/

  togglePanel() {
    this.panelOpenState = !this.panelOpenState;
  }

  togglePanelAstreinte() {
    this.panelOpenStateAst = !this.panelOpenStateAst;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  updatePaginationHs(event: any) {
    if (event) {
      this.HS_service.getArchiveHSWithParams(event.offset, event.limit, this.filtrerRecherche_HS())
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.dataJSON_HS = result.results;
            this.hslenght = result.totalRecords;
            this.dataTable_HS = this.transformData_HS();
            this.detectorRef.markForCheck();
          } else {
            console.log('error');
          }
        });
    } else {
      this.HS_service.getArchiveHSWithParams(environment.pagination.offset, environment.pagination.limit, this.filtrerRecherche_HS())
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.dataJSON_HS = result.results;
            this.hslenght = result.totalRecords;
            this.dataTable_HS = this.transformData_HS();
            this.detectorRef.markForCheck();
          } else {
            console.log('error');
          }
        });
    }
  }

  exportAstrainte() {
    this.astreintesService
      .getAstreinteArchiveWithParams(0 /*page*/, this.totalRecords_HS /*size*/, this.filtrerRecherche_astreinte())
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          let exportData = this.transformData_astreintes_to_export(result.results);

          this.convertExcel(exportData, 'export_donnees_saisies_Astreintes_');
        },
        error: (error) => {
          console.log('error:', error);
        }
      });
  }
  transformData_astreintes_to_export(data: any) {
    // data pour l'export
    let dataJSON = data;
    let dataHS: any[] = [];

    for (let key in dataJSON) {
      let dataJ = dataJSON[key];
      let agent = dataJ?.agent;
      let emetteur = '';
      if (dataJ?.emetteur?.nom && dataJ?.emetteur?.prenom) {
        emetteur = dataJ?.emetteur?.nom + ' ' + dataJ?.emetteur?.prenom;
      }
      let _data: any = {};

      /**
       * On ajoute la campagne dans la sous-liste
       */

      _data['Agent'] = agent?.nom + ' ' + agent?.prenom;
      _data['Matricule'] = agent?.matricule;

      let typeCode = dataJ?.type;
      let typeText = '';
      if (typeCode == 'JOUR') {
        typeText = 'Jour';
      } else if (typeCode == 'NUIT') {
        typeText = 'Nuit';
      } else if (typeCode == 'SEMAINE') {
        typeText = 'Semaine';
      } else if (typeCode == 'DIMANCHE_FERIE') {
        typeText = 'Dimanche/J.F';
      } else if (typeCode == 'CRECHE') {
        typeText = 'Crèche';
      } else if (typeCode == 'SAMEDI') {
        typeText = 'Samedi';
      } else if (typeCode == 'WEEKEND') {
        typeText = 'Week-end';
      }

      _data['Type'] = typeText;

      _data['Date création'] = this.pipe.transform(dataJ?.dateCreation, 'shortDate');
      _data['Date début'] = this.pipe.transform(dataJ?.dateDebut, 'shortDate');
      _data['Date fin'] = this.pipe.transform(dataJ?.dateFin, 'shortDate');

      _data['Nombre'] = dataJ?.nombre;
      _data['Emetteur'] = emetteur;
      _data['Direction origine'] = dataJ?.directionOrigine?.libelle;
      _data['Direction évènement'] = dataJ?.directionOrganisatrice?.libelle;

      let status_code = dataJ?.statut;
      let status_text = {};
      if (status_code == 'A_VALIDER') {
        status_text = { class: 'badge-gray', text: 'Avis non renseigné', object: true };
      } else if (status_code == 'VALIDEES') {
        status_text = { class: 'badge-green-directeur', text: 'Avis favorable - Directeur', object: true };
      } else if (status_code == 'REFUSEES') {
        status_text = { class: 'badge-red', text: 'Avis défavorable - Directeur', object: true };
      } else if (status_code == 'VALIDEES_N2') {
        status_text = { class: 'badge-green-dga', text: 'Avis favorable - DG', object: true };
      } else if (status_code == 'REFUSEES_N2') {
        status_text = { class: 'badge-red', text: 'Avis défavorable - DG', object: true };
      } else if (status_code == 'A_VALIDER_RESPONSABLE') {
        status_text = { class: 'badge-gray', text: 'Avis non renseigné (responsable)', object: true };
      } else if (status_code == 'VALIDEES_RESPONSABLE') {
        status_text = { class: 'badge-green-directeur', text: 'Avis favorable - Responsable', object: true };
      } else if (status_code == 'REFUSEES_RESPONSABLE') {
        status_text = { class: 'badge-red', text: 'Avis défavorable - Responsable', object: true };
      }
      //@ts-ignore
      _data['Statut'] = status_text.text;

      _data['Campagne'] = dataJ?.campagne.nom;
      _data['Service origine'] = dataJ?.serviceFonctionnel?.libelle;
      dataHS.push(_data);
    }

    return dataHS;
  }
  radioChange(value: string) {
    if (value == 'true') {
      this.isHs = true;
    } else {
      this.isHs = false;
    }

    this.isHs = value;
  }

  deleteAstr(event: any) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '600px',
      data: {
        id: event,
        description: 'Voulez-vous supprimer la saisie de cette astreinte ?',
        type: 'astreinte'
      }
    });
    dialogRef.afterClosed().subscribe((action) => {
      if (action) {
        this.getListe_astreinte();
      }
      this.broadcast.broadcastEvent({
        broadcastedevent: 'resetPagination'
      });
    });
  }

  deleteHs(event: any) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '600px',
      data: {
        id: event,
        description: 'Voulez-vous supprimer la saisie de cette heure supplémentaire ?',
        type: 'hs'
      }
    });
    dialogRef.afterClosed().subscribe((action) => {
      if (action) {
        this.getListe_HS();
      }

      this.broadcast.broadcastEvent({
        broadcastedevent: 'resetPagination'
      });
    });
  }
}
