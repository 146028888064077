<div class="background-container">
  <div class="shape">
    <h2>PORTAIL DE SAISIES DES HEURES SUPPLEMENTAIRES</h2>

    <div class="centre-card">
      <mat-card>
        <mat-card-content>
          <div style="color: #cd1f1f; font-size: 20px">VOUS N'ÊTES PAS AUTORISÉ À UTILISER CETTE APPLICATION.</div>
          <div style="color: #294bc2; font-size: 16px">Veuillez contacter votre administrateur réseau pour plus d'informations.</div>
          <div class="button">
            <button (click)="logout()" color="primary" type="button" style="margin-top: 5px" mat-raised-button>
              Retour à la page de connexion
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <img class="img-logo" src="assets/logos/logo-studia.png" alt="" />
</div>
