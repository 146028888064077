<span class="material-symbols-outlined icon icon-titre">{{ arianeIcon }}</span>

<mat-divider class="my-3"></mat-divider>
<mat-radio-group class="ml-50 mb-10" aria-label="Choisir une option" [(ngModel)]="isHs" (change)="onSelectionChangeTypeSaisie($event)">
  <mat-radio-button class="custom-radio-button" [value]="true" [ngClass]="{ active: isHs }"
    ><span style="cursor: pointer; width: 100%">VALIDATION DES SAISIES HS ({{ aValiderLenght }})</span></mat-radio-button
  >
  <mat-radio-button [value]="false" class="custom-radio-button" [ngClass]="{ active: !isHs }"
    ><span style="cursor: pointer; width: 100%">VALIDATION DES SAISIES ASTREINTES ({{ aValiderAstLenght }})</span></mat-radio-button
  >
</mat-radio-group>
<div style="border-top-style: solid; border-top-color: rgba(0, 0, 0, 0.12); border-top-width: 1px" class="mt-2"></div>
<mat-tab-group *ngIf="isHs" class="mt-15" (selectedTabChange)="onTabChangedHS($event)">
  <mat-tab label="HS EN ATTENTE DE VALIDATION">
    <ng-template matTabContent>
      <div class="row mt-3">
        <div class="col">
          <mat-form-field class="mr-5" class="field-filtre">
            <mat-label>Filtrer</mat-label>
            <input matInput #input (keyup)="EnAttenteDataTable.applyFilter($event)" />
          </mat-form-field>
        </div>
        <div class="col flex-container end align-items-center">
          <div *ngIf="actifValidation && condition">
            <button
              mat-raised-button
              class="bt-table-defaut bt-table-ajouter mr-5"
              style="margin-right: 10px"
              (click)="validerEnMasse('valider en masse', 'listeAvalider')"
            >
              <mat-icon class="text-success">check</mat-icon> Valider en masse
            </button>
            <button mat-raised-button class="bt-table-defaut bt-table-ajouter mr-5" (click)="refuserEnMasse('listeAvalider')">
              <mat-icon class="text-danger">clear</mat-icon> Refuser en masse
            </button>
          </div>
        </div>
      </div>
      <app-data-table
        #EnAttenteDataTable
        [displayedColumns]="validDisplayedColumns"
        [cellStyleCss]="validCellStyleCss"
        [data]="dataEnAttente"
        [expendable]="true"
        [subTableDisplayedColumns]="subValidDisplayedColumns"
        [validable]="true"
        [refusable]="true"
        (onClickEmit)="openDialog($event, 'listeAvalider', 'valider', 'hs')"
        (onClickEmitRef)="openDialog($event, 'listeAvalider', 'refuser', 'hs')"
        [selectable]="true"
        selectionKey="listeAvalider"
        [filter]="false"
        [subTableSelectable]="true"
        [zoom]="true"
        (showDetailSub)="viewDetailsValidation($event)"
        [length]="aValiderLenght"
        (updatePagination)="updatePaginationAvalider($event)"
        (partialSelection)="partialUpdate($event)"
        (onClickEmitSingle)="validateSingleItem($event, 'hs')"
        (onClickEmitRefSingle)="refuseSingleItem($event, 'hs')"
        (updateFiltre)="updateFiltreHsEnAttent($event)"
        [customFilter]="true"
      >
      </app-data-table>
    </ng-template>
  </mat-tab>
  <mat-tab label="HS VALIDEES">
    <ng-template matTabContent>
      <app-data-table
        [displayedColumns]="validDisplayedColumns"
        [cellStyleCss]="validCellStyleCss"
        [data]="dataValider"
        [expendable]="true"
        [subTableDisplayedColumns]="subValidDisplayedColumns"
        (onClickEmitRef)="openDialog($event, 'listeValide', 'refuser', 'hs')"
        [selectable]="true"
        selectionKey="listeValide"
        [subTableSelectable]="true"
        [zoom]="true"
        (showDetailSub)="viewDetailsValidation($event)"
        [length]="validerLenght"
        (updatePagination)="updatePaginationValider($event)"
        (updateFiltre)="updateFiltreHsValidees($event)"
        [customFilter]="true"
      >
      </app-data-table>
    </ng-template>
  </mat-tab>
  <mat-tab label="HS REFUSEES">
    <ng-template matTabContent>
      <app-data-table
        [displayedColumns]="validDisplayedColumns"
        [cellStyleCss]="validCellStyleCss"
        [data]="dataRefuser"
        [expendable]="true"
        [subTableDisplayedColumns]="subValidDisplayedColumns"
        (onClickEmit)="openDialog($event, 'ListeRefus', 'repasser_valide', 'hs')"
        [selectable]="true"
        selectionKey="ListeRefus"
        [subTableSelectable]="true"
        [zoom]="true"
        [length]="refuseesLenght"
        (showDetailSub)="viewDetailsValidation($event)"
        (updatePagination)="updatePaginationRefusee($event)"
        (updateFiltre)="updateFiltreHsRefusee($event)"
        [customFilter]="true"
      >
      </app-data-table>
    </ng-template>
  </mat-tab>
  <mat-tab label="VOIR TOUT">
    <ng-template matTabContent>
      <app-data-table
        [displayedColumns]="validDisplayedColumns"
        [cellStyleCss]="validCellStyleCss"
        [data]="dataRecap"
        [expendable]="true"
        [subTableDisplayedColumns]="subValidDisplayedColumns"
        (onClickEmit)="openDialog($event, 'listeTout', 'valider', 'hs')"
        (onClickEmitRef)="openDialog($event, 'listeTout', 'refuser', 'hs')"
        [selectable]="true"
        selectionKey="listeTout"
        [subTableSelectable]="true"
        [zoom]="true"
        (showDetailSub)="viewDetailsValidation($event)"
        [length]="allLenght"
        (updatePagination)="updatePaginationAll($event)"
        (updateFiltre)="updateFiltreHsAll($event)"
        [customFilter]="true"
      >
      </app-data-table>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<mat-tab-group *ngIf="!isHs" class="mt-15" (selectedTabChange)="onTabChangedAstreintes($event)">
  <mat-tab label="ASTREINTES EN ATTENTE DE VALIDATION">
    <ng-template matTabContent>
      <div class="row mt-3">
        <div class="col">
          <mat-form-field class="mr-5" class="field-filtre">
            <mat-label>Filtrer</mat-label>
            <input matInput #input (keyup)="EnAttenteAstDataTable.applyFilter($event)" />
          </mat-form-field>
        </div>
        <div class="col flex-container end align-items-center">
          <div *ngIf="actifAstValidation && condition">
            <button
              mat-raised-button
              class="bt-table-defaut bt-table-ajouter mr-5"
              style="margin-right: 10px"
              (click)="validerEnMasseAstreinte('valider en masse', 'listeAstAvalider')"
            >
              <mat-icon class="text-success">check</mat-icon> Valider en masse
            </button>
            <button mat-raised-button class="bt-table-defaut bt-table-ajouter mr-5" (click)="refuserEnMasseAstreinte('listeAstAvalider')">
              <mat-icon class="text-danger">clear</mat-icon> Refuser en masse
            </button>
          </div>
        </div>
      </div>

      <app-data-table
        #EnAttenteAstDataTable
        [displayedColumns]="astraintesDisplayedColumns"
        [cellStyleCss]="validCellStyleCss"
        [data]="astrainteDataAvalider"
        [expendable]="true"
        [subTableDisplayedColumns]="astreintesSubTableDisplayedColumns"
        [validable]="true"
        [refusable]="true"
        (onClickEmit)="openDialog($event, 'listeAstAvalider', 'valider', 'astrainte')"
        (onClickEmitRef)="openDialog($event, 'listeAstAvalider', 'refuser', 'astrainte')"
        [selectable]="true"
        selectionKey="listeAstAvalider"
        [filter]="false"
        [subTableSelectable]="true"
        [zoom]="true"
        (showDetailSub)="viewDetailsValidationAstreinte($event)"
        [length]="aValiderAstLenght"
        (updatePagination)="updatePaginationAstAvalider($event)"
        (partialSelection)="partialUpdate($event)"
        (onClickEmitSingle)="validateSingleItem($event, 'astrainte')"
        (onClickEmitRefSingle)="refuseSingleItem($event, 'astrainte')"
        (updateFiltre)="updateFiltreAstEnAttent($event)"
        [customFilter]="true"
      >
      </app-data-table>
    </ng-template>
  </mat-tab>
  <mat-tab label="ASTREINTES VALIDEES">
    <ng-template matTabContent>
      <app-data-table
        [displayedColumns]="astraintesDisplayedColumns"
        [cellStyleCss]="validCellStyleCss"
        [data]="astraintevalide"
        [expendable]="true"
        [subTableDisplayedColumns]="astreintesSubTableDisplayedColumns"
        (onClickEmitRef)="openDialog($event, 'listeAstValide', 'refuser', 'astrainte')"
        [selectable]="true"
        selectionKey="listeAstValide"
        [subTableSelectable]="true"
        [zoom]="true"
        (showDetailSub)="viewDetailsValidationAstreinte($event)"
        [length]="validerAstLenght"
        (updatePagination)="updatePaginationAstValider($event)"
        (updateFiltre)="updateFiltreAstValider($event)"
        [customFilter]="true"
      >
      </app-data-table>
    </ng-template>
  </mat-tab>
  <mat-tab label="ASTREINTES REFUSEES">
    <ng-template matTabContent>
      <app-data-table
        [displayedColumns]="astraintesDisplayedColumns"
        [cellStyleCss]="validCellStyleCss"
        [data]="astrainteInvalide"
        [expendable]="true"
        [subTableDisplayedColumns]="astreintesSubTableDisplayedColumns"
        (onClickEmit)="openDialog($event, 'ListeAstRefus', 'valider', 'astrainte')"
        [selectable]="true"
        selectionKey="ListeAstRefus"
        [subTableSelectable]="true"
        [zoom]="true"
        [length]="refuseesAstLenght"
        (showDetailSub)="viewDetailsValidationAstreinte($event)"
        (updatePagination)="updatePaginationAstRefusee($event)"
        (updateFiltre)="updateFiltreAstRefusee($event)"
        [customFilter]="true"
      >
      </app-data-table>
    </ng-template>
  </mat-tab>
  <mat-tab label="VOIR TOUT">
    <ng-template matTabContent>
      <app-data-table
        [displayedColumns]="astraintesDisplayedColumns"
        [cellStyleCss]="validCellStyleCss"
        [data]="astrainteData"
        [expendable]="true"
        [subTableDisplayedColumns]="astreintesSubTableDisplayedColumns"
        (onClickEmit)="openDialog($event, 'listeAstTout', 'valider', 'astrainte')"
        (onClickEmitRef)="openDialog($event, 'listeAstTout', 'refuser', 'astrainte')"
        [selectable]="true"
        selectionKey="listeAstTout"
        [subTableSelectable]="true"
        [zoom]="true"
        (showDetailSub)="viewDetailsValidationAstreinte($event)"
        [length]="allAstLenght"
        (updatePagination)="updatePaginationAstAll($event)"
        (updateFiltre)="updateFiltreAstAll($event)"
        [customFilter]="true"
      >
      </app-data-table>
    </ng-template>
  </mat-tab>
</mat-tab-group>
