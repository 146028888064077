import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ConfirmQuestionComponent } from '../../validation/confirm-question/confirm-question.component';
import { AgentSuivantComponent } from 'src/app/shared/agent-suivant/agent-suivant.component';
import { MatChipsModule } from '@angular/material/chips';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { HeureSupDirectionOrigineFormComponent } from '../heure-sup-direction-origine-form/heure-sup-direction-origine-form.component';
import { HeureSupForm3Component } from '../heure-sup-form-3/heure-sup-form-3.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { CustomLuxonDateAdapter } from 'src/app/shared/utils/custom-luxon-date-adapter';
import { MY_FORMATS } from 'src/app/shared/utils/date-pickr-custom-luxon-format';
import { AgentsService } from 'src/app/services/agents.service';
import { Observable, take } from 'rxjs';
import { MotifsService } from 'src/app/services/motifs.service';
import { DirectionService } from 'src/app/services/direction.service';
import { JoursFeriesService } from 'src/app/services/jours-feries.service';
import { CommunicationService } from 'src/app/shared/communication.service';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { DateTime } from 'luxon';
import { AstreintesService } from 'src/app/services/astreintes.service';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-astreinte-edition',
  standalone: true,
  imports: [
    AgentSuivantComponent,
    CommonModule,
    MatTableModule,
    MatRadioModule,
    MatIconModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    HeureSupDirectionOrigineFormComponent,
    HeureSupForm3Component,
    DragDropModule
  ],
  templateUrl: './astreinte-edition.component.html',
  styleUrls: ['./astreinte-edition.component.scss'],
  //providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: CustomLuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AstreinteEditionComponent {
  agentsList: any;
  currentIndex = 0;
  selectedAgents: string[] = [];
  selectedOption: string = 'HS_POUR_DIRECTION_ORIGINE';

  agentNavActif: boolean = true;
  champTypeActif: boolean = true;
  champAgentActif: boolean = true;
  agentParcourirActif: boolean = true;

  Agent: any[] = [];
  Motifs: any[] = [];
  directionList: any[] = [];
  selectedAgent: any;
  selectedCodeMotif: any;
  selectedMotifLibelle: string = '';
  selectedAgentDirection: string = '';
  selectedAgentService: string = '';
  selectAgent: string = '';
  agentIndex: number = 0;
  selectedMotif: any;
  totalHS: string = '';
  selectedNbAgent: number = 1;
  agentDisabled: boolean = false;
  disabledAgent: string[] = [];
  formhours: any[] = [];
  agentData: any[] = [];
  previousAgent: any;
  storeAgents: string[] = [];
  titre_detail: string = '';
  directionList$!: Observable<any>;
  selectedDirection: string = '';
  minDateDebut: any;
  maxDateDebut: any;
  minDateFin: any;
  maxDateFin: any;
  rowData: any = [];
  nombre: number | null = null;
  /**
   * Est-ce que le nombre est en lecture seule (calculé automatiquement)
   */
  isNombreRO: boolean = true;
  dateFinRO: boolean = false;
  jourFerierList: any = [];

  form_astreinte: FormGroup = this.formBuilder.group({
    type: ['', [Validators.required]],
    agent: [{ value: '', disabled: true }],
    direction_origine: [{ value: '', disabled: true }],
    service: [{ value: '', disabled: true }],
    directionOrganisatriceId: null,
    date_debut: ['', [Validators.required]],
    date_fin: ['', [Validators.required]],
    nombre: [null, [Validators.required, Validators.min(1)]]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateAdapter: DateAdapter<any>,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<HeureSupForm3Component>,
    private agentService: AgentsService,
    private motifService: MotifsService,
    private directionService: DirectionService,
    private notifyService: CommunicationService,
    //private eventsService: EventsService,
    private detectorRef: ChangeDetectorRef,
    private astreintesService: AstreintesService,
    public joursFeriesService: JoursFeriesService
  ) {}

  ngOnInit() {
    this.rowData = this.data;
    this.getJourFerierList();
    this.initForm();
    this.verifCampagneActuelle();

    this.dateAdapter.setLocale('fr-FR');
  }

  ngAfterViewInit() {
    // initialise les vérifications des champs date
    this.form_astreinte.controls['date_debut'].addValidators([this.verifDateAstreinte(this)]);
    this.form_astreinte.controls['date_fin'].addValidators([this.verifDateAstreinte(this)]);

    this.form_astreinte.controls['date_debut'].setErrors(null);
    this.form_astreinte.controls['date_fin'].setErrors(null);
  }

  ngAfterContentChecked(): void {
    this.detectorRef.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.detectorRef.detectChanges();
  }

  initForm() {
    let _data = this.rowData;

    this.form_astreinte.controls['agent'].setValue(`${this.rowData?.agent?.nom} ${this.rowData?.agent?.prenom}`);
    this.form_astreinte.controls['service'].setValue(this.rowData?.serviceFonctionnel?.libelle);
    this.form_astreinte.controls['direction_origine'].setValue(this.rowData?.directionOrigine?.libelle);

    let type = this.rowData?.type;
    this.changeType(null, type);
    this.form_astreinte.controls['type'].setValue(type);

    if (type == 'SEMAINE' || type == 'WEEKEND' || type == 'SAMEDI' || type == 'DIMANCHE_FERIE') {
      this.dateFinRO = true;
    }

    if (_data['dateDebut'] != null) {
      let date_d = _data['dateDebut'];
      let date_debut = new Date(date_d).toISOString();
      this.form_astreinte.controls['date_debut'].setValue(date_debut);

      let date_deb = DateTime.fromISO(date_d);

      if (date_deb.isValid) {
        let minDateFin = date_deb.plus({ days: 1 }).toFormat('yyyy-MM-dd');
        this.minDateFin = new Date(minDateFin);
      }
    }

    if (this.data['dateFin'] != null) {
      let date_f = _data['dateFin'];
      let date_fin = new Date(date_f).toISOString();
      this.form_astreinte.controls['date_fin'].setValue(date_fin);
    }

    this.form_astreinte.controls['nombre'].setValue(this.rowData?.nombre);
  }

  loadDirectionList() {
    this.directionService
      .getAllDirections()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.directionList = data;

          this.directionList.forEach((_direction: any) => {
            if (_direction['id'] == this.rowData?.directionOrganisatrice?.id) {
              this.selectedDirection = _direction['id'];
            }
          });
        } else {
          console.log('error');
        }
      });
  }

  getJourFerierList() {
    this.joursFeriesService
      .getAllJoursFeries()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          data.forEach((data_a: any) => {
            data_a['dateJourFerier_fmt'] = DateTime.fromISO(data_a.dateJourFerier).toFormat('yyyy-MM-dd');
          });

          this.jourFerierList = data;
        }
      });
  }

  getFormControlByName(ctrlName: string): FormControl {
    return this.form_astreinte.get(ctrlName) as FormControl;
  }

  validerAstreinte() {
    let _data: any = {};

    _data['id'] = this.rowData['id'];
    _data['statut'] = this.rowData['statut'];
    _data['type'] = this.form_astreinte.controls['type'].value;

    _data['directionOrganisatrice'] = { id: this.rowData.directionOrigine.id };

    let dateDebut = this.form_astreinte.controls['date_debut'].value;

    let dateDebut_dt = DateTime.fromJSDate(dateDebut);
    _data['dateDebut'] = !dateDebut_dt.isValid ? DateTime.fromISO(dateDebut).toFormat('yyyy-MM-dd') : dateDebut_dt.toFormat('yyyy-MM-dd');

    let dateFin = this.form_astreinte.controls['date_fin'].value;

    let dateFin_dt = DateTime.fromJSDate(dateFin);
    _data['dateFin'] = !dateFin_dt.isValid ? DateTime.fromISO(dateFin).toFormat('yyyy-MM-dd') : dateFin_dt.toFormat('yyyy-MM-dd');

    _data['nombre'] = this.form_astreinte.controls['nombre'].value;
    _data['agent'] = { id: this.rowData.agent.id };
    _data['directionOrigine'] = { id: this.rowData.directionOrigine.id };
    _data['serviceFonctionnel'] = { id: this.rowData.serviceFonctionnel.id };
    _data['campagne'] = { id: this.rowData.campagne.id };

    this.astreintesService
      .modifieAstreinte(_data, this.rowData['id'])
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.handleModal();
          console.log('result ok: ', result);
        },
        error: (error) => {
          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Erreur',
              content: error.error.replace('Validation error:', '')
            }
          });
        }
      });
  }

  validate() {
    this.dialog.open(ConfirmQuestionComponent, {
      width: '460px'
    });
    this.dialogRef.close(true);
  }

  cancelDialog() {
    this.dialogRef.close();
  }

  handleModal() {
    this.dialogRef.close(true);
    this.dialog.open(ValidationModalComponent, {
      width: '600px',
      data: {
        title: 'Confirmation',
        content: 'La saisie astreinte a été modifiée avec succès.'
      }
    });
  }

  // calcul de la différence des dates
  calculNombre(date_debut: any, date_fin: any) {
    let type = this.form_astreinte.controls['type'].value;

    // si type=SEMAINE ou WEEKEND nombre=1
    if (type == 'SEMAINE' || type == 'WEEKEND') {
      return 0;
      // calcul du nombre
    } else {
      let date_debut_dt = DateTime.fromJSDate(date_debut);
      let date_fin_dt = DateTime.fromJSDate(date_fin);

      if (date_debut_dt.isValid && date_fin_dt.isValid) {
        let diff_days = date_fin_dt.diff(date_debut_dt, 'days').days;

        return diff_days;
      }
    }

    return null;
  }

  // changement manuel de la date
  changeDateAstreinte(event: any) {
    let date_debut = this.form_astreinte.controls['date_debut'].value;
    let type = this.form_astreinte.controls['type'].value;
    let id = event.targetElement.id;
    let val = event.target.value;

    if (id == 'date_debut') {
      let date_deb = val as DateTime;

      if (date_deb.isValid) {
        if (type == 'JOUR' || type == 'NUIT' || type == 'CRECHE') {
          // calcul du nombre de jours entre les 2 dates
          let dateFin = this.form_astreinte.controls['date_fin'].value;

          if (dateFin?.isValid) {
            let dateFinJS = dateFin.toJSDate();
            let dateDebJS = date_deb.toJSDate();
            let nb_jour = Number(this.calculNombre(dateDebJS, dateFinJS));
            this.form_astreinte.controls['nombre'].setValue(nb_jour);
          }
        } else if (type == 'DIMANCHE_FERIE' || type == 'SAMEDI') {
          let date_deb_f = date_deb;
          this.form_astreinte.controls['date_fin'].setValue(date_deb_f);
        } else if (type == 'SEMAINE') {
          let date_deb_f = date_deb.plus({ days: 6 }).toFormat('yyyy-MM-dd');
          this.form_astreinte.controls['date_fin'].setValue(date_deb_f);
        } else if (type == 'WEEKEND') {
          let date_deb_f = date_deb.plus({ days: 1 }).toFormat('yyyy-MM-dd');
          this.form_astreinte.controls['date_fin'].setValue(date_deb_f);
        }
      }
    }

    if (id == 'date_fin') {
      let date_fin = val as DateTime;

      if (date_fin.isValid) {
        if (type == 'JOUR' || type == 'NUIT' || type == 'CRECHE') {
          // calcul du nombre de jours entre les 2 dates
          let dateDeb = this.form_astreinte.controls['date_debut'].value;

          if (dateDeb?.isValid) {
            let dateFinJS = date_fin.toJSDate();
            let dateDebJS = dateDeb.toJSDate();
            let nb_jour = Number(this.calculNombre(dateDebJS, dateFinJS));
            this.form_astreinte.controls['nombre'].setValue(nb_jour);
          }
        } else if (type == 'DIMANCHE_FERIE' || type == 'SAMEDI') {
          let date_fin_f = date_fin;
          this.form_astreinte.controls['date_debut'].setValue(date_fin_f);
        } else if (type == 'SEMAINE') {
          let date_fin_f = date_fin.plus({ days: -6 });
          this.form_astreinte.controls['date_debut'].setValue(date_fin_f);
        } else if (type == 'WEEKEND') {
          let date_fin_f = date_fin.plus({ days: -1 });
          this.form_astreinte.controls['date_debut'].setValue(date_fin_f);
        }
      }
    }

    // recalcul du nombre automatique
    date_debut = this.form_astreinte.controls['date_debut'].value;
    let date_fin = this.form_astreinte.controls['date_fin'].value;

    if (date_debut && date_fin) {
      let dateDebut_dt = DateTime.fromJSDate(date_debut);
      let dateFin_dt = DateTime.fromJSDate(date_fin);
      dateDebut_dt = dateDebut_dt.isValid ? dateDebut_dt : DateTime.fromISO(date_debut);
      dateFin_dt = dateFin_dt.isValid ? dateFin_dt : DateTime.fromISO(date_fin);
      let dateDebJS = dateDebut_dt.toJSDate();
      let dateFinJS = dateFin_dt.toJSDate();
      let diff = this.calculNombre(dateDebJS, dateFinJS);

      if (diff != null) {
        diff = Math.round(diff) + 1; // +1 si dates identiques
        this.form_astreinte.controls['nombre'].setValue(diff);
      }
    }

    this.form_astreinte.controls['date_debut'].updateValueAndValidity();
    this.form_astreinte.controls['date_fin'].updateValueAndValidity();
    this.form_astreinte.controls['nombre'].updateValueAndValidity();
  }

  // grise les jours du calendrier suivant les dates de la campagne en cours
  verifCampagneActuelle() {
    let campagneSession: any = sessionStorage.getItem('campagneActuelle');
    let campagneActuelle = JSON.parse(campagneSession) || null;

    if (campagneActuelle) {
      let dateFin = campagneActuelle.dateFin;

      let day_fin = DateTime.fromFormat(dateFin, 'dd/MM/yyyy').setLocale('fr').day;
      let minDateDebut = DateTime.fromFormat(dateFin, 'dd/MM/yyyy').plus({ month: -3 }).set({ day: 1 }).toFormat('yyyy-MM-dd');

      this.minDateDebut = new Date(minDateDebut);
      this.minDateFin = new Date(minDateDebut);

      let maxDateFin = DateTime.fromFormat(dateFin, 'dd/MM/yyyy').plus({ month: 1 }).plus({ day: -1 }).toFormat('yyyy-MM-dd');

      this.maxDateDebut = new Date(maxDateFin);
      this.maxDateFin = new Date(maxDateFin);
    }
  }

  dateDebutFilter = (d: DateTime | null): boolean => {
    return true;
  };

  dateFinFilter = (d: DateTime | null): boolean => {
    return true;
  };

  // si choix du type on filtre les dates des calendriers
  changeType(event: any, type_ini?: string) {
    let type = type_ini ? type_ini : event?.value;

    this.isNombreRO = true;
    this.dateFinRO = false;

    if (type) {
      // efface les saisies des heures
      if (type == 'SEMAINE' || type == 'DIMANCHE_FERIE' || type == 'SAMEDI' || type == 'JOUR' || type == 'WEEKEND') {
        this.form_astreinte.controls['date_debut'].setValue(null);
        this.form_astreinte.controls['date_fin'].setValue(null);
      }

      // Choix libre du Lundi au Vendredi sauf jour fériés
      if (type == 'JOUR') {
        this.dateDebutFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;
          let d_fmt = d?.toFormat('yyyy-MM-dd');
          let jourFerie = this.jourFerierList.find((jf: any) => jf.dateJourFerier_fmt == d_fmt);

          return day != 6 && day != 7 && !jourFerie;
        };
        this.dateFinFilter = this.dateDebutFilter;
        this.form_astreinte.controls['nombre'].setValue(null);
        this.isNombreRO = true;
        this.dateFinRO = false;
        // Pour les Nuits les jours fériés sont possible
      } else if (type == 'NUIT') {
        this.dateDebutFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;
          let d_fmt = d?.toFormat('yyyy-MM-dd');
          //let jourFerie = this.jourFerierList.find((jf: any) => jf.dateJourFerier_fmt == d_fmt);

          return day != 6 && day != 7 /*&& !jourFerie*/;
        };
        this.dateFinFilter = this.dateDebutFilter;
        this.form_astreinte.controls['nombre'].setValue(null);
        this.isNombreRO = true;
        this.dateFinRO = false;
      } else if (type == 'SEMAINE') {
        this.dateDebutFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;
          // On ne veut que les Lundi => d = 1
          return day == 1;
        };
        this.dateFinFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;
          // On ne veut que les Dimanche => d = 7
          return day == 7;
        };
        this.form_astreinte.controls['nombre'].setValue(1);
        this.isNombreRO = true;
        this.dateFinRO = true;
      } else if (type == 'WEEKEND') {
        this.dateDebutFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;
          // On ne veut que les Samedi => d = 6
          return day == 6;
        };
        this.dateFinFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;
          // On ne veut que les Dimanche => d = 7
          return day == 7;
        };
        this.form_astreinte.controls['nombre'].setValue(1);
        this.isNombreRO = true;
        this.dateFinRO = true;
      }

      // date début : on ne peut choisir qu’un dimanche
      // date fin : le même dimanche que la date de début
      // nombre = 1
      else if (type == 'DIMANCHE_FERIE') {
        this.dateDebutFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;
          let d_fmt = d?.toFormat('yyyy-MM-dd');
          let jourFerie = this.jourFerierList.find((jf: any) => jf.dateJourFerier_fmt == d_fmt);
          // Temporaire : on ne veut que les Dimanche
          return day == 7 || jourFerie;
        };
        this.dateFinFilter = this.dateDebutFilter;
        this.form_astreinte.controls['nombre'].setValue(1);
        this.isNombreRO = true;
        this.dateFinRO = true;
      }

      // date début : on ne peut choisir qu’un samedi
      // date fin : le même samedi que la date de début
      // nombre = 1
      else if (type == 'SAMEDI') {
        this.dateDebutFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;
          // Temporaire : on ne veut que les Samedi
          return day == 6;
        };
        this.dateFinFilter = this.dateDebutFilter;
        this.form_astreinte.controls['nombre'].setValue(1);
        this.isNombreRO = true;
        this.dateFinRO = true;
      } else {
        this.dateDebutFilter = (d: DateTime | null): boolean => {
          return true;
        };
        this.dateFinFilter = (d: DateTime | null): boolean => {
          return true;
        };

        this.form_astreinte.controls['nombre'].setValue(null);
        this.nombre = null;
        this.isNombreRO = true;
        this.dateFinRO = false;
      }
    }
  }

  // vérification des dates
  verifDateAstreinte(_this: any): ValidatorFn {
    return (control: AbstractControl): null | ValidationErrors => {
      let c_date_debut = _this.form_astreinte.controls['date_debut'].value;
      let dateDebut: any = '';
      if (c_date_debut) {
        let date = new Date(c_date_debut);
        dateDebut = DateTime.fromJSDate(date).startOf('day');
      }

      let c_date_fin_v = _this.form_astreinte.controls['date_fin'].value;
      let dateFin_v: any = '';
      if (c_date_fin_v) {
        let date = new Date(c_date_fin_v);
        dateFin_v = DateTime.fromJSDate(date).startOf('day');
      }

      let type = _this.form_astreinte.controls['type'].value;

      if (type == 'DIMANCHE_FERIE' || type == 'SAMEDI' || type == 'SEMAINE' || type == 'WEEKEND') {
        return null;
      } else {
        // la date de début doit être supérieur à la fin.
        if (dateDebut > dateFin_v) {
          return { erreurDateAstreinte: control.value };
        }
      }

      return null;
    };
  }
}
