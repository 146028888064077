import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ArianeNavComponent } from 'src/app/layout/ariane-nav/ariane-nav.component';
import { MatDividerModule } from '@angular/material/divider';
import { DirectionService } from 'src/app/services/direction.service';
import { take } from 'rxjs';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommunicationService } from 'src/app/shared/communication.service';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { DirectionsModalComponent } from './directions-modal/directions-modal.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { ExcelService } from 'src/app/core/services/excel.service';

@Component({
  selector: 'app-admin-directions',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    CommonModule,
    ArianeNavComponent,
    MatDividerModule,
    DataTableComponent,
    MatFormFieldModule,
    MatInputModule
  ],
  templateUrl: './directions.component.html',
  styleUrls: ['./directions.component.scss']
})
export class DirectionsComponent implements OnInit {
  arianeLabel: string = 'Directions';
  arianeIcon: string = 'border_all';
  pipe = new DatePipe('fr-FR'); // Use your own locale
  dataTable: any[] = [];
  rowData: any[] = [];
  keyChanges: any = {};
  untransformedData: any = [];
  dataLength: number;
  filterValue: string = '';
  arianeList = [
    {
      label: 'Administration',
      url: '/administration'
    },
    {
      label: 'Directions',
      url: '/directions'
    }
  ];
  constructor(
    public directionService: DirectionService,
    private refreshService: CommunicationService,
    public dialog: MatDialog,
    private detectorRef: ChangeDetectorRef,
    private broadcast: Broadcast,
    private excelService: ExcelService
  ) {}
  DirectionsDisplayedColumns: string[] = ['Libellé', 'Structure de validation', 'Type de workflow', 'Actions'];

  ngOnInit() {
    this.updatePagination(null);

    this.refreshService.refreshList$.subscribe((refresh) => {
      if (refresh) {
        this.updatePagination(null);
      }
    });
  }
  transformData(result: any) {
    let data: any[] = [];

    for (let key in result) {
      let dataJ = result[key];
      let _data: any = {};
      _data['id'] = dataJ?.id;
      _data['Libellé'] = dataJ?.libelle;
      _data['Structure de validation'] = dataJ?.structValidation;
      _data['Type de workflow'] = dataJ?.typeWorkflow;
      if ('ASSISTANT_DIRECTEUR' == dataJ?.typeWorkflow) {
        _data['Type de workflow'] = 'Assistant vers Directeur';
      } else if ('ASSISTANT_RESPONSABLE_DIRECTEUR' == dataJ?.typeWorkflow) {
        _data['Type de workflow'] = 'Assistant vers Responsable puis Directeur';
      }

      data.push(_data);
    }
    return data;
  }

  updatePagination(event: any) {
    if (event) {
      this.directionService
        .getDirectionsWithParams(event.offset, event.limit, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result: any) => {
            this.untransformedData = result.results;
            this.dataLength = result.totalRecords;

            //result.forEach((element: any) => {
            this.dataTable = this.transformData(result.results);
            //});
          },
          error: (error: any) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.directionService
        .getDirectionsWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result: any) => {
            this.untransformedData = result.results;
            this.dataLength = result.totalRecords;

            //result.forEach((element: any) => {
            this.dataTable = this.transformData(result.results);
            //});
          },
          error: (error: any) => {
            console.log('erreur:', error);
          }
        });
    }
  }
  updateFiltre(value: any) {
    this.filterValue = value.filterValue;
    this.updatePagination(value);
  }

  // modale de modification
  openDirectionEdit(id: number) {
    this.searchDataID(this.untransformedData, id);

    const dialogRef = this.dialog.open(DirectionsModalComponent, {
      width: '780px',
      disableClose: true,
      data: { edition: true, ...this.rowData }
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data?.action) {
        this.updatePagination(null);
        this.detectorRef.markForCheck();
        this.broadcast.broadcastEvent({
          broadcastedevent: 'resetPagination'
        });
      }
    });
  }

  // recherche la ligne data correspondant à l'id
  searchDataID(data: any, id: number) {
    let index = Object.keys(data).find((k) => data[k].id === id);

    if (index != null) {
      this.rowData = this.untransformedData[Number(index)];
    }
  }

  /**
   * Export de l'organigramme sous forme de fichier Excel
   */
  exportOrganigramme() {
    this.directionService
      .getOrganigramme()
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          let _data_export: any[] = [];

          _data_export = this.transformOrganigrammeData(result);

          this.convertExcel(_data_export, 'export_organigramme_');
        },
        error: (error) => {
          console.log('error:', error);
          let erreur_texte = error.statusText || error.error.detail || error.error || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Une erreur est survenue lors de l'export de l'organigramme.",
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  transformOrganigrammeData(dir_list: any) {
    let dataCSV: any[] = [];

    if (dir_list) {
      // Parcours des Directions
      for (let key in dir_list) {
        let dataRow = dir_list[key];

        // Est-ce qu'on descend au niveau des Services ?
        let isWorkflowResponsable = dataRow?.workflowResponsable == true;

        // Niveau Service
        if (isWorkflowResponsable) {
          let serv_list = dataRow?.services;
          for (let keyService in serv_list) {
            let dataRowService = serv_list[keyService];

            let ligne: any = {};

            ligne['Direction'] = dataRow?.libelleDirection;
            ligne['Service'] = dataRowService?.libelleService;
            ligne['Total agents'] = dataRowService?.nbAgentsTotal;
            ligne['Total actifs'] = dataRowService?.nbAgentsActifs;
            ligne['Total inactifs'] = dataRowService?.nbAgentsInactifs;
            ligne['Responsable'] = dataRowService?.nomResponsable;
            ligne['Directeur(s)'] = dataRow?.directeurs?.map((dir: any) => dir.nomDirecteur).join(', \n');
            ligne['DG(s)'] = dataRow?.dgs?.map((dir: any) => dir.nomDirecteur).join(', \n');
            ligne['Responsable délégué'] = dataRowService?.delegues?.map((dir: any) => dir.nomDelegue).join(', \n');
            ligne['Directeur(s) délégué(s)'] = dataRow?.directeurs
              ?.map((dir: any) => dir.delegues?.map((del: any) => del.nomDelegue).join(', \n'))
              .join(' \n');
            ligne['DG(s) délégué(s)'] = dataRow?.dgs
              ?.map((dir: any) => dir.delegues?.map((del: any) => del.nomDelegue).join(', \n'))
              .join(' \n');

            let commentaireParam = '';
            if (dataRowService?.responsableNonFonctionnel) {
              commentaireParam += dataRowService?.commentaireNonFonctionnel + ', \n';
            }
            for (let delegResp in dataRowService?.delegues) {
              let delegRow = dataRowService?.delegues[delegResp];
              if (delegRow.delegationExpire) {
                commentaireParam += 'Délégation responsable expirée, \n';
              }
              if (delegRow.delegationNonFonctionnelle) {
                commentaireParam += delegRow?.commentaireNonFonctionnelle + ', \n';
              }
            }
            for (let dir in dataRow?.directeurs) {
              let delegDir = dataRow?.directeurs[dir];
              for (let deleg in delegDir?.delegues) {
                let delegRow = delegDir?.delegues[deleg];
                if (delegRow.delegationExpire) {
                  commentaireParam += 'Délégation directeur expirée, \n';
                }
                if (delegRow.delegationNonFonctionnelle) {
                  commentaireParam += delegRow?.commentaireNonFonctionnelle + ', \n';
                }
              }
            }
            for (let dir in dataRow?.dgs) {
              let delegDir = dataRow?.dgs[dir];
              for (let deleg in delegDir?.delegues) {
                let delegRow = delegDir?.delegues[deleg];
                if (delegRow.delegationExpire) {
                  commentaireParam += 'Délégation DG expirée, \n';
                }
                if (delegRow.delegationNonFonctionnelle) {
                  commentaireParam += delegRow?.commentaireNonFonctionnelle + ', \n';
                }
              }
            }

            ligne['Commentaires paramétrage'] = commentaireParam;

            dataCSV.push(ligne);
          }
        }
        // On reste au niveau Direction
        else {
          let ligne: any = {};

          ligne['Direction'] = dataRow?.libelleDirection;
          ligne['Service'] = '';
          ligne['Total agents'] = dataRow?.nbAgentsTotal;
          ligne['Total actifs'] = dataRow?.nbAgentsActifs;
          ligne['Total inactifs'] = dataRow?.nbAgentsInactifs;
          ligne['Responsable'] = '';
          ligne['Directeur(s)'] = dataRow?.directeurs?.map((dir: any) => dir.nomDirecteur).join(', \n');
          ligne['DG(s)'] = dataRow?.dgs?.map((dir: any) => dir.nomDirecteur).join(', \n');
          ligne['Responsable délégué'] = '';
          ligne['Directeur(s) délégué(s)'] = dataRow?.directeurs
            ?.map((dir: any) => dir.delegues?.map((del: any) => del.nomDelegue).join(', \n'))
            .join(', \n');
          ligne['DG(s) délégué(s)'] = dataRow?.dgs
            ?.map((dir: any) => dir.delegues?.map((del: any) => del.nomDelegue).join(', \n'))
            .join(' \n');

          let commentaireParam = '';

          for (let dir in dataRow?.directeurs) {
            let delegDir = dataRow?.directeurs[dir];
            for (let deleg in delegDir?.delegues) {
              let delegRow = delegDir?.delegues[deleg];
              if (delegRow.delegationExpire) {
                commentaireParam += 'Délégation directeur expirée, \n';
              }
              if (delegRow.delegationNonFonctionnelle) {
                commentaireParam += delegRow?.commentaireNonFonctionnelle + ', \n';
              }
            }
          }
          for (let dir in dataRow?.dgs) {
            let delegDir = dataRow?.dgs[dir];
            for (let deleg in delegDir?.delegues) {
              let delegRow = delegDir?.delegues[deleg];
              if (delegRow.delegationExpire) {
                commentaireParam += 'Délégation DG expirée, \n';
              }
              if (delegRow.delegationNonFonctionnelle) {
                commentaireParam += delegRow?.commentaireNonFonctionnelle + ', \n';
              }
            }
          }

          ligne['Commentaires paramétrage'] = commentaireParam;

          dataCSV.push(ligne);
        }
      }
    }

    return dataCSV;
  }

  convertExcel(data: any, name: string) {
    const search = '/';
    const replaceWith = '-';
    const date = new Date().toLocaleDateString('en-GB').split(search).join(replaceWith);
    let fileName = `${name}${date}`;
    this.excelService.exportToExcel(data, `${fileName}.xlsx`, 'Sheet1');
  }
}
