import { Component, Inject, AfterViewInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { take } from 'rxjs';
import { CampagnesService } from 'src/app/services/campagnes.service';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { MatSelectModule } from '@angular/material/select';
import { DateTime } from 'luxon';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';

import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { CustomLuxonDateAdapter } from 'src/app/shared/utils/custom-luxon-date-adapter';
import { MY_FORMATS } from 'src/app/shared/utils/date-pickr-custom-luxon-format';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-campagne-notifications',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatRadioModule,
    MatIconModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatAutocompleteModule,
    ValidationModalComponent,
    ConfirmModalComponent,
    MatNativeDateModule,
    DragDropModule
  ],
  templateUrl: './campagne-notifications.component.html',
  styleUrls: ['./campagne-notifications.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: CustomLuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class CampagneNotificationsComponent implements AfterViewInit {
  rowData: any = {};
  dateP = new DatePipe('fr-FR');
  action: string = '';
  titre_action: string = 'Ajouter';
  selectedType: string = '';
  dataCampagne: any = '';
  texte_erreur_date: string = '';
  minDateDebutSaisie: Date;
  minDateFinSaisie: Date;
  minDateDebutValidation: Date;
  minDateFinValidation: Date;
  minDateArchivage: Date;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CampagneNotificationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateAdapter: DateAdapter<any>,
    private campagneService: CampagnesService,
    private broadcast: Broadcast,
    private spinner: NgxSpinnerService
  ) {}

  form: FormGroup = this.formBuilder.group({
    nom: [this.data['nom'], [Validators.required]]
  });

  ngOnInit() {
    this.dateAdapter.setLocale('fr-FR');
    this.rowData = this.data;
    this.initForm();
  }

  ngAfterViewInit() {}

  // chargement des dates dans les datepicker
  initForm() {
    let _data = this.data;
    console.log('initForm _data:', _data);

    // par défaut : en mode ajout
    this.action = 'ajouter';

    if (Object.keys(_data).length > 0) {
      if (_data['edition']) {
        this.titre_action = 'Modifier';
        this.action = 'modifier';
      } else {
        this.action = 'ajouter';
      }

      this.selectedType = this.data['type'];
      this.dateAdapter.setLocale('fr-FR');
    }
  }

  fermerModale() {
    // modification
    this.dialogRef.close({ action: false });
  }

  sendEmailCreation() {
    this.campagneService
      .sendEmailCreation(this.rowData.id)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: "L'email a été envoyé."
            }
          });
          this.dialogRef.close({ action: true });
        },
        error: (error) => {
          let erreur_texte = error.error.detail || error.error || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Erreur lors de l'envoi de l'email !",
              //erreur: error.message,
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  sendEmailDebutSaisie() {
    this.campagneService
      .sendEmailDebutSaisie(this.rowData.id)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: "L'email a été envoyé."
            }
          });
          this.dialogRef.close({ action: true });
        },
        error: (error) => {
          let erreur_texte = error.error.detail || error.error || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Erreur lors de l'envoi de l'email !",
              //erreur: error.message,
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  sendEmailDebutValidation() {
    this.campagneService
      .sendEmailDebutValidation(this.rowData.id)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: "L'email a été envoyé."
            }
          });
          this.dialogRef.close({ action: true });
        },
        error: (error) => {
          let erreur_texte = error.error.detail || error.error || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Erreur lors de l'envoi de l'email !",
              //erreur: error.message,
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  sendEmailDebutValidationDg() {
    this.campagneService
      .sendEmailDebutValidationDg(this.rowData.id)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: "L'email a été envoyé."
            }
          });
          this.dialogRef.close({ action: true });
        },
        error: (error) => {
          let erreur_texte = error.error.detail || error.error || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Erreur lors de l'envoi de l'email !",
              //erreur: error.message,
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  sendEmailFinValidation() {
    this.campagneService
      .sendEmailFinValidation(this.rowData.id)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: "L'email a été envoyé."
            }
          });
          this.dialogRef.close({ action: true });
        },
        error: (error) => {
          let erreur_texte = error.error.detail || error.error || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Erreur lors de l'envoi de l'email !",
              //erreur: error.message,
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  sendEmailDRH() {
    this.campagneService
      .sendEmailDRH(this.rowData.id)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: "L'email a été envoyé."
            }
          });
          this.dialogRef.close({ action: true });
        },
        error: (error) => {
          let erreur_texte = error.error.detail || error.error || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Erreur lors de l'envoi de l'email !",
              //erreur: error.message,
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }
}
