<mat-form-field class="mt-20 col-xs-12 field-filtre" [hidden]="!filter">
  <mat-label>Filtrer</mat-label>
  <input matInput (keyup)="applyFilter($event)" #input
/></mat-form-field>
<div style="overflow-x: auto">
  <table mat-table [dataSource]="dataSource" matSort #empTbSort="matSort" multiTemplateDataRows aria-describedby="tableDescription">
    <!-- Dynamic Column Definition -->
    <ng-container
      class="td-wrap"
      *ngFor="let column of displayedColumns"
      matColumnDef="{{ column }}"
      [stickyEnd]="column == 'Actions' ? true : false"
    >
      <ng-container *ngIf="column !== ' '">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [disabled]="column === 'Sélectionner' || column === 'Statut' || column === 'Actions'"
          [ngClass]="{ 'desactiver-tri-colonne': column === 'Sélectionner' || column === 'Statut' || column === 'Actions' }"
          [ngStyle]="this.headerStyleCss.hasOwnProperty(column) ? this.headerStyleCss[column] : {}"
        >
          <section class="example-section" *ngIf="column == 'Sélectionner'">
            <mat-checkbox
              class="example-margin"
              (click)="$event.stopPropagation()"
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected('s1') && allselectedActif"
              [indeterminate]="selection.hasValue() && !isAllSelected('s1') && allselectedActif"
              [aria-label]="checkboxLabel('s1')"
              (change)="updateCheckAll()"
            >
            </mat-checkbox>
          </section>
          {{ column }}
        </th>
      </ng-container>

      <ng-container *ngIf="column == ' '">
        <th mat-header-cell *matHeaderCellDef>
          <section class="example-section pointer">
            <mat-icon matTooltip="Déplier tous les volets détails" (click)="toggleSubTable()" *ngIf="!showAllSubTable"
              ><span class="material-symbols-outlined"> expand_all </span></mat-icon
            >
            <mat-icon matTooltip="Replier tous les volets détails" (click)="toggleSubTable()" *ngIf="showAllSubTable"
              ><span class="material-symbols-outlined"> unfold_less </span></mat-icon
            >
          </section>
        </th>
      </ng-container>
      <td
        class="td-wrap"
        mat-cell
        *matCellDef="let element"
        [ngClass]="{ 'table-helper': column == 'Actions' }"
        [ngStyle]="this.cellStyleCss.hasOwnProperty(column) ? this.cellStyleCss[column] : {}"
      >
        <span class="text-left" *ngIf="column == 'Sélectionner'">
          <span>
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(element) : null"
              [checked]="selection.isSelected(element)"
              [aria-label]="checkboxLabel(element, 's1')"
              (change)="updateCheck($event, element)"
            >
            </mat-checkbox>
          </span>
        </span>
        <span>
          <span *ngIf="isObjectType(element[column])">
            <div class="{{ element[column].class }}">
              {{ element[column].text }}
            </div>
          </span>

          <span *ngIf="!isObjectType(element[column])">{{ element[column] }}</span>
        </span>

        <span class="text-center" *ngIf="column == 'Actions'">
          <button
            [disabled]="disabledEdit"
            class="bt-action"
            *ngIf="
              !(
                this.userRoles.includes('dhesa-dg') ||
                this.userRoles.includes('dhesa-directeur') ||
                this.userRoles.includes('dhesa-responsable') ||
                this.userRoles.includes('dhesa-gestionnaire-paie')
              )
            "
            [hidden]="!addable || element['add_hidden'] || element['addUserInactif']"
            matTooltip="Ajouter une délégation"
            mat-button
            (click)="add(element['id'])"
          >
            <mat-icon class="icon-add pointer">add</mat-icon>
          </button>
          <button
            class="bt-action"
            *ngIf="
              this.userRoles.includes('dhesa-admin') ||
              this.userRoles.includes('dhesa-assistant-cds') ||
              this.userRoles.includes('dhesa-dg') ||
              this.userRoles.includes('dhesa-directeur') ||
              this.userRoles.includes('dhesa-responsable') ||
              this.userRoles.includes('dhesa-assistant-responsable') ||
              this.userRoles.includes('dhesa-assistant-directeur') ||
              this.userRoles.includes('dhesa-gestionnaire-paie')
            "
            [hidden]="!historical || element['history_hidden']"
            [disabled]="element['history_disabled']"
            mat-button
            matTooltip="Historique des modifications"
            (click)="fetchHistoricalData(element['id'])"
          >
            <mat-icon class="icon-history pointer">history</mat-icon>
          </button>
          <button
            [disabled]="disabledEdit"
            class="bt-action"
            *ngIf="
              !(
                this.userRoles.includes('dhesa-dg') ||
                this.userRoles.includes('dhesa-directeur') ||
                this.userRoles.includes('dhesa-responsable') ||
                this.userRoles.includes('') ||
                this.userRoles.includes('dhesa-gestionnaire-paie')
              )
            "
            [hidden]="!editable || element['editer_hidden'] || element['editUserInactif']"
            matTooltip="Modifier"
            mat-button
            (click)="edit(element['id'])"
          >
            <mat-icon class="icon-edit pointer">edit</mat-icon>
          </button>

          <button class="bt-action" [hidden]="!hasView" [matTooltip]="zoomTooltip" mat-button (click)="showTableDetail(element['id'])">
            <mat-icon class="icon-zoom pointer">{{ zoomIcon }}</mat-icon>
          </button>
          <button
            class="bt-action"
            [hidden]="!hasViewDisabled"
            [matTooltip]="zoomTooltipDisabled"
            mat-button
            disabled
            (click)="showTableDetail(element['id'])"
          >
            <mat-icon class="icon-zoom pointer">zoom_in</mat-icon>
          </button>

          <button
            class="bt-action"
            *ngIf="
              this.userRoles.includes('dhesa-admin') ||
              this.userRoles.includes('dhesa-assistant-cds') ||
              this.userRoles.includes('dhesa-dg') ||
              this.userRoles.includes('dhesa-directeur') ||
              this.userRoles.includes('dhesa-responsable') ||
              this.userRoles.includes('dhesa-assistant-responsable') ||
              this.userRoles.includes('dhesa-assistant-directeur') ||
              this.userRoles.includes('dhesa-gestionnaire-paie')
            "
            [hidden]="!refreshable || element['refresh_hidden']"
            [disabled]="element['refresh_disabled']"
            mat-button
            matTooltip="Renouveler"
            (click)="renouveler(element['id'])"
          >
            <mat-icon class="icon-refresh pointer">refresh</mat-icon>
          </button>

          <button
            class="bt-action"
            *ngIf="
              this.userRoles.includes('dhesa-admin') ||
              this.userRoles.includes('dhesa-assistant-cds') ||
              this.userRoles.includes('dhesa-dg') ||
              this.userRoles.includes('dhesa-directeur') ||
              this.userRoles.includes('dhesa-responsable') ||
              this.userRoles.includes('dhesa-assistant-responsable') ||
              this.userRoles.includes('dhesa-assistant-directeur') ||
              this.userRoles.includes('dhesa-gestionnaire-paie')
            "
            [hidden]="!delatable || element['delete_hidden']"
            [disabled]="element['delete_disabled']"
            mat-button
            matTooltip="Supprimer"
            (click)="delete(element['id'])"
          >
            <mat-icon class="icon-delete pointer">delete</mat-icon>
          </button>

          <button
            class="bt-action"
            mat-button
            [hidden]="!export"
            matTooltip="Télécharger"
            (click)="onClickExporter(element['id'], element['exportData'])"
          >
            <mat-icon class="icon-download pointer">download</mat-icon>
          </button>

          <button
            class="bt-action"
            mat-button
            [hidden]="!creerPDF"
            matTooltip="Générer un PDF"
            *ngIf="element['creerPDFData']"
            (click)="onClickCreerPDF(element['creerPDFData'])"
          >
            <mat-icon class="icon-download pointer">picture_as_pdf</mat-icon>
          </button>

          <button
            class="bt-action text-success"
            matTooltip="valider"
            mat-button
            [hidden]="!validable || element['valider_hidden'] || verifSubtableDisabled(element['subTable'])"
            (click)="onClickValider(element)"
            aria-label="icon button"
          >
            <mat-icon>done</mat-icon>
          </button>
          <button
            class="bt-action text-danger"
            matTooltip="refuser"
            mat-button
            [hidden]="!refusable || element['valider_hidden']"
            (click)="onClickRefuser(element)"
            aria-label="icon button"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <button
            class="bt-action"
            [hidden]="!expendable"
            mat-button
            aria-label="expand row"
            (click)="toggleExpandedElement(element, $event)"
          >
            <span>
              <mat-icon matTooltip="Déplier le volet détails" *ngIf="!element.isExpanded">keyboard_arrow_down</mat-icon>
              <mat-icon matTooltip="Replier le volet détails" *ngIf="element.isExpanded">keyboard_arrow_up</mat-icon>
            </span>
          </button>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element"></td>
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length"
          [ngStyle]="{ border: !element.isExpanded ? '0px' : '' }"
        >
          <div
            class="example-element-detail"
            [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'"
            [class.example-expanded-div]="element.isExpanded"
          >
            <table class="sub-table-border" aria-describedby="tableDescription">
              <tr>
                <th scope="col" *ngFor="let keyValue of subTableData[0] | keyvalue: originalOrder">
                  <section class="example-section" *ngIf="keyValue.key == 'Sélectionner'">
                    <mat-checkbox class="example-margin" [checked]="subTableAllSelected" (change)="selectAllSubTable()"></mat-checkbox>
                  </section>
                  <span *ngIf="keyValue.key !== 'checked' && subTableDisplayedColumns.includes(keyValue.key)">
                    {{ keyValue.key }}
                  </span>
                </th>
              </tr>

              <tr>
                <th
                  scope="col"
                  *ngFor="let keyValue of subTableDisplayedColumns"
                  class="col-sub-table"
                  [ngStyle]="this.headerStyleCssSub.hasOwnProperty(keyValue) ? this.headerStyleCssSub[keyValue] : {}"
                >
                  <ng-container *ngIf="keyValue != 'Actions' && subTableDisplayedColumns.includes(keyValue)">{{ keyValue }}</ng-container>
                </th>
              </tr>

              <tr *ngFor="let item of element['subTable']">
                <ng-container *ngFor="let keyValue of item | keyvalue: originalOrder">
                  <ng-container *ngIf="keyValue.key !== 'id'">
                    <td
                      *ngIf="
                        (keyValue.key !== 'checked' &&
                          keyValue.key !== 'valider_hidden' &&
                          subTableDisplayedColumns.includes(keyValue.key)) ||
                        keyValue.key == 'action'
                      "
                      [ngStyle]="this.cellStyleCssSub.hasOwnProperty(keyValue.key) ? this.cellStyleCssSub[keyValue.key] : {}"
                      class="col-sub-table-td"
                    >
                      <span *ngIf="keyValue.key == 'Sélectionner'">
                        <section class="example-section" *ngIf="keyValue.key == 'Sélectionner'">
                          <mat-checkbox [checked]="selection.isSelected(element)" (change)="updateCheck($event, item, element)">
                          </mat-checkbox>
                        </section>
                      </span>
                      <span
                        *ngIf="
                          keyValue.key !== 'checked' &&
                          keyValue.key !== 'Sélectionner' &&
                          keyValue.key !== 'valider_hidden' &&
                          keyValue.key !== 'delete_disabled' &&
                          keyValue.value &&
                          !keyValue.value.object &&
                          subTableDisplayedColumns.includes(keyValue.key)
                        "
                      >
                        {{ keyValue.value }}
                      </span>

                      <span
                        *ngIf="
                          keyValue.key !== 'checked' &&
                          keyValue.key !== 'Sélectionner' &&
                          keyValue.key !== 'valider_hidden' &&
                          keyValue.key !== 'delete_disabled' &&
                          keyValue.value &&
                          keyValue.value.object
                        "
                      >
                        <div class="{{ keyValue.value.class }}" style="text-align: center">
                          {{ keyValue.value.text }}
                        </div>
                      </span>

                      <span class="example-section" *ngIf="keyValue.key == 'action'">
                        <button
                          class="bt-action"
                          (click)="showTableDetailSub(item['id'])"
                          [hidden]="!zoom"
                          matTooltip="Voir le détail"
                          mat-icon-button
                          color="primary"
                        >
                          <mat-icon>zoom_in</mat-icon>
                        </button>

                        <button
                          class="bt-action text-success"
                          matTooltip="valider"
                          mat-icon-button
                          [hidden]="!validable || item['valider_hidden']"
                          (click)="validateSingleItem(item)"
                          aria-label="Example icon button with a vertical three dot icon"
                        >
                          <mat-icon>done</mat-icon>
                        </button>

                        <button
                          class="bt-action text-danger"
                          matTooltip="refuser"
                          mat-icon-button
                          [hidden]="!refusable || item['valider_hidden']"
                          (click)="refuseSingleItem(item)"
                          aria-label="Example icon button with a vertical three dot icon"
                        >
                          <mat-icon>clear</mat-icon>
                        </button>
                      </span>
                    </td>
                  </ng-container>
                </ng-container>
              </tr>
            </table>
          </div>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; let index = dataIndex; columns: displayedColumns"
      class="example-element-row"
      [ngClass]="{ 'row-altern': index % 2 != 0 }"
      [class.example-expanded-row]="row.isExpanded"
      actions
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row" [hidden]="!row.isExpanded"></tr>
  </table>
</div>
<mat-paginator
  [pageSizeOptions]="[10, 15, 20, 25, 30, 40, 50, 75, 100]"
  [pageSize]="limit"
  [pageIndex]="offset"
  (page)="onPageChange($event)"
  [length]="length"
  showFirstLastButtons
  *ngIf="hasPagination"
></mat-paginator>
