import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgentsService {
  module = environment.webService.server + environment.webService.url + environment.webService.api;
  constructor(private http: HttpClient) {}

  // récupère tous les agents pour remplir les menus
  getAllAgents(): Observable<any> {
    return this.http.get<any>(this.module + '/agents/all');
  }

  // récupère tous les agents délégués pour les menus
  getDelegueAgents(agentId: any): Observable<any> {
    let url = agentId != '' && agentId != null ? '?type=delegues&idAgent=' + agentId : '?type=mes delegues';
    return this.http.get<any>(this.module + '/agents/all' + url);
  }

  // récupère tous les agents d'une direction pour les menus
  getMaDirectionAgents(): Observable<any> {
    return this.http.get<any>(this.module + '/agents/all?type=direction');
  }

  // filtre les agents pour la recherche
  getAgentWithParams(offset: number, limit: number, filtre: string | null = null) {
    console.log('filtre param', filtre);
    filtre = filtre ?? '';
    let url = this.module + '/agents';
    if (filtre != '' && filtre !== undefined && filtre !== null) {
      url = url + '?' + `filterGenerique=${filtre}`;
    }
    const params = new HttpParams().set('page', offset).set('size', limit);
    return this.http.get<any>(url, { params });
  }

  AjoutDelegue(model: any) {
    return this.http.post<any>(this.module + '/agents/delegues', model);
  }
  AjoutMonDelegue(model: any) {
    return this.http.post<any>(this.module + '/agents/mes-delegues', model);
  }
  ModifierAgent(data: any, id: number) {
    return this.http.put<any>(this.module + '/agents/' + id, data);
  }
  getAgentConnecte() {
    return this.http.get<any>(this.module + '/agents/moi');
  }

  /**
   *
   * @param file data du input file
   * @returns
   */
  importAgentFile(file: any, fileName: string) {
    const fd = new FormData();
    fd.append('file', file, fileName);

    return this.http.post<any>(this.module + '/agents/import', fd, {
      /*reportProgress: true,
      observe: 'events'*/
    });
  }

  importOrganisationFile(file: any, fileName: string) {
    const fd = new FormData();
    fd.append('file', file, fileName);

    return this.http.post<any>(this.module + '/directions/import', fd, {
      /*reportProgress: true,
      observe: 'events'*/
    });
  }
}
