<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-6">
      <mat-card-title><h2 class="pl-20">Modifier un agent</h2></mat-card-title>
    </div>

    <div class="col-md-6 text-end mb-2 mr-0 ml-auto gx-0">
      <button (click)="onClose()" class="close-icon" class="py-0" mat-icon-button aria-label="Fermer la modale">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<mat-dialog-content class="mat-typography suppression-scroll">
  <form [formGroup]="edit_form" class="row">
    <div class="row g-0 mt-2">
      <div class="col-md-6 mb-2 pr-md-2">
        <mat-form-field class="example-full-width large-form-field" floatLabel="always">
          <mat-label>Nom</mat-label>
          <input type="text" class="pt-0" matInput formControlName="nom" />
          <mat-error *ngIf="edit_form.get('nom')?.hasError('required')"> Le nom est obligatoire. </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6 mb-2 pr-md-2">
        <mat-form-field class="example-full-width large-form-field" floatLabel="always">
          <mat-label>Prénom</mat-label>
          <input type="text" class="pt-0" matInput formControlName="prenom" />
          <mat-error *ngIf="edit_form.get('prenom')?.hasError('required')"> Le prénom est obligatoire. </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row g-0 mt-0">
      <div class="col-md-6 mb-2 pr-md-2">
        <mat-form-field class="example-full-width large-form-field" floatLabel="always">
          <mat-label>Login</mat-label>
          <input type="text" class="pt-0" matInput formControlName="login" />
          <mat-error *ngIf="edit_form.get('login')?.hasError('required')"> Le login est obligatoire. </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6 mb-2 pr-md-2">
        <mat-form-field class="example-full-width large-form-field" floatLabel="always">
          <mat-label>Email</mat-label>
          <input type="email" class="pt-0" matInput formControlName="email" />
          <mat-error *ngIf="edit_form.get('email')?.hasError('required')"> L'email est obligatoire. </mat-error>
          <mat-error *ngIf="edit_form.get('email')?.hasError('email')"> Veuillez entrer une adresse email valide. </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row g-0 mt-0">
      <div class="col-md-6" style="margin-top: -8px; flex: 0 0 auto; width: 100%">
        <mat-label class="label-etat">Etat</mat-label>
        <div style="margin-top: 20px">
          <mat-checkbox color="primary" formControlName="actif">Actif</mat-checkbox>
        </div>
        <div style="margin-top: 20px">
          <mat-checkbox color="primary" formControlName="verrouAdmin"
            >Verrouillé (ne sera pas activé/désactivé par la synchronisation)</mat-checkbox
          >
        </div>
      </div>
    </div>
    <div class="row g-0 mt-0">
      <div class="col-md-6" style="flex: 0 0 auto; width: 100%">
        <mat-label class="label-texte">Dernier commentaire de synchronisation</mat-label>
        <p>{{ data.rowElement.dernierCommentaireSynchro }}</p>
      </div>
    </div>
    <div class="row g-0 mt-0">
      <div class="col-md-6" style="flex: 0 0 auto; width: 100%">
        <mat-label class="label-texte">Date de dernière vue dans le fichier de synchronisation</mat-label>
        <p>{{ data.rowElement.dateDerniereSynchro | date: 'dd/MM/yyyy HH:mm' }}</p>
      </div>
    </div>
    <div class="row g-0 mt-0">
      <div class="col-md-6" style="flex: 0 0 auto; width: 100%">
        <mat-label class="label-texte">Date de fin de contrat</mat-label>
        <p>{{ data.rowElement.dateFinContrat | date: 'dd/MM/yyyy' }}</p>
      </div>
    </div>
    <div class="row g-0 mt-0">
      <div class="col-md-6" style="margin-top: -8px; flex: 0 0 auto; width: 100%">
        <mat-label class="label-etat">Notifications</mat-label>
        <div style="margin-top: 20px">
          <mat-checkbox color="primary" formControlName="flagNotifsAll">Notifications activées</mat-checkbox>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="background-color: rgba(250, 250, 252, 1)" align="end">
  <button mat-stroked-button mat-dialog-close class="mx-3" color="primary">Annuler</button>
  <button (click)="saveAgent()" mat-flat-button color="primary" [disabled]="edit_form.invalid">Valider</button>
</mat-dialog-actions>
