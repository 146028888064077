<!-- form 2 -->
<form [formGroup]="formAstreinte">
  <div class="row g-3">
    <div class="col-md-4">
      <mat-form-field floatLabel="always" style="width: 100%">
        <mat-label>Type d'astreinte</mat-label>
        <mat-select (selectionChange)="changeType($event)" formControlName="type" id="type" name="type">
          <!--mat-option value="JOUR">Jour</mat-option-->
          <mat-option value="NUIT">Nuit</mat-option>
          <mat-option value="SEMAINE">Semaine</mat-option>
          <mat-option value="WEEKEND">Week-end</mat-option>
          <mat-option value="SAMEDI">Samedi</mat-option>
          <mat-option value="DIMANCHE_FERIE">Dimanche / jour férié</mat-option>
          <mat-option value="CRECHE">Crèche</mat-option>
        </mat-select>
        <mat-error *ngIf="formAstreinte.get('type')?.hasError('required')"> Le type est <strong>obligatoire</strong> </mat-error>
      </mat-form-field>
    </div>
  </div>

  <mat-divider style="border-top-width: 3px" class="my-3"></mat-divider>

  <div class="row g-3">
    <div class="col-md-8">
      <mat-form-field floatLabel="always" style="width: 100%">
        <mat-label>Agent </mat-label>
        <input
          type="text"
          placeholder="Nom/Prénom"
          aria-label="Number"
          matInput
          name="item"
          [matAutocomplete]="auto"
          [formControl]="agentFormControl"
          (input)="filter($event)"
          [required]="selectedAgents.length == 0"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          hideSingleSelectionIndicator
          requireSelection
          (optionSelected)="add_agent($event)"
          [displayWith]="displayFn"
        >
          <mat-option [value]="agent" *ngFor="let agent of filteredOptions">
            <br />
            <b>{{ agent.nom }} {{ agent.prenom }}</b> <br />
            {{ agent.direction?.libelle }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="erreurChoixAgentCadre || agentFormControl.hasError('erreurChoixAgentCadre')"
          >Vous ne pouvez pas ajouter un Agent Cadre à un évènement autre que ELECTIONS</mat-error
        >
        <mat-error *ngIf="erreurAgentsNonEligibles || agentFormControl.hasError('erreurAgentsNonEligibles')"
          >Certains agents ont été supprimés car non éligibles à cet évènement</mat-error
        >
      </mat-form-field>
    </div>
  </div>
  <!-- card for chips -->
  <mat-card [hidden]="selectedAgents.length == 0">
    <mat-card-header
      ><mat-card-title [class.invalidAgents]="selectedAgents.length == 0"
        >Agents sélectionnés ({{ selectedAgents.length }})</mat-card-title
      ></mat-card-header
    >
    <mat-card-content>
      <mat-chip class="m-10 agent-chip" *ngFor="let agentSelected of selectedAgents" (removed)="delete_agent(agentSelected)">
        <strong>{{ agentSelected.nom + ' ' + agentSelected.prenom }}</strong> <br />
        {{ agentSelected.direction?.libelle + ' / ' + agentSelected.serviceFonctionnel?.libelle }}
        <button matChipRemove [attr.area-label]="'remove' + agentSelected">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    </mat-card-content>
  </mat-card>
  <mat-divider style="border-top-width: 3px" class="my-3"></mat-divider>

  <!-- form  -->
  <div class="row g-3">
    <ng-container formArrayName="hoursFormArray" *ngFor="let hour of hours.controls; index as i">
      <ng-container [formGroupName]="i">
        <div class="d-flex">
          <mat-form-field style="width: 26%" floatLabel="always">
            <mat-label>Date début </mat-label>
            <input
              matInput
              [matDatepicker]="dateDebutPicker"
              [min]="minDateDebut[i]"
              [max]="maxDateDebut[i]"
              [matDatepickerFilter]="dateDebutFilter"
              formControlName="date_debut"
              id="date_debut"
              placeholder="JJ/MM/AAAA"
              (dateChange)="changeDateAstreinte($event, i)"
            />
            <mat-datepicker-toggle matIconSuffix [for]="dateDebutPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateDebutPicker></mat-datepicker>
            <mat-error *ngIf="hour.get('date_debut')?.hasError('required')"> Date début est <strong>obligatoire</strong> </mat-error>
            <mat-error *ngIf="hour.get('date_debut')?.hasError('matDatepickerMax')"
              >La date maximum autorisée est le
              {{ hour.get('date_debut')?.errors?.['matDatepickerMax']['max'] | date: 'dd/MM/yyyy' }}</mat-error
            >
            <mat-error *ngIf="hour.get('date_debut')?.hasError('matDatepickerMin')"
              >La date minimum autorisée est le
              {{ hour.get('date_debut')?.errors?.['matDatepickerMin']['min'] | date: 'dd/MM/yyyy' }}</mat-error
            >
          </mat-form-field>

          <mat-form-field style="width: 26%; margin-left: 20px" floatLabel="always">
            <mat-label>Date fin </mat-label>
            <input
              matInput
              [matDatepicker]="dateFinPicker"
              [min]="minDateFin[i]"
              [max]="maxDateFin[i]"
              [matDatepickerFilter]="dateFinFilter"
              formControlName="date_fin"
              [readonly]="dateFinRO"
              id="date_fin"
              placeholder="JJ/MM/AAAA"
              (dateChange)="changeDateAstreinte($event, i)"
            />
            <mat-datepicker-toggle matIconSuffix [for]="dateFinPicker" *ngIf="!dateFinRO"></mat-datepicker-toggle>
            <mat-datepicker #dateFinPicker></mat-datepicker>
            <mat-error *ngIf="hour.get('date_fin')?.hasError('required')"> Date fin est <strong>obligatoire</strong> </mat-error>
            <mat-error *ngIf="hour.get('date_fin')?.hasError('matDatepickerMax')"
              >La date maximum autorisée est le
              {{ hour.get('date_fin')?.errors?.['matDatepickerMax']['max'] | date: 'dd/MM/yyyy' }}</mat-error
            >
            <mat-error *ngIf="hour.get('date_fin')?.hasError('matDatepickerMin')"
              >La date minimum autorisée est le
              {{ hour.get('date_fin')?.errors?.['matDatepickerMin']['min'] | date: 'dd/MM/yyyy' }}</mat-error
            >
          </mat-form-field>

          <mat-form-field style="width: 26%; margin-left: 20px" floatLabel="always" class="champ-disabled">
            <mat-label>Nombre </mat-label>
            <input type="number" matInput formControlName="nombre" (change)="changeNombre($event, i)" min="1" [readonly]="isNombreRO" />
            <mat-error *ngIf="hour.get('nombre')?.hasError('required')"> Le nombre est <strong>obligatoire</strong> </mat-error>
            <mat-error *ngIf="hour.get('nombre')?.hasError('min')"> Le nombre doit être supérieur à 1</mat-error>
          </mat-form-field>

          <a mat-icon-button color="primary" class="small form-control ml-3" (click)="remove_inputs(i)" title="Supprimer la date">
            <mat-icon>delete</mat-icon>
          </a>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- btn add -->
  <div class="w-100" class="bottom-ajouter" style="bottom: 160px">
    <div style="display: inline-flexbox; background-color: #ffffff; padding-bottom: 10px">
      <button
        mat-stroked-button
        color="primary"
        style="text-wrap: nowrap; background: #ffffff; padding: 13px"
        class="ml-auto"
        (click)="add_inputs()"
      >
        <mat-icon>add</mat-icon>Ajouter une date
      </button>
    </div>
  </div>

  <!-- zone fixe en bas de la modale -->
  <div class="bottom-spacer"></div>
  <div class="bottom-fixed">
    <mat-divider style="border-top-width: 3px" class="my-1"></mat-divider>

    <div class="row gx-0 d-flex mt-3 div-agent">
      <app-bandeau-parcourir-ast
        [currentIndex]="this.currentIndex"
        [nbFormulaires]="this.infosFormulaires.length"
        [actionsActives]="isFormulaireValid()"
        [totalJour]="this.totalJourAstreinte"
        (showNext)="formulaireSuivant()"
        (showPrev)="formulairePrecedent()"
        (newForm)="formulaireNew()"
        (deleteForm)="formulaireDelete()"
      ></app-bandeau-parcourir-ast>
    </div>
    <h5>
      Vous devez renseigner au moins un agent avant de passer à l'étape suivante. Les éléments marqués d'une étoile(*) sont obligatoires.
    </h5>
  </div>
</form>
