<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-10">
      <div class="titre-modale">Notifications de la campagne</div>
    </div>

    <div class="col-md-2 text-end mb-0 mr-0 ml-auto gx-0">
      <button mat-dialog-close class="py-0" mat-icon-button aria-label="Fermer la modale" (click)="fermerModale()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>

<mat-dialog-content class="mat-typography">
  <!-- form -->
  <form [formGroup]="form" class="row">
    <div class="col-md-12 mt-2">
      <mat-form-field class="example-full-width" floatLabel="always" style="width: 100%">
        <mat-label>Nom de la campagne </mat-label>
        <input class="pt-0" matInput class="form-control" id="nom" formControlName="nom" readonly="readonly" />
        <mat-error *ngIf="form.controls['nom'].hasError('required')">Le nom est obligatoire.</mat-error>
      </mat-form-field>
    </div>
    <div class="row g-0 mt-0 btn-group">
      <div class="col-md-6" style="flex: 0 0 auto">
        <input
          type="checkbox"
          [(ngModel)]="data['flagEmailCreationEnvoye']"
          [ngModelOptions]="{ standalone: true }"
          readonly="readonly"
          disabled="disabled"
        />Email de création envoyé
      </div>
      <div class="col-md-6" style="flex: 0 0 auto">
        <mat-label class="label-texte">Date d'envoi de l'email de création</mat-label>
        <p>{{ data['dateEmailCreationEnvoye'] ? (data['dateEmailCreationEnvoye'] | date: 'dd/MM/yyyy HH:mm') : 'date non renseignée' }}</p>
      </div>
      <div class="col-md-6" style="flex: 0 0 auto; width: 100%">
        <button #bouttonValider2 mat-flat-button color="primary" (click)="sendEmailCreation()">Envoyer l'email de création</button>
      </div>
    </div>
    <div class="row g-0 mt-0 btn-group">
      <div class="col-md-6" style="flex: 0 0 auto">
        <input
          type="checkbox"
          [(ngModel)]="data['flagEmailDebutSaisieEnvoye']"
          [ngModelOptions]="{ standalone: true }"
          readonly="readonly"
          disabled="disabled"
        />Email de début de saisie envoyé
      </div>
      <div class="col-md-6" style="flex: 0 0 auto">
        <mat-label class="label-texte">Date d'envoi de l'email de début de saisie</mat-label>
        <p>
          {{ data['dateEmailDebutSaisieEnvoye'] ? (data['dateEmailDebutSaisieEnvoye'] | date: 'dd/MM/yyyy HH:mm') : 'date non renseignée' }}
        </p>
      </div>
      <div class="col-md-6" style="flex: 0 0 auto; width: 100%">
        <button #bouttonValider2 mat-flat-button color="primary" (click)="sendEmailDebutSaisie()">
          Envoyer l'email de début de saisie
        </button>
      </div>
    </div>
    <div class="row g-0 mt-0 btn-group">
      <div class="col-md-6" style="flex: 0 0 auto">
        <input
          type="checkbox"
          [(ngModel)]="data['flagEmailDebutValidationEnvoye']"
          [ngModelOptions]="{ standalone: true }"
          readonly="readonly"
          disabled="disabled"
        />Email de début de validation envoyé
      </div>
      <div class="col-md-6" style="flex: 0 0 auto">
        <mat-label class="label-texte">Date d'envoi de l'email de début de validation</mat-label>
        <p>
          {{
            data['dateEmailDebutValidationEnvoye']
              ? (data['dateEmailDebutValidationEnvoye'] | date: 'dd/MM/yyyy HH:mm')
              : 'date non renseignée'
          }}
        </p>
      </div>
      <div class="col-md-6" style="flex: 0 0 auto; width: 100%">
        <button #bouttonValider2 mat-flat-button color="primary" (click)="sendEmailDebutValidation()">
          Envoyer l'email de début de validation
        </button>
      </div>
    </div>
    <div class="row g-0 mt-0 btn-group">
      <div class="col-md-6" style="flex: 0 0 auto">
        <input
          type="checkbox"
          [(ngModel)]="data['flagEmailDebutValidationDgEnvoye']"
          [ngModelOptions]="{ standalone: true }"
          readonly="readonly"
          disabled="disabled"
        />Email de début de validation DG envoyé
      </div>
      <div class="col-md-6" style="flex: 0 0 auto">
        <mat-label class="label-texte">Date d'envoi de l'email de début de validation DG</mat-label>
        <p>
          {{
            data['dateEmailDebutValidationDgEnvoye']
              ? (data['dateEmailDebutValidationDgEnvoye'] | date: 'dd/MM/yyyy HH:mm')
              : 'date non renseignée'
          }}
        </p>
      </div>
      <div class="col-md-6" style="flex: 0 0 auto; width: 100%">
        <button #bouttonValider2 mat-flat-button color="primary" (click)="sendEmailDebutValidationDg()">
          Envoyer l'email de début de validation DG
        </button>
      </div>
    </div>
    <div class="row g-0 mt-0 btn-group">
      <div class="col-md-6" style="flex: 0 0 auto">
        <input
          type="checkbox"
          [(ngModel)]="data['flagEmailFinValidationEnvoye']"
          [ngModelOptions]="{ standalone: true }"
          readonly="readonly"
          disabled="disabled"
        />Email de fin de validation envoyé
      </div>
      <div class="col-md-6" style="flex: 0 0 auto">
        <mat-label class="label-texte">Date d'envoi de l'email de fin de validation</mat-label>
        <p>
          {{
            data['dateEmailFinValidationEnvoye'] ? (data['dateEmailFinValidationEnvoye'] | date: 'dd/MM/yyyy HH:mm') : 'date non renseignée'
          }}
        </p>
      </div>
      <div class="col-md-6" style="flex: 0 0 auto; width: 100%">
        <button #bouttonValider2 mat-flat-button color="primary" (click)="sendEmailFinValidation()">
          Envoyer l'email de fin de validation
        </button>
      </div>
    </div>
    <div class="row g-0 mt-0 btn-group">
      <div class="col-md-6" style="flex: 0 0 auto">
        <input
          type="checkbox"
          [(ngModel)]="data['flagEmailRhEnvoye']"
          [ngModelOptions]="{ standalone: true }"
          readonly="readonly"
          disabled="disabled"
        />Email DRH envoyé
      </div>
      <div class="col-md-6" style="flex: 0 0 auto">
        <mat-label class="label-texte">Date d'envoi de l'email DRH</mat-label>
        <p>{{ data['dateEmailRhEnvoye'] ? (data['dateEmailRhEnvoye'] | date: 'dd/MM/yyyy HH:mm') : 'date non renseignée' }}</p>
      </div>
      <div class="col-md-6" style="flex: 0 0 auto; width: 100%">
        <button #bouttonValider2 mat-flat-button color="primary" (click)="sendEmailDRH()">Envoyer l'email DRH</button>
      </div>
    </div>
  </form>
  <mat-divider style="border-top-width: 1px" class="mt-4 mb-1"></mat-divider>
  <h5 class="text-footer">Les éléments marqués d'une étoile (*) sont obligatoires</h5>
</mat-dialog-content>
<mat-dialog-actions style="background-color: rgba(250, 250, 252, 1)" align="end">
  <button mat-dialog-close mat-stroked-button class="mx-3" color="primary" (click)="fermerModale()">Fermer</button>
</mat-dialog-actions>
