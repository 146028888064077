import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { CardComponent } from './card/card.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { Router } from '@angular/router';
import { RoleService } from 'src/app/services/role.service';
import { CampagneActuelleComponent } from './campagne-actuelle/campagne-actuelle.component';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { environment } from 'src/environments/environment';
import { Observable, Subscription, take, of, filter, Subject, takeUntil } from 'rxjs';
import { FormsModule, FormBuilder, ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { EChartsOption } from 'echarts';
import { HeuresSupplémentairesService } from 'src/app/services/heures-supplémentaires.service';
import { DirectionService } from 'src/app/services/direction.service';
import { CampagnesService } from 'src/app/services/campagnes.service';
import { Duration } from 'luxon';
import { CampagneActuService } from './campagne-actuelle/campagne-actuelle.service';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { MatDialog } from '@angular/material/dialog';

import { ExcelService } from 'src/app/core/services/excel.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    CampagneActuelleComponent,
    MatCardModule,
    MatButtonModule,
    MatGridListModule,
    MatIconModule,
    CardComponent,
    MatDividerModule,
    MatListModule,
    MatToolbarModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    NgxEchartsDirective,
    MatAutocompleteModule
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    provideEcharts(),
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' }
    }
  ]
})
export class HomeComponent implements OnInit, OnDestroy {
  public cards = [
    {
      icon: 'format_align_left',
      title: 'Saisies et consultation',
      body: 'Accéder au listing des saisies de la campagne en cours, en attente de validation.Déclarer des heures supplémentaires et astreintes de vos collaborateurs.',
      link: 'SAISIES ET CONSULTATION',
      routerLink: 'saisie-et-consultation'
      // show:false
    },
    {
      icon: 'task_alt',
      title: 'Validation des saisies HS / Astreintes',
      body: 'Accéder au listing des saisies à valider de la campagne en cours.',
      link: 'VALIDATION DES SAISIES',
      routerLink: 'validation-des-saisies',
      requiredRoles: ['dhesa-assistant-cds', 'dhesa-gestionnaire-paie'],
      show: false
    },
    {
      icon: 'upload_file',
      title: 'Export des données',
      body: "Exporter un fichier excel pour préparer le fichier à intégrer dans l'injecteur.",
      link: 'EXPORT DES DONNÉES',
      routerLink: 'export-des-donnees',
      requiredRoles: [
        'dhesa-assistant-cds',
        'dhesa-gestionnaire-paie',
        'dhesa-dg',
        'dhesa-directeur',
        'dhesa-responsable',
        'dhesa-assistant-responsable',
        'dhesa-assistant-directeur'
      ],
      show: false
    },

    {
      icon: 'settings',
      title: 'Administration',
      body: "Donne accès aux fonctions d'administration ",
      link: 'ADMINISTRATION',
      routerLink: 'administration',
      requiredRoles: [
        'dhesa-assistant-cds',
        'dhesa-gestionnaire-paie',
        'dhesa-dg',
        'dhesa-directeur',
        'dhesa-responsable',
        'dhesa-assistant-responsable',
        'dhesa-assistant-directeur'
      ],
      show: false
    }
  ];

  pipe = new DatePipe('fr-FR');
  Routerlinks: string[] = ['accueil', 'saisie-et-consultation', 'validation-des-saisies', 'export-des-donnees', 'administration'];
  userRoles: string[] = [];
  campagneValidation: boolean = false;
  isCampagneEnSaisie: boolean = false;
  chartActif$: Observable<boolean> = of(false);
  isHs: any = true;
  chart_ft_direction: any;
  chart_ft_campagne: any;
  chart_ft_annee: any;
  val_HS_campagne: any = null;
  val_HS_direction: any = null;
  val_Ast_campagne: any = null;
  val_Ast_direction: any = null;
  directionList: any[] = [];
  campagneList: any = [];
  listDirectionOrigine: any = [];
  listCampagne: any = [];
  dataJSONChart1: any;
  userProfile: any = {};
  derCampagne: any = [];
  derCampagneID: any = [];
  dateP = new DatePipe('fr-FR');
  instanceChart: any = {};
  agentConnecte: any = {};

  form_chart: FormGroup = this.formBuilder.group({
    HSdirection: [''],
    HScampagne: ['']
  });

  mes_directions: any = { id: 'mes_directions', libelle: 'Toutes mes directions', structValidation: '' };

  HScampagne = new FormControl('');
  destroys$ = new Subject<void>();
  private subscription: Subscription;

  constructor(
    private redirection: Broadcast,
    private router: Router,
    private RoleService: RoleService,
    private campagneActuService: CampagneActuService,
    private detectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private HS_service: HeuresSupplémentairesService,
    private directionService: DirectionService,
    private campagneServie: CampagnesService,
    public dialog: MatDialog,
    private excelService: ExcelService
  ) {
    this.getUserProfile();
    this.AfficheGraph();
  }

  ngOnInit() {
    this.HS_service.getAllHeuresSupplémentaires()
      .pipe(take(1))
      .subscribe({
        next: (result) => {},
        error: (error) => {
          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: 'Le compte utilisé est mal configuré'
            }
          });
        }
      });
    this.RoleService.getRole().subscribe((roles) => {
      this.userRoles = roles;

      this.verifCampagne();

      // si la session campagneActuelle n'existe pas, on relance le service campagneActuService
      // pour optimiser il faudrait pouvoir créer cette session avant le chargement de la page d'accueil
      if (
        sessionStorage.getItem('campagneActuelle') == null ||
        sessionStorage.getItem('campagneActuelle') == '' ||
        sessionStorage.getItem('campagneActuelle') == '{}'
      ) {
        this.campagneActuService.getCampagneActuelle().subscribe((data) => {
          this.verifCampagne();
          this.cardAccess();
        });
      } else {
        this.cardAccess();
      }
    });

    this.loadDirectionList();
    this.loadCampagneList();

    // initialisation des graphiques
  }

  ngOnDestroy() {
    this.chartActif$ = of(false);
    this.subscription.unsubscribe();
  }

  // affichage du cadre des graphiques
  AfficheGraph() {
    this.subscription = this.router.events.pipe().subscribe((event) => {
      const navigation = this.router.getCurrentNavigation();
      let param_chartActif = navigation?.extras?.queryParams?.['chartActif'];
      if (param_chartActif != undefined) {
        this.chartActif$ = of(param_chartActif);
      }
    });
  }

  navigateToLink(item: any) {
    this.router.navigateByUrl(item.routerLink);
    this.redirection.broadcastEvent(item.link);
  }

  async cardAccess() {
    console.log(
      'this.campagneValidation',
      this.campagneValidation,
      'this.isCampagneEnSaisie',
      this.isCampagneEnSaisie,
      'environment.features.validation_en_saisie',
      environment.features.validation_en_saisie
    );

    this.cards.forEach((card) => {
      // Attention : le paramètre 'hasRole' est mal nommé : c'est plutôt 'Rôle interdit pour avoir
      // accès à cette fonction' sa vraie signification
      const hasRole = card.requiredRoles?.some((role) => this.userRoles.includes(role));
      card.show = !hasRole;

      // Menu Aide activé ?
      if (card.link == 'AIDE' && environment.features.menu_aide) {
        card.show = true;
      }

      // si aucune campagne de validation en cours on masque la tuile validation
      if (card.link == 'VALIDATION DES SAISIES' && !this.campagneValidation) {
        card.show = false;
      }
      /**
       * DHESA-484 : on permet la validation pendant la saisie si feature flag correspondant activé
       */
      if (!hasRole && card.link == 'VALIDATION DES SAISIES' && this.isCampagneEnSaisie && environment.features.validation_en_saisie) {
        card.show = true;
      }
    });
  }

  // vérifie si une campagne de validation est en cours
  verifCampagne() {
    let campagneActuelle: any = sessionStorage.getItem('campagneActuelle');
    try {
      let _campagneActuelle = JSON.parse(campagneActuelle);
      this.campagneValidation = _campagneActuelle?.type == 'VALIDATION_EN_COURS' ? true : false;
      this.isCampagneEnSaisie = _campagneActuelle?.type == 'SAISIE_EN_COURS' ? true : false;
      console.log('verifCampagne : validation en cours ? ' + this.campagneValidation + ' / saisie en cours ? ' + this.isCampagneEnSaisie);
    } catch (error) {
      //Pb parsing infos campagne
      this.campagneValidation = false;
      this.isCampagneEnSaisie = false;
    }
  }

  redirect_vue_globale() {
    this.router.navigate(['/', 'saisie-et-consultation']);
  }

  radioChange(event: any) {
    this.isHs = event.value;
  }

  /** GRAPHIQUES **/

  directionFilter(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.directionList = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.directionList = this.listDirectionOrigine.filter((item: any) =>
      item.libelle.toLowerCase().split(' ').join('').includes(filterValue)
    );
  }
  campagneFilter(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.campagneList = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.campagneList = this.listCampagne.filter((campagne: any) => campagne?.nom.toLowerCase().split(' ').join('').includes(filterValue));
  }

  effaceCampagneFiltre(event: any, controlName: string) {
    event.stopPropagation();
    this.val_HS_campagne = '';
    this.getData_charts([], '');
  }

  directionOriginedisplayFn(direction?: any): string {
    return direction ? direction.libelle : undefined;
  }
  // campagnedisplayFn(value: string): any[] {
  //   const filterValue = value.toLowerCase();
  //   return this.campagneList.filter((campagne) => campagne?.nom.toLowerCase().includes(filterValue));
  // }
  campagnedisplayFn(campagne?: any): string {
    return campagne ? campagne?.nom : undefined;
  }

  loadDirectionList() {
    this.directionService
      .getMesDirections()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          console.log('listDirectionOrigine', data);
          this.listDirectionOrigine = data.filter((o: any) => {
            if (o.libelle !== '') {
              return o;
            }
          });

          if (this.listDirectionOrigine.length > 1) {
            this.listDirectionOrigine.unshift(this.mes_directions);
          }

          this.directionList = [...this.listDirectionOrigine];
        } else {
          console.log('error');
        }
      });
  }

  loadCampagneList() {
    this.campagneServie
      .getAllCampagnes()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.listCampagne = data.results;
          this.campagneList = [...this.listCampagne];
          this.getDerniereCampagne();
        } else {
          console.log('error');
        }
      });
  }

  // filtre campagne, limite le nombre de choix à 3
  campOptionDisabled(opt: any): boolean {
    if (this.val_HS_campagne != null && this.val_HS_campagne.length >= 3) {
      let campagneID = this.val_HS_campagne.map((ligne: any) => ligne.id);
      if (campagneID.includes(opt.id)) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  getUserProfile() {
    const ss_userProfile: any = sessionStorage.getItem('userProfile');
    this.userProfile = JSON.parse(ss_userProfile);
    console.log('getUserProfile userProfile', this.userProfile);
  }

  // récupération des paramètres par défauts pour les graphiques
  getDerniereCampagne() {
    // infos sur les 3 dernières campagne
    this.HS_service.getCampagneDerniere()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.derCampagneID = result.map((ligne: any) => ligne.id);

          // valeur par défatu du menu + coche les cases de la multi-sélection
          this.derCampagne = [];
          this.campagneList.forEach((camp: any) => {
            if (this.derCampagneID.includes(camp.id)) {
              this.derCampagne.push(camp);
            }
          });
          this.val_HS_campagne = this.derCampagne;

          // infos sur la direction
          this.HS_service.loggedAgent
            .pipe(
              filter((result) => result),
              takeUntil(this.destroys$)
            )
            .subscribe((result) => {
              if (result) {
                this.agentConnecte = result;
                this.val_HS_direction = this.listDirectionOrigine.length > 1 ? this.mes_directions : this.agentConnecte.direction;
                // lance la création des graphiques
                this.getData_charts('3der');
              } else {
                console.log('error');
              }
            });
        } else {
          console.log('error');
        }
      });
  }

  onChartInit(event: any, nom: string) {
    this.instanceChart[nom] = event;
  }

  changeFiltreChart(option: any) {
    if (option) {
      let campagne = this?.val_HS_campagne || '';
      let directionId = this?.val_HS_direction?.id || this?.val_HS_direction || '';

      this.getData_charts(campagne, directionId);
    }
  }

  // lecture des données pour les graphiques
  getData_charts(campagne?: any, directionId?: any) {
    let campagneID = [];
    if (this.val_HS_campagne == '3der' && this.derCampagne.length > 0) {
      campagne = this.derCampagne;
    } else {
      campagne = this.val_HS_campagne;
    }

    if (campagne.length > 0) {
      campagneID = campagne.map((ligne: any) => ligne.id);
    }

    if (directionId == null || directionId == '') {
      directionId = this.listDirectionOrigine.length > 0 ? 'mes_directions' : this.agentConnecte.direction.id;
    }

    this.HS_service.getCampagneStat(campagneID, directionId)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          console.log('getCampagneStat:', result);

          // graphique 1
          // type = HS
          let dataHS = this.Chart1_transformData(result, 'HS');
          console.log('dataHS', dataHS);

          if (dataHS != null) {
            this.chartHS1_data = {
              series: [
                {
                  data: dataHS
                }
              ]
            };
          } else {
            this.chartHS1_data = null;
          }

          // graphique 2
          // type = HS
          let dataHS_2 = this.Chart2_transformData(result, 'HS');
          console.log('dataHS_2', dataHS_2);

          if (dataHS_2 != null) {
            this.chartHS2_data = {
              title: {
                text: dataHS_2['title']
              },
              legend: {
                data: dataHS_2['legend']
              },
              dataset: [
                {
                  source: dataHS_2['source']
                }
              ],
              series: dataHS_2['series']
            };
          } else {
            this.chartHS2_data = null;
          }

          // type = astreintes
          let dataAst_2 = this.Chart2_transformData(result, 'astreinte');
          console.log('dataAst_2', dataAst_2);

          if (dataAst_2 != null) {
            this.chartAst2_data = {
              title: {
                text: dataAst_2['title']
              },
              legend: {
                data: dataAst_2['legend']
              },
              dataset: [
                {
                  source: dataAst_2['source']
                }
              ],
              series: dataAst_2['series']
            };
          } else {
            this.chartAst2_data = null;
          }
        } else {
          console.log('error');
        }
      });
  }

  Chart1_transformData(result: any, typeSaisie: string) {
    let data = null;

    if (result != null && Object.keys(result).length > 0) {
      let mapRepartitionTotal = result?.mapRepartitionTotalHS;

      if (mapRepartitionTotal != undefined && Object.keys(mapRepartitionTotal).length > 0) {
        let MEME_AGENTS_DIRECTION = mapRepartitionTotal?.MEME_AGENTS_DIRECTION || '0:0';
        let MEME_AGENTS_AUTRE_DIRECTION = mapRepartitionTotal?.MEME_AGENTS_AUTRE_DIRECTION || '0:0';
        let AUTRES_AGENTS_MEME_DIRECTION = mapRepartitionTotal?.AUTRES_AGENTS_MEME_DIRECTION || '0:0';

        MEME_AGENTS_DIRECTION = MEME_AGENTS_DIRECTION.split(':', 2);
        MEME_AGENTS_AUTRE_DIRECTION = MEME_AGENTS_AUTRE_DIRECTION.split(':', 2);
        AUTRES_AGENTS_MEME_DIRECTION = AUTRES_AGENTS_MEME_DIRECTION.split(':', 2);

        let val1_d = Duration.fromObject({ hours: MEME_AGENTS_DIRECTION[0], minutes: MEME_AGENTS_DIRECTION[1] });
        let val2_d = Duration.fromObject({ hours: MEME_AGENTS_AUTRE_DIRECTION[0], minutes: MEME_AGENTS_AUTRE_DIRECTION[1] });
        let val3_d = Duration.fromObject({ hours: AUTRES_AGENTS_MEME_DIRECTION[0], minutes: AUTRES_AGENTS_MEME_DIRECTION[1] });

        let val1 = val1_d.valueOf() > 0 ? Number(val1_d.toMillis()) : 0;
        let val2 = val2_d.valueOf() > 0 ? Number(val2_d.toMillis()) : 0;
        let val3 = val3_d.valueOf() > 0 ? Number(val3_d.toMillis()) : 0;

        let tot = val1 + val2 + val3;

        let val1_pc = Math.round((val1 / tot) * 100 * 100) / 100;
        let val2_pc = Math.round((val2 / tot) * 100 * 100) / 100;
        let val3_pc = Math.round((100 - val1_pc - val2_pc) * 100) / 100; // pour que le total soit toujours = 100%

        let mapTypeTotalHS = result?.mapTypeTotalHS;
        let typeTotal: any = { JOUR: mapTypeTotalHS['JOUR'], NUIT: mapTypeTotalHS['NUIT'], FERIE: mapTypeTotalHS['FERIE'] };

        let total_1: any = new Array(3);
        let total_2: any = new Array(3);
        let total_3: any = new Array(3);

        for (let key in typeTotal) {
          let tot = typeTotal[key];
          let _duree = tot != null ? tot.split(':', 2) : [0, 0];
          let stat_d = Duration.fromObject({ hours: _duree[0], minutes: _duree[1] });

          let val_mm = stat_d.valueOf() > 0 ? Number(stat_d.toFormat('mm')) : 0;
          let val_mm_1 = Number((val_mm * Number(val1_pc)) / 100);
          let val_mm_2 = Number((val_mm * Number(val2_pc)) / 100);
          let val_mm_3 = Number((val_mm * Number(val3_pc)) / 100);

          let val_1 = Duration.fromObject({ minutes: val_mm_1 }).toFormat('hh:mm');
          let val_2 = Duration.fromObject({ minutes: val_mm_2 }).toFormat('hh:mm');
          let val_3 = Duration.fromObject({ minutes: val_mm_3 }).toFormat('hh:mm');

          // pour saisies HS, 3 types possibles : JOUR, NUIT, FERIE
          if (key == 'JOUR') {
            total_1[0] = { txt: 'Jour', tot: val_1 };
            total_2[0] = { txt: 'Jour', tot: val_2 };
            total_3[0] = { txt: 'Jour', tot: val_3 };
          } else if (key == 'NUIT') {
            total_1[1] = { txt: 'Nuit', tot: val_1 };
            total_2[1] = { txt: 'Nuit', tot: val_2 };
            total_3[1] = { txt: 'Nuit', tot: val_3 };

            // pour que le calcul de la dernière tranche soit toujours juste, on fait une soustraction des 2 1ères tranches
          } else if (key == 'FERIE') {
            let val_tot_J_1 = Duration.fromISOTime(total_1[0].tot).toObject();
            let val_tot_N_1 = Duration.fromISOTime(total_1[1].tot).toObject();
            let val_JF_1 = Duration.isDuration(val1_d) ? val1_d.minus(val_tot_J_1).minus(val_tot_N_1).toFormat('hh:mm') : '00:00';
            total_1[2] = { txt: 'Dimanches et jours fériés', tot: val_JF_1 };
            let val_tot_J_2 = Duration.fromISOTime(total_2[0].tot).toObject();
            let val_tot_N_2 = Duration.fromISOTime(total_2[1].tot).toObject();
            let val_JF_2 = Duration.isDuration(val2_d) ? val2_d.minus(val_tot_J_2).minus(val_tot_N_2).toFormat('hh:mm') : '00:00';
            total_2[2] = { txt: 'Dimanches et jours fériés', tot: val_JF_2 };
            let val_tot_J_3 = Duration.fromISOTime(total_3[0].tot).toObject();
            let val_tot_N_3 = Duration.fromISOTime(total_3[1].tot).toObject();
            let val_JF_3 = Duration.isDuration(val3_d) ? val3_d.minus(val_tot_J_3).minus(val_tot_N_3).toFormat('hh:mm') : '00:00';
            total_3[2] = { txt: 'Dimanches et jours fériés', tot: val_JF_3 };
          }
        }

        let val1_hm = val1_d.toFormat('hh:mm');
        let val2_hm = val2_d.toFormat('hh:mm');
        let val3_hm = val3_d.toFormat('hh:mm');

        let txt_type = typeSaisie == 'HS' ? 'HS' : 'AS';

        data = [
          {
            //value: result.mapRepartitionTotalHS?.MEME_AGENTS_DIRECTION,
            value: val1,
            name: txt_type + ` réalisées par les agents de ma direction pour ma direction ${val1_hm} (${val1_pc}%)`,
            total: total_1,
            pc: val1_pc,
            couleur: ['#CC9999', '#FC8452', '#9A60B4']
          },
          {
            //value: result.mapRepartitionTotalHS?.MEME_AGENTS_AUTRE_DIRECTION,
            value: val2,
            name: txt_type + ` réalisées par les agents de ma directions pour les autres directions ${val2_hm} (${val2_pc}%)`,
            total: total_2,
            pc: val2_pc,
            couleur: ['#CC9999', '#FC8452', '#9A60B4']
          },
          {
            value: val3,
            //value: result.mapRepartitionTotalHS?.AUTRES_AGENTS_MEME_DIRECTION,
            name: txt_type + ` réalisées par les agents des autres directions pour ma direction ${val3_hm} (${val3_pc}%)`,
            total: total_3,
            pc: val3_pc,
            couleur: ['#CC9999', '#FC8452', '#9A60B4']
          }
        ];
      }
    }
    return data;
  }

  //Chart2HS_transformData(result: any, campagne: any) {
  Chart2_transformData(result: any, typeSaisie: string) {
    let data = null;

    if (result != null && Object.keys(result).length > 0) {
      let mapTypeTotal = typeSaisie == 'HS' ? result?.mapTypeTotalHS : result?.mapTypeTotalAstreintes;

      if (mapTypeTotal != undefined && Object.keys(mapTypeTotal).length > 0) {
        let source: any = [];
        let series: any = [];

        let txTp = typeSaisie == 'HS' ? 'HS' : 'Astreintes';
        let title = txTp;

        let legende = ['campagnes'];
        let type_code: any = [];

        // légende : on garde le nombre d'éléments de légende fixe égale pour toutes les campagnes
        if (typeSaisie == 'HS') {
          legende.push(' Jour');
          legende.push(' Nuit');
          legende.push(' Dimanches et jours fériés');
          type_code = ['JOUR', 'NUIT', 'FERIE'];
        } else {
          legende.push(' Jour');
          legende.push(' Nuit');
          legende.push(' Semaine');
          legende.push(' Week-end');
          legende.push(' Samedi');
          legende.push(' Dimanches et jours fériés');
          legende.push(' Crèche');

          type_code = ['JOUR', 'NUIT', 'SEMAINE', 'WEEKEND', 'SAMEDI', 'DIMANCHE_FERIE', 'CRECHE'];
        }

        // pour chaque type il faut ajouter une série pour que séries=légendes-1
        for (let i = 0; i < type_code.length; i++) {
          let name = legende[i + 1];
          series.push({ name: name, type: 'bar', barMaxWidth: 80 });
        }

        source.push(legende);

        let campagne = result.statsCampagnes;

        campagne.forEach((camp: any) => {
          let ligne = [];
          let stat = typeSaisie == 'HS' ? camp?.mapTypeTotalHS : camp?.mapTypeTotalAstreintes;

          let total = 0;
          let total_duree: any = Duration.fromObject({ hours: 0, minutes: 0 });
          let _statVal: any = [];
          let _stat_duree: any = [];

          // pour chaque code on créé une barre même si la valeur n'existe pas
          for (let code of type_code) {
            if (stat[code] != undefined) {
              let statVal = stat[code];
              let _duree = statVal != null ? statVal.split(':', 2) : [0, 0];
              let stat_d = Duration.fromObject({ hours: _duree[0], minutes: _duree[1] });
              // chiffres arrondis pour les barres
              let val = stat_d.valueOf() > 0 ? Number(stat_d.toFormat('hh')) : 0;
              _statVal.push(val);
              // précision avec minutes pour les tooltips
              let val_hm = stat_d.valueOf() > 0 ? stat_d.toFormat('hh:mm') : '00:00';
              _stat_duree.push(val_hm);

              total_duree = total_duree.plus({ hours: stat_d.hours, minutes: stat_d.minutes });
              total += Number(val);
            } else {
              _statVal.push(0);
            }
          }

          total_duree = total_duree.toFormat('hh:mm');
          let dateDebutCampagne = this.dateP.transform(camp?.dateDebutCampagne, 'dd/MM/yyyy');
          let dateFinCampagne = this.dateP.transform(camp?.dateFinCampagne, 'dd/MM/yyyy');
          let statut = camp?.statutCampagne == 'SAISIE_EN_COURS' ? ' -  {sta_s|Saisie en cours}' : '';
          statut = camp?.statutCampagne == 'ARCHIVEE' ? ' -  {sta_a|Archivée}' : statut;

          let txTot = typeSaisie == 'HS' ? total_duree : total;
          let Cp_nom =
            camp?.nomCampagne + ' - du ' + dateDebutCampagne + ' au ' + dateFinCampagne + '\nTotal ' + txTp + ': ' + txTot + statut;

          ligne.push(Cp_nom);
          ligne = [...ligne, ..._statVal];
          let tooltip: any = {};

          // ajout d'informations pour les tooltip
          if (typeSaisie == 'HS') {
            tooltip = {
              type: 'hs',
              legende: ['HS' + legende[1], 'HS' + legende[2], 'HS' + legende[3]],
              titre: camp?.nomCampagne + ' - du ' + dateDebutCampagne + ' au ' + dateFinCampagne,
              duree: _stat_duree
            };
          } else {
            tooltip = {
              type: 'astreinte',
              legende: [
                'Astreintes' + legende[1],
                'Astreintes' + legende[2],
                'Astreintes' + legende[3],
                'Astreintes' + legende[4],
                'Astreintes' + legende[5],
                'Astreintes' + legende[6],
                'Astreintes' + legende[7]
              ],
              titre: camp?.nomCampagne + ' - du ' + dateDebutCampagne + ' au ' + dateFinCampagne
            };
          }

          ligne.push(tooltip);

          source.push(ligne);
        });

        data = { title: title, source: source, series: series, legend: legende };
      }
    }
    return data;
  }

  /**
   * Export CSV des statistiques suivant le filtre appliqué sur les graphes :
   * - type : HS/astreinte
   * - direction(s) : toutes mes directions ou une en particulier
   * - campagne(s) : les 3 dernières ou une en particulier
   */
  export_stats() {
    let campagne = this?.val_HS_campagne || '';
    let directionId = this?.val_HS_direction?.id || this?.val_HS_direction || '';
    let isExportHS = this.isHs;

    let campagneID = [];
    if (this.val_HS_campagne == '3der' && this.derCampagne.length > 0) {
      campagne = this.derCampagne;
    } else {
      campagne = this.val_HS_campagne;
    }

    if (campagne.length > 0) {
      campagneID = campagne.map((ligne: any) => ligne.id);
    }

    if (directionId == null || directionId == '') {
      directionId = this.listDirectionOrigine.length > 0 ? 'mes_directions' : this.agentConnecte.direction.id;
    }

    this.HS_service.getExportCampagneStat(campagneID, directionId, isExportHS)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          let _data_export: any[] = [];

          let dataTable = null;

          if (isExportHS) {
            dataTable = this.transformData_HS(result);
          } else {
            dataTable = this.transformData_astreintes_to_export(result);
          }
          dataTable.forEach((_ligne: any) => {
            let _data = _ligne;
            if (_ligne['subTable'] != undefined) {
              _data = { ..._data, ..._ligne['subTable'][0] };
              delete _data['subTable'];
            }
            delete _data['id'];

            _data['Statut'] = _data['Statut_export'];
            delete _data['Statut_export'];

            _data_export.push(_data);
          });

          this.convertExcel(_data_export, isExportHS ? 'export_donnees_HS_' : 'export_donnees_Astreintes_');
        },
        error: (error) => {
          console.log('error:', error);
          let erreur_texte = error.statusText || error.error.detail || error.error || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Une erreur est survenue lors de l'export des données.",
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  transformData_astreintes_to_export(data: any) {
    // data pour l'export
    let dataJSON = data;
    let dataHS: any[] = [];

    for (let key in dataJSON) {
      let dataJ = dataJSON[key];
      let agent = dataJ?.agent;
      let emetteur = '';
      if (dataJ?.emetteur?.nom && dataJ?.emetteur?.prenom) {
        emetteur = dataJ?.emetteur?.nom + ' ' + dataJ?.emetteur?.prenom;
      }
      let _data: any = {};
      _data['Agent'] = agent?.nom + ' ' + agent?.prenom;
      _data['Matricule'] = agent?.matricule;

      let typeCode = dataJ?.type;
      let typeText = '';
      if (typeCode == 'JOUR') {
        typeText = 'Jour';
      } else if (typeCode == 'NUIT') {
        typeText = 'Nuit';
      } else if (typeCode == 'SEMAINE') {
        typeText = 'Semaine';
      } else if (typeCode == 'DIMANCHE_FERIE') {
        typeText = 'Dimanche/J.F';
      } else if (typeCode == 'CRECHE') {
        typeText = 'Crèche';
      } else if (typeCode == 'SAMEDI') {
        typeText = 'Samedi';
      } else if (typeCode == 'WEEKEND') {
        typeText = 'Week-end';
      }
      _data['Type'] = typeText;

      _data['Date création'] = this.pipe.transform(dataJ?.dateCreation, 'shortDate');
      _data['Date début'] = this.pipe.transform(dataJ?.dateDebut, 'shortDate');
      _data['Date fin'] = this.pipe.transform(dataJ?.dateFin, 'shortDate');

      _data['Nombre'] = dataJ?.nombre;
      _data['Emetteur'] = emetteur;
      _data['Direction origine'] = dataJ?.directionOrigine?.libelle;

      let status_code = dataJ?.statut;
      let status_text = {};
      if (status_code == 'A_VALIDER') {
        status_text = { class: 'badge-gray', text: 'Avis non renseigné', object: true };
      } else if (status_code == 'VALIDEES') {
        status_text = { class: 'badge-green-directeur', text: 'Avis favorable - Directeur', object: true };
      } else if (status_code == 'REFUSEES') {
        status_text = { class: 'badge-red', text: 'Avis défavorable - Directeur', object: true };
      } else if (status_code == 'VALIDEES_N2') {
        status_text = { class: 'badge-green-dga', text: 'Avis favorable - DG', object: true };
      } else if (status_code == 'REFUSEES_N2') {
        status_text = { class: 'badge-red', text: 'Avis défavorable - DG', object: true };
      } else if (status_code == 'SAISIE_A_RENOUVELER') {
        status_text = { class: 'badge-gray', text: 'Saisie à renouveler', object: true };
      } else if (status_code == 'A_VALIDER_RESPONSABLE') {
        status_text = { class: 'badge-gray', text: 'Avis non renseigné (responsable)', object: true };
      } else if (status_code == 'VALIDEES_RESPONSABLE') {
        status_text = { class: 'badge-green-directeur', text: 'Avis favorable - Responsable', object: true };
      } else if (status_code == 'REFUSEES_RESPONSABLE') {
        status_text = { class: 'badge-red', text: 'Avis défavorable - Responsable', object: true };
      }
      //@ts-ignore
      _data['Statut'] = status_text.text;

      _data['Campagne'] = dataJ?.campagne?.nom;
      _data['Service origine'] = dataJ?.serviceFonctionnel?.libelle;
      dataHS.push(_data);
    }

    return dataHS;
  }

  convertExcel(data: any, name: string) {
    const search = '/';
    const replaceWith = '-';
    const date = new Date().toLocaleDateString('en-GB').split(search).join(replaceWith);
    let fileName = `${name}${date}`;
    this.excelService.exportToExcel(data, `${fileName}.xlsx`, 'Sheet1');
  }

  transformData_HS(data2?: any) {
    // data pour l'export
    let dataJSON = data2;

    let dataHS: any[] = [];
    let roles = this.userRoles;

    for (let key in dataJSON) {
      let dataJ = dataJSON[key];
      let agent = dataJ?.agent;
      let emetteur = '';
      if (dataJ?.emetteur?.nom && dataJ?.emetteur?.prenom) {
        emetteur = dataJ?.emetteur?.nom + ' ' + dataJ?.emetteur?.prenom;
      }
      let _data: any = {};

      _data['id'] = dataJ?.id;
      _data['Agent'] = agent?.nom + ' ' + agent?.prenom;
      _data['Matricule'] = agent?.matricule;

      let typeCode = dataJ?.typeHS;
      let typeText = '';
      if (typeCode == 'FERIE') {
        typeText = 'Dim./Férié';
      } else if (typeCode == 'NUIT') {
        typeText = 'Nuit';
      } else if (typeCode == 'JOUR') {
        typeText = 'Jour';
      }
      _data['Type'] = typeText;
      if (dataJ) {
        _data['Date création'] = this.pipe.transform(dataJ?.dateCreation, 'shortDate');
        _data['Date HS'] = this.pipe.transform(dataJ?.dateRealisation, 'shortDate');
      }

      _data['Heure début'] = dataJ?.heureDebut.slice(0, -3);

      // arrondit les secondes en minutes
      // dataJ.heureFin = '23:59:59'; // pour test
      let _heure_fin = dataJ?.heureFin.split(':').map((a: any) => Number(a));

      if (_heure_fin[2] != undefined && _heure_fin[2] > 30) {
        _heure_fin[2] = 0;
        if (_heure_fin[1] == 59) {
          _heure_fin[1] = 0;
          _heure_fin[0] = _heure_fin[0] == 23 ? 0 : _heure_fin[0] + 1;
        } else {
          _heure_fin[1] += 1;
        }
      }
      let d_heure_fin = Duration.fromObject({ hours: _heure_fin[0], minutes: _heure_fin[1], seconds: _heure_fin[2] });
      _data['Heure fin'] = d_heure_fin.toFormat('hh:mm');

      let _duree: any = dataJ?.duree.split(':');
      let hr = _duree[0].toString().length == 1 ? '0' + _duree[0] : _duree[0];
      let min = _duree[1].toString().length == 1 ? '0' + _duree[1] : _duree[1];
      _data['Durée'] = hr + ':' + min;

      _data['Emetteur'] = emetteur;
      _data['Direction origine'] = dataJ?.directionOrigine?.libelle;
      _data['Direction évènement'] = dataJ?.directionOrganisatrice?.libelle;
      _data['Motif'] = dataJ?.motif;

      let status_code = dataJ?.statut;
      let status_text = '';
      let status_class = '';

      if (status_code == 'A_VALIDER') {
        status_class = 'badge-gray';
        status_text = 'Avis non renseigné';
      } else if (status_code == 'VALIDEES') {
        status_class = 'badge-green-directeur';
        status_text = 'Avis favorable - Directeur';
      } else if (status_code == 'REFUSEES') {
        status_class = 'badge-red';
        status_text = 'Avis défavorable - Directeur';
      } else if (status_code == 'VALIDEES_N2') {
        status_class = 'badge-green-dga';
        status_text = 'Avis favorable - DG';
      } else if (status_code == 'REFUSEES_N2') {
        status_class = 'badge-red';
        status_text = 'Avis défavorable - DG';
      } else if (status_code == 'SAISIE_A_RENOUVELER') {
        status_class = 'badge-gray';
        status_text = 'Saisie à renouveler';
      } else if (status_code == 'A_VALIDER_RESPONSABLE') {
        status_class = 'badge-gray';
        status_text = 'Avis non renseigné (responsable)';
      } else if (status_code == 'VALIDEES_RESPONSABLE') {
        status_class = 'badge-green-directeur';
        status_text = 'Avis favorable - Responsable';
      } else if (status_code == 'REFUSEES_RESPONSABLE') {
        status_class = 'badge-red';
        status_text = 'Avis défavorable - Responsable';
      }

      _data['Statut'] = { class: status_class, text: status_text, object: true };
      _data['status_code'] = status_code;
      _data['Statut_export'] = status_text; // champ pour la fonction export

      _data['subTable'] = [];
      let _subTable: any = {};

      _subTable['Campagne'] = dataJ?.campagne?.nom;
      _subTable['Service origine'] = dataJ?.serviceFonctionnel?.libelle;

      _data['subTable'].push(_subTable);
      dataHS.push(_data);
    }

    return dataHS;
  }

  dataJSON_chart1: any = [];

  Chart1HStransformData_temp(result: any) {
    let datachart: any = [];

    for (let key in this.dataJSON_chart1) {
      let _data: any = {};
      _data['id'] = key;
      datachart.push(_data);
    }

    return datachart;
  }

  // graphiques pour les heures supplémentaires
  // Graph 1
  chartHS1_data: EChartsOption | null = null;

  chartHS1_options: EChartsOption = {
    title: {
      text: '',
      subtext: '',
      left: 'center'
    },
    tooltip: {
      trigger: 'item',
      borderWidth: 2,
      //formatter: 'Type HS :<br />Jour : {c0}<br />Nuit : 11<br />Dimanches et jours fériés :  4'
      formatter: function (param: any) {
        let text = '';
        let total = param?.data?.total;

        text = '<div style="height:26px">Type HS</div>';

        if (total != undefined && total.length > 0) {
          let i = 0;
          text += '<div style="display: table">';
          total.forEach((_val: any) => {
            let tot = _val.tot;
            let marker = param.marker.replace(param.color, param?.data?.couleur[i]);

            text += '<div style="display: table-row"><div style="display: table-cell; width: 150px">';
            text += marker + ' ' + _val.txt + '' + '</div>';
            text += '<div style="display: table-cell; width: 50px; padding-left:12px"><b>' + tot + '</b></div></div>';
            i++;
          });
          text += '</div>';
        }

        return text;
      },
      textStyle: {}
      //borderColor: '#4165D2'
    },
    rich: {
      b: {
        fontWeight: 'bold'
      }
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      show: false
    },
    grid: {
      left: '10%',
      right: '10%',
      top: 0,
      bottom: 0,
      height: 10
    },
    series: [
      {
        name: 'Directions',
        type: 'pie',
        radius: '80%',
        center: ['50%', '50%'],
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  // Graph 2
  chartHS2_data: EChartsOption | null = null;

  chartHS2_options: EChartsOption = {
    title: {
      left: 'left'
    },
    legend: {
      orient: 'horizontal',
      width: '100%',
      right: '10%',
      data: []
      /*
      formatter: function (param: any) {
        console.log('param', param);
        let _text = param.split(' ');
        let debut = _text.shift();
        let text = _text.join(' ');
        return text;
      }
      */
      //data: ['HS Jour', 'HS nuit']
    },
    tooltip: {
      trigger: 'item',
      borderWidth: 2,
      formatter: function (param: any) {
        let text = '';
        let nb_data = param.data.length;
        let data = param?.data[nb_data - 1];
        let index = Number(param.seriesIndex);
        let type = data?.type;

        if (data != undefined) {
          let legende = '<div style="height:26px">' + data.legende[index] + '</div>';
          let titre = data.titre;
          text += legende + param.marker + ' ' + titre + ' ' + ' &nbsp;&nbsp;<span style="font-size:16px"><b>';
          if (type == 'hs') {
            text += data.duree[index] + '</b></span>';
          } else {
            text += param.data[index + 1] + '</b></span>';
          }
        } else {
          let titre = param.titre.replace(' -  {sta_a|Archivée}', ' ');
          text += param.marker + ' ' + titre + ' ' + ' <b>' + param.data[index + 1] + '</b>';
        }

        return text;
      }

      //renderMode: 'richText'
      //borderColor: '#C28484'
    },
    grid: {
      containLabel: true,
      left: '0px'
    },
    dataset: {
      source: []
    },
    xAxis: {
      type: 'category',
      nameGap: 0,
      axisLabel: {
        //formatter: '-----',
        //margin: 20,
        width: 400,
        margin: 10,
        //padding: 0,
        lineHeight: 20,
        overflow: 'break',
        interval: 0,
        backgroundColor: '#FFFFFF',
        //alignMinLabel: 'left',
        //alignMaxLabel: 'left',
        //align: 'left'
        rich: {
          sta_s: {
            fontWeight: 'bold',
            color: '#fbc955',
            borderColor: '#fbc955',
            borderWidth: 2,
            borderRadius: 5,
            padding: [4, 8, 2, 7]
          },
          sta_a: {
            fontWeight: 'bold',
            color: '#17a2b8',
            borderColor: '#17a2b8',
            borderWidth: 2,
            borderRadius: 5,
            padding: [4, 8, 2, 7]
          }
        }
      }
    },
    yAxis: {},
    color: ['#CC9999', '#FC8452', '#9A60B4', '#ee6666', '#73c0de', '#3ba272', '#FAC858', '#9a60b4', '#ea7ccc'],
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [{ type: 'bar', barMaxWidth: 80 }],
    replaceMerge: ['title', 'dataset', 'series', 'legend'],
    notMerge: false
  };

  // graphiques pour les astreintes

  // Graph 2
  chartAst2_data: EChartsOption | null = null;
  //chartAst2_options: EChartsOption = this.chartHS2_options;
  chartAst2_options: EChartsOption = Object.assign({}, this.chartHS2_options);
}
