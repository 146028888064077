import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet, Router } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { RoleService } from './services/role.service';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  imports: [CommonModule, RouterOutlet, HeaderComponent]
})
export class AppComponent {
  userRoles: string[] = [];

  constructor(
    private roleService: RoleService,
    private router: Router
  ) {}

  ngOnInit() {
    this.roleService.getRole().subscribe((role) => {
      this.userRoles = role;
      this.verifAcces();
    });
  }

  // vérification d'accès à la racine de l'application
  verifAcces() {
    let roles = this.userRoles;
    let profil_admin = roles.includes('dhesa-admin');
    let profil_assistant = roles.includes('dhesa-assistant-cds');
    let profil_dg = roles.includes('dhesa-dg');
    let profil_directeur = roles.includes('dhesa-directeur');
    let profil_gestionnaire = roles.includes('dhesa-gestionnaire-paie');

    let profil_responsable = roles.includes('dhesa-responsable');
    let profil_assistant_responsable = roles.includes('dhesa-assistant-responsable');
    let profil_assistant_directeur = roles.includes('dhesa-assistant-directeur');

    // test

    // broadcast vers la page d'erreur
    if (
      !profil_admin &&
      !profil_assistant &&
      !profil_dg &&
      !profil_directeur &&
      !profil_gestionnaire &&
      !profil_responsable &&
      !profil_assistant_responsable &&
      !profil_assistant_directeur
    ) {
      this.router.navigate(['/', 'acces-interdit']);
    }
    // redirection normale, nécessaire pour rétablir la navigation
    else {
      this.router.navigate([window.location.pathname]);
    }
  }
}
