import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { RoleService } from 'src/app/services/role.service';
import { KeycloakService } from 'keycloak-angular';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { CampagneActuService } from 'src/app/features/home-page/campagne-actuelle/campagne-actuelle.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-body',
  standalone: true,
  imports: [CommonModule, MatTabsModule, RouterModule, MatIconModule],

  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {
  accessProfile: any;

  role: boolean | undefined;
  @Input() userRoles: string[] = [];
  token: string | null | undefined;
  campagneActive: boolean = false;
  links: string[] = [
    'ACCUEIL',
    'SAISIES ET CONSULTATION',
    'VALIDATION DES SAISIES HS / ASTREINTES',
    'EXPORT DES DONNÉES ASTRE / FICHES NAVETTES',
    'ADMINISTRATION'
  ];
  Routerlinks: string[] = ['accueil', 'saisie-et-consultation', 'validation-des-saisies', 'export-des-donnees', 'administration'];
  activeLink: string = this.links[0];

  constructor(
    private kc: KeycloakService,
    private service: RoleService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private campagneActuService: CampagneActuService
  ) {}

  ngOnInit() {
    this.campagneActuService.getCampagneActuelle().subscribe((data) => {
      if (this.userRoles) {
        this.links = ['ACCUEIL', 'SAISIES ET CONSULTATION'];
        this.Routerlinks = ['accueil', 'saisie-et-consultation'];

        if (
          this.userRoles.includes('dhesa-dg') ||
          this.userRoles.includes('dhesa-directeur') ||
          this.userRoles.includes('dhesa-admin') ||
          this.userRoles.includes('dhesa-responsable') ||
          this.userRoles.includes('dhesa-assistant-responsable') ||
          this.userRoles.includes('dhesa-assistant-directeur')
        ) {
          this.links.push('VALIDATION DES SAISIES HS / ASTREINTES');
          this.Routerlinks.push('validation-des-saisies');
        }

        if (this.userRoles.includes('dhesa-admin')) {
          this.links.push('EXPORT DES DONNÉES ASTRE / FICHES NAVETTES');
          this.Routerlinks.push('export-des-donnees');

          this.links.push('ADMINISTRATION');
          this.Routerlinks.push('administration');
        }
      } else {
        this.links = [];
        this.Routerlinks = [];
      }

      console.log('User roles = ' + JSON.stringify(this.userRoles));
      console.log('getCampagneActuelle data: ', data);

      // si aucune campagne active on masque les liens
      if (
        (data?.active != undefined && !data?.active) ||
        (data.type != 'VALIDATION_EN_COURS' && !(data.type == 'SAISIE_EN_COURS' && environment.features.validation_en_saisie))
      ) {
        let index_valid = this.links.indexOf('VALIDATION DES SAISIES HS / ASTREINTES');
        if (index_valid > 0) {
          this.links.splice(index_valid, 1);
          this.Routerlinks.splice(index_valid, 1);
        }
      }
    });
  }

  addLink() {
    this.links.push(`Link ${this.links.length + 1}`);
  }

  canAccessLink(link: string): boolean {
    switch (link) {
      case 'ACCUEIL':
        return true;

      case 'SAISIES ET CONSULTATION':
        return true;

      case 'VALIDATION DES SAISIES HS / ASTREINTES':
        return !(this.userRoles.includes('dhesa-assistant-cds') || this.userRoles.includes('dhesa-gestionnaire-paie'));

      case 'EXPORT DES DONNÉES ASTRE / FICHES NAVETTES':
        return !(
          this.userRoles.includes('dhesa-assistant-cds') ||
          this.userRoles.includes('dhesa-gestionnaire-paie') ||
          this.userRoles.includes('dhesa-dg') ||
          this.userRoles.includes('dhesa-directeur') ||
          this.userRoles.includes('dhesa-responsable') ||
          this.userRoles.includes('dhesa-assistant-responsable') ||
          this.userRoles.includes('dhesa-assistant-directeur')
        );

      case 'ADMINISTRATION':
        return !(
          this.userRoles.includes('dhesa-assistant-cds') ||
          this.userRoles.includes('dhesa-gestionnaire-paie') ||
          this.userRoles.includes('dhesa-dg') ||
          this.userRoles.includes('dhesa-directeur') ||
          this.userRoles.includes('dhesa-responsable') ||
          this.userRoles.includes('dhesa-assistant-responsable') ||
          this.userRoles.includes('dhesa-assistant-directeur')
        );

      default:
        return false;
    }
  }
}
