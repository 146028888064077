import { Injectable } from '@angular/core';
import { RoleService } from 'src/app/services/role.service';
@Injectable({
  providedIn: 'root'
})
export class TabService {
  userRoles: string[] = [];
  private storageKey = 'selectedTabIndex';

  constructor(private roleService: RoleService) {}
  getSelectedTabIndex(): number {
    this.roleService.getRole().subscribe((role) => {
      this.userRoles = role;
    });
    if (
      this.userRoles.includes('dhesa-dg') ||
      this.userRoles.includes('dhesa-directeur') ||
      this.userRoles.includes('dhesa-responsable') ||
      this.userRoles.includes('dhesa-assistant-responsable') ||
      this.userRoles.includes('dhesa-assistant-directeur')
    ) {
      localStorage.setItem(this.storageKey, '2');
      return 2;
    } else {
      localStorage.setItem(this.storageKey, '0');
      return 0;
    }
  }

  setSelectedTabIndex(tabIndex: number): void {
    localStorage.setItem(this.storageKey, tabIndex.toString());
  }
}
