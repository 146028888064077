import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ExportModalComponent } from './export-modal/export-modal.component';

import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { CampagneModalComponent } from './campagne-modal/campagne-modal.component';
import { CampagneNotificationsComponent } from './campagne-notifications/campagne-notifications.component';
import { ArianeNavComponent } from 'src/app/layout/ariane-nav/ariane-nav.component';
import { DeleteModalComponent } from 'src/app/shared/delete-modal/delete-modal.component';
import localeFr from '@angular/common/locales/fr';
import { RoleService } from 'src/app/services/role.service';
import { RestrictedPageComponent } from 'src/app/shared/restricted-page/restricted-page.component';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { take } from 'rxjs';
import { CampagnesService } from 'src/app/services/campagnes.service';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { Broadcast } from 'src/app/core/services/broadcast.service';

registerLocaleData(localeFr);

@Component({
  selector: 'app-gestion-campagne',
  standalone: true,
  imports: [
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    CommonModule,
    DataTableComponent,
    ExportModalComponent,
    MatExpansionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    MatDividerModule,
    ArianeNavComponent,
    RestrictedPageComponent
  ],
  templateUrl: './gestion-campagne.component.html',
  styleUrls: ['./gestion-campagne.component.scss']
})
export class GestionCampagneComponent implements OnInit {
  panelOpenState: boolean = false;

  pipe = new DatePipe('fr-FR'); // Use your own locale
  arianeLabel: string = 'Gestion des campagnes';
  arianeIcon: string = 'calendar_month';
  rowCampagne: any = {};

  togglePanel() {
    console.log('clicked');
    this.panelOpenState = !this.panelOpenState;
  }
  userRoles: string[] = [];
  loading: boolean | undefined;
  dataJSON: any[] = [];
  dataTable: any[] = [];
  dataSource: any;
  lenght: number;
  filterValue: string = '';
  campagneDisplayedColumns: string[] = [
    'Nom',
    'Auteur',
    'Date création',
    'Date début saisie',
    'Date fin saisie',
    'Date début validation',
    'Date fin validation',
    'Date d’archivage',
    'Commentaire',
    'Statut',
    'Email DRH envoyé',
    'Email création envoyé',
    'Email début saisie envoyé',
    'Email début validation envoyé',
    'Email début validation DG envoyé',
    'Email fin validation envoyé',
    'Actions'
  ];

  campagneCellStyleCss: any = {
    'Date création': { 'text-align': 'center', 'padding-right': '30px' },
    'Date début saisie': { 'text-align': 'center', 'padding-right': '30px' },
    'Date fin saisie': { 'text-align': 'center', 'padding-right': '30px' },
    'Date début validation': { 'text-align': 'center', 'padding-right': '30px' },
    'Date début validation DG': { 'text-align': 'center', 'padding-right': '30px' },
    'Date fin validation': { 'text-align': 'center', 'padding-right': '30px' },
    'Date d’archivage': { 'text-align': 'center', 'padding-right': '30px' }
  };

  arianeList = [
    {
      label: 'Administration',
      url: '/administration'
    },
    {
      label: 'Gestion des campagnes',
      url: '/gestion-des-campagnes'
    }
  ];

  constructor(
    public dialog: MatDialog,
    private roleService: RoleService,
    private campagneService: CampagnesService,
    private detectorRef: ChangeDetectorRef,
    private broadcast: Broadcast
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource();

    this.roleService.getRole().subscribe((roles) => {
      this.userRoles = roles;
      console.log('Role', roles);
    });
    this.loadCampagnes();
  }

  loadCampagnes() {
    console.log('called');
    this.loading = true;
    this.campagneService
      .getCampagnesWithParams(environment.pagination.offset, environment.pagination.limit)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.dataJSON = result.results;
          console.log('all data ', this.dataJSON);
          const testedCmp = this.dataJSON.filter((item) => item.statut !== 'ARCHIVEE');
          const cmpName = testedCmp.map((item) => item.nom);
          console.log('not archived cmp =', cmpName);

          this.dataTable = this.transformData();
          this.detectorRef.markForCheck();
          this.lenght = result.totalRecords;
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }

  transformData() {
    let dataCampagne: any[] = [];

    for (let key in this.dataJSON) {
      let dataJ = this.dataJSON[key];
      let _data: any = {};

      _data['id'] = dataJ?.id;

      let _type: any = {
        A_VENIR: 'Non ouverte',
        SAISIE_EN_COURS: 'Saisie',
        VALIDATION_EN_COURS: 'Validation',
        TERMINEE: 'Archivage',
        ARCHIVEE: 'Clôturée'
      };
      _data['Type'] = _type[dataJ?.type] || '';
      _data['Nom'] = dataJ['nom'];
      let emetteur = '';
      if (dataJ?.emetteur?.nom && dataJ?.emetteur?.prenom) {
        emetteur = dataJ?.emetteur?.nom + ' ' + dataJ?.emetteur?.prenom;
      }
      _data['Auteur'] = emetteur;
      _data['Date création'] = this.pipe.transform(dataJ?.dateCreation, 'shortDate');
      _data['Date début saisie'] = this.pipe.transform(dataJ?.dateDebutSaisie, 'shortDate');
      _data['Date fin saisie'] = this.pipe.transform(dataJ?.dateFinSaisie, 'shortDate');
      _data['Date début validation'] = this.pipe.transform(dataJ?.dateDebutValidation, 'shortDate');
      _data['Date début validation DG'] = this.pipe.transform(dataJ?.dateDebutValidationDg, 'shortDate');
      _data['Date fin validation'] = this.pipe.transform(dataJ?.dateFinValidation, 'shortDate');
      _data['Date d’archivage'] = this.pipe.transform(dataJ?.dateArchivage, 'shortDate');
      _data['Commentaire'] = dataJ?.commentaire;
      _data['Email DRH envoyé'] = dataJ?.flagEmailRhEnvoye ? 'Oui' : 'Non';
      _data['Email création envoyé'] = dataJ?.flagEmailCreationEnvoye ? 'Oui' : 'Non';
      _data['Email début saisie envoyé'] = dataJ?.flagEmailDebutSaisieEnvoye ? 'Oui' : 'Non';
      _data['Email début validation envoyé'] = dataJ?.flagEmailDebutValidationEnvoye ? 'Oui' : 'Non';
      _data['Email début validation DG envoyé'] = dataJ?.flagEmailDebutValidationDgEnvoye ? 'Oui' : 'Non';
      _data['Email fin validation envoyé'] = dataJ?.flagEmailFinValidationEnvoye ? 'Oui' : 'Non';

      let statut = dataJ?.statut;
      let statut_text: string = '';
      let statut_class: string = '';

      // A venir : date courante < date de début de saisie
      if (statut == 'A_VENIR') {
        statut_text = 'A venir';
        statut_class = 'statut-a-venir';
      }
      // Saisie en cours : date de début de saisie <= date courante <= date de fin de saisie
      else if (statut == 'SAISIE_EN_COURS') {
        statut_text = 'Saisie en cours';
        statut_class = 'statut-en-cours';
      }
      // Validation en attente : date de fin de saisie < date courante < date de début de validation
      else if (statut == 'VALIDATION_EN_ATTENTE') {
        statut_text = 'Validation en attente';
        statut_class = 'statut-validation-en-attente';
      }
      // Validation en cours :  date de début de validation <= date courante <= date de fin de validation
      else if (statut == 'VALIDATION_EN_COURS') {
        statut_text = 'Validation en cours';
        statut_class = 'statut-validation-en-cours';
      }
      // Terminée : date de fin de validation < date courante <= date d’archivage
      else if (statut == 'TERMINEE') {
        statut_text = 'Terminée';
        statut_class = 'statut-termine';
      }
      // Archivée : date d’archivage < date courante
      else if (statut == 'ARCHIVEE') {
        statut_text = 'Archivée';
        statut_class = 'statut-archive';
      }

      if (statut_text != '' && statut_class != '') {
        _data['Statut'] = { class: statut_class, text: statut_text, object: true };
      }
      // une campagne archivée et terminée ne peuvent pas être modifiées
      _data['editer_hidden'] = false;

      _data['editer_hidden'] = statut == 'ARCHIVEE' ? true : _data['editer_hidden'];
      _data['editer_hidden'] = statut == 'TERMINEE' ? true : _data['editer_hidden'];

      _data['delete_hidden'] = false;

      _data['delete_hidden'] = statut == 'TERMINEE' ? true : _data['delete_hidden'];
      _data['delete_hidden'] = statut == 'ARCHIVEE' ? true : _data['delete_hidden'];

      /**
       * Contrôle : si date de début <= maintenant alors pas possible de supprimer
       */
      _data['delete_hidden'] =
        dataJ?.dateDebutSaisie && new Date(dataJ?.dateDebutSaisie).getTime() < new Date().getTime() ? true : _data['delete_hidden'];

      // provisoire
      _data['dateCreation'] = dataJ?.['dateCreation'] != null ? dataJ?.dateCreation : '';

      dataCampagne.push(_data);
    }

    return dataCampagne;
  }

  openExportDialog() {
    this.dialog.open(ExportModalComponent, {
      width: '600px'
    });
  }

  // modale de création
  openCampagneDialog() {
    const dialogRef = this.dialog.open(CampagneModalComponent, {
      width: '780px',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data?.action) {
        this.loadCampagnes();
        this.detectorRef.markForCheck();
        this.broadcast.broadcastEvent({
          broadcastedevent: 'resetPagination'
        });
      }
    });
  }

  // modale de modification
  openCampagneEdit(id: number) {
    this.searchDataID(this.dataJSON, id);

    const dialogRef = this.dialog.open(CampagneModalComponent, {
      width: '780px',
      disableClose: true,
      data: { edition: true, ...this.rowCampagne }
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data?.action) {
        this.loadCampagnes();
        this.detectorRef.markForCheck();
        this.broadcast.broadcastEvent({
          broadcastedevent: 'resetPagination'
        });
      }
    });
  }

  // recherche la ligne data correspondant à l'id
  searchDataID(data: any, id: number) {
    let index = Object.keys(data).find((k) => data[k].id === id);

    if (index != null) {
      this.rowCampagne = this.dataJSON[Number(index)];
    }
  }

  deleteCampagne(id: number) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '780px',
      data: {
        id: id,
        description: 'Voulez-vous supprimer cette campagne ?',
        url: 'test'
      }
    });

    if (id != undefined && id != null) {
      dialogRef.afterClosed().subscribe((action) => {
        // lance la suppression
        if (action) {
          let data_id: number = id;

          this.campagneService
            .supprimerCampagne(data_id)
            .pipe(take(1))
            .subscribe({
              next: (result) => {
                this.dialog.open(ValidationModalComponent, {
                  width: '600px',
                  data: {
                    title: 'Confirmation',
                    content: 'La campagne a été supprimée avec succès.'
                  }
                });
                this.loadCampagnes();
                this.detectorRef.markForCheck();
              },
              error: (error) => {
                let erreur_texte = error.error.detail || error.error || error;

                this.dialog.open(AlerteModalComponent, {
                  width: '640px',
                  data: {
                    title: 'Alerte',
                    content: "Erreur lors de la suppression de l'enregistrement !",
                    //erreur: error.message,
                    status: 'STATUS: ' + error.status,
                    detail: 'DETAIL: ' + erreur_texte
                  }
                });
              }
            });
        }
      });
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  updatePagination(event: any) {
    if (event) {
      this.campagneService
        .getCampagnesWithParams(event.offset, event.limit, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.dataJSON = result.results;
            this.dataTable = this.transformData();
            this.detectorRef.markForCheck();
            this.lenght = result.totalRecords;
          },
          error: (error) => {}
        });
    } else {
      this.campagneService
        .getCampagnesWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.dataJSON = result.results;
            this.dataTable = this.transformData();
            this.detectorRef.markForCheck();
            this.lenght = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }
  updateFiltreExport(value: any) {
    this.filterValue = value.filterValue;
    this.updatePagination(value);
  }

  openCampagneNotifsModale(id: number) {
    this.searchDataID(this.dataJSON, id);

    const dialogRef = this.dialog.open(CampagneNotificationsComponent, {
      width: '780px',
      disableClose: false,
      data: { edition: true, ...this.rowCampagne }
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data?.action) {
        this.loadCampagnes();
        this.detectorRef.markForCheck();
        this.broadcast.broadcastEvent({
          broadcastedevent: 'resetPagination'
        });
      }
    });
  }
}
