import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { AgentDelModalComponent } from './agent-del-modal/agent-del-modal.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { ArianeNavComponent } from 'src/app/layout/ariane-nav/ariane-nav.component';
import { DeleteModalComponent } from 'src/app/shared/delete-modal/delete-modal.component';
import localeFr from '@angular/common/locales/fr';
import { RoleService } from 'src/app/services/role.service';
import { RestrictedPageComponent } from 'src/app/shared/restricted-page/restricted-page.component';
registerLocaleData(localeFr);

import { DeleguesService } from 'src/app/services/delegues.service';
import { take, Subscription } from 'rxjs';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { environment } from 'src/environments/environment';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { CommunicationService } from 'src/app/shared/communication.service';
import { Broadcast } from 'src/app/core/services/broadcast.service';

@Component({
  selector: 'app-delegation-modal',
  standalone: true,
  imports: [
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    CommonModule,
    DataTableComponent,
    MatExpansionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    MatDividerModule,
    ArianeNavComponent,
    RestrictedPageComponent,
    MatCardModule,
    AgentDelModalComponent
  ],
  templateUrl: './delegation-modal.component.html',
  styleUrls: ['./delegation-modal.component.scss']
})
export class DelegationModalComponent implements OnInit {
  @ViewChild('appDataTable') appDataTable: DataTableComponent;
  panelOpenState: boolean = false;
  pipe = new DatePipe('fr-FR'); // Use your own locale
  date_deb = this.pipe.transform(new Date(), 'short');
  date_fin = this.pipe.transform(new Date(), 'short');
  rowDelegue: any = {};
  dataJSON: any[] = [];
  lenght: number;
  firstName: string;
  lastname: string;
  agentId: number;
  viewMode: 'read' | 'edit' | 'user';
  togglePanel() {
    console.log('clicked');
    this.panelOpenState = !this.panelOpenState;
  }

  userRoles: string[] = [];
  delegationList: any[] = [];
  DebutDates: any;
  FinDates: any;
  fullName: string;
  matricule: string;
  userName: string;
  userId: any;
  delegueRow: any = {};
  filterValue: string = '';
  private subscription: Subscription;

  constructor(
    public dialog: MatDialog,
    public agentDialog: MatDialog,
    public dialogRef: MatDialogRef<DelegationModalComponent>,
    private notifyService: CommunicationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private roleService: RoleService,
    private delegationService: DeleguesService,
    private detectorRef: ChangeDetectorRef,
    private broadcast: Broadcast
  ) {}

  ngOnInit() {
    this.roleService.getRole().subscribe((roles) => {
      this.userRoles = roles;
      console.log('Role', roles);
    });

    this.agentId = this.data.agentId;

    this.userId = this.data.idUser;

    this.userName = this.data.name;
    this.firstName = this.data.agentName;
    this.lastname = this.data.agentPrenom;
    this.fullName = this.firstName + ' ' + this.lastname;
    this.matricule = this.data.agentMatricule;

    this.loadDelegationList();

    if (this.data.viewMode) {
      this.viewMode = this.data.viewMode;
    }
  }

  transformData() {
    let dataDelegue: any[] = [];

    for (let key in this.dataJSON) {
      let dataJ = this.dataJSON[key];
      let _data: any = {};

      _data['id'] = dataJ?.id;

      _data['Nom'] = dataJ['nom'];
      _data['Date de début'] = this.pipe.transform(dataJ?.dateDebut, 'shortDate');
      _data['Date de fin'] = this.pipe.transform(dataJ?.dateFin, 'shortDate');
      _data["Nom de l'Agent"] = dataJ?.remplacant?.nom + ' ' + dataJ?.remplacant?.prenom;

      dataDelegue.push(_data);
    }

    return dataDelegue;
  }

  displayedColumns: string[] = ['Date de début', 'Date de fin', "Nom de l'Agent", 'Actions'];
  displayedViewColumns: string[] = ['Date de début', 'Date de fin', "Nom de l'Agent"];
  displayedUserColumns: string[] = ['Date de début', 'Date de fin', "Nom de l'Agent", 'Actions'];

  cellStyleCss: any = {};

  loadDelegationList() {
    if (this.data.viewMode === 'user') {
      this.delegationService
        .getMesDelegues()
        .pipe(take(1))
        .subscribe((data: any) => {
          if (data) {
            this.dataJSON = data.results;
            this.delegationList = this.transformData();

            this.DebutDates = this.delegationList.map((date) => date['Date de début']);
            console.log('dates from List', this.DebutDates);
            this.FinDates = this.delegationList.map((date) => date['Date de fin']);
            console.log('dates from List', this.FinDates);
            this.detectorRef.markForCheck();
            this.lenght = data.totalRecords;

            /**
             * Communication du nouveau nombre de délégués
             */
            this.notifyService.setNbAgent(this.lenght);
            this.notifyService.triggerRefresh();
          } else {
            console.log('error');
          }
        });
    } else {
      this.delegationService
        .getDeleguesAgent(this.data.agentId)
        .pipe(take(1))
        .subscribe((data: any) => {
          if (data) {
            this.dataJSON = data.results;
            this.delegationList = this.transformData();

            this.DebutDates = this.delegationList.map((date) => date['Date de début']);
            console.log('dates from List', this.DebutDates);
            this.FinDates = this.delegationList.map((date) => date['Date de fin']);
            console.log('dates from List', this.FinDates);
            this.detectorRef.markForCheck();
            this.lenght = data.totalRecords;

            /**
             * Communication du nouveau nombre de délégués
             */
            this.notifyService.setNbAgent(this.lenght);
            this.notifyService.triggerRefresh();
          } else {
            console.log('error');
          }
        });
    }
  }

  openAgentDialog() {
    this.dialog
      .open(AgentDelModalComponent, {
        width: '780px',
        hasBackdrop: true,
        disableClose: true,
        autoFocus: false,
        data: {
          remplaceId: this.data.agentId,
          Dates: this.DebutDates,
          FinDates: this.FinDates,
          DelegationAgent: this.delegationList,
          agentMatricule: this.matricule,
          idUser: this.userId,
          agentId: this.agentId,
          nameUser: this.userName,
          // nameUser: this.firstName,
          view: this.data.viewMode
        }
      })
      .afterClosed()
      .subscribe((result) => {
        // Refresh de la liste des délégués
        this.loadDelegationList();
        this.broadcast.broadcastEvent({
          broadcastedevent: 'resetPagination'
        });
      });
  }

  deleteDelegue(id: number, mesDelegues = true) {
    console.log('event id', id);
    let role = this.roleService.getRole();
    console.log('roleee', role);

    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '780px',
      data: {
        id: id,
        description: 'Voulez-vous supprimer le délégué ?',
        url: 'test'
      }
    });

    dialogRef.afterClosed().subscribe((action) => {
      console.log('Action after close', action);
      if (action === true && this.data.view !== 'user') {
        if (this.userRoles.includes('dhesa-admin') && !mesDelegues) {
          let data_id: number = id;
          console.log('req 1');

          this.delegationService
            .supprimerDelegue(data_id)
            .pipe(take(1))
            .subscribe({
              next: (result) => {
                console.log('result ok: ', result);
                this.dialog.open(ValidationModalComponent, {
                  width: '600px',

                  data: {
                    title: 'Confirmation',
                    content: 'La délégation a été supprimée avec succès.'
                  }
                });
                this.loadDelegationList();
                this.detectorRef.markForCheck();
              },
              error: (error) => {
                let erreur_texte = error.error.detail || error.error || error;

                this.dialog.open(AlerteModalComponent, {
                  width: '640px',
                  data: {
                    title: 'Alerte',
                    content: "Erreur lors de la suppression de l'enregistrement !",

                    status: 'STATUS: ' + error.status,
                    detail: 'DETAIL: ' + erreur_texte
                  }
                });
              }
            });
        } else {
          if (
            this.userRoles.includes('dhesa-assistant-cds') ||
            this.userRoles.includes('dhesa-admin') ||
            this.userRoles.includes('dhesa-dg') ||
            this.userRoles.includes('dhesa-directeur') ||
            this.userRoles.includes('dhesa-responsable') ||
            this.userRoles.includes('dhesa-assistant-responsable') ||
            this.userRoles.includes('dhesa-assistant-directeur') ||
            this.userRoles.includes('dhesa-gestionnaire-paie')
          ) {
            let data_id: number = id;
            console.log('req 2');

            this.delegationService
              .supprimerMonDelegue(data_id)
              .pipe(take(1))
              .subscribe({
                next: (result) => {
                  console.log('result ok: ', result);
                  this.dialog.open(ValidationModalComponent, {
                    width: '600px',
                    data: {
                      title: 'Confirmation',
                      content: 'Le délégué a été supprimé avec succès.'
                    }
                  });
                  this.loadDelegationList();
                  this.detectorRef.markForCheck();
                },
                error: (error) => {
                  let erreur_texte = error.error.detail || error.error || error;

                  this.dialog.open(AlerteModalComponent, {
                    width: '640px',
                    data: {
                      title: 'Alerte',
                      content: "Erreur lors de la suppression de l'enregistrement !",

                      status: 'STATUS: ' + error.status,
                      detail: 'DETAIL: ' + erreur_texte
                    }
                  });
                }
              });
          }
        }
      }
    });
  }

  updatePagination(event: any) {
    if (event) {
      console.log('delagation', event);

      this.delegationService
        .getDeleguesWithParams(event.offset, event.limit, this.agentId, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.dataJSON = result.results;
            this.delegationList = this.transformData();
            this.detectorRef.markForCheck();
            this.lenght = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.delegationService
        .getDeleguesWithParams(environment.pagination.offset, environment.pagination.limit, this.agentId)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.dataJSON = result.results;
            this.delegationList = this.transformData();
            this.detectorRef.markForCheck();
            this.lenght = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }

  delegueDataId(data: any, id: number) {
    let index = Object.keys(data).find((k) => data[k].id === id);

    if (index != null) {
      this.delegueRow = data[Number(index)];
    }
  }
  modifyDelegate(id: any) {
    this.delegueDataId(this.dataJSON, id);
    this.agentDialog
      .open(AgentDelModalComponent, {
        width: '780px',
        hasBackdrop: true,
        disableClose: true,
        autoFocus: false,
        data: {
          remplaceId: this.data.agentId,
          Dates: this.DebutDates,
          FinDates: this.FinDates,
          DelegationAgent: this.delegationList,
          agentMatricule: this.matricule,
          idUser: this.userId,
          nameUser: this.userName,
          view: this.data.viewMode,
          delegue: this.delegueRow,
          modif: true
        }
      })
      .afterClosed()
      .subscribe((result) => {
        console.log(`Dialog result: ${result}`);
        this.loadDelegationList();
      });
  }

  onBackToAgentList() {
    console.log('pump route test with close');
    this.dialogRef.close();
    this.notifyService.setNbAgent(this.lenght);
    this.notifyService.triggerRefresh();
  }
  updateFiltre(value: any) {
    this.filterValue = value.filterValue;
    this.updatePagination(value);
  }
}
