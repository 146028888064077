<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-6">
      <mat-card-title><h2 class="pl-20">Edition des astreintes</h2></mat-card-title>
    </div>

    <div class="col-md-6 text-end mb-2 mr-0 ml-auto gx-0">
      <button (click)="cancelDialog()" class="py-0" mat-icon-button aria-label="Fermer la modale">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>

<mat-dialog-content class="mat-typography">
  <ng-container>
    <form [formGroup]="form_astreinte">
      <div class="row g-3">
        <div class="col-md-4">
          <mat-form-field floatLabel="always" style="width: 100%">
            <mat-label>Type d'astreinte</mat-label>
            <mat-select (selectionChange)="changeType($event)" formControlName="type" id="type" name="type">
              <!--mat-option value="JOUR">Jour</mat-option-->
              <mat-option value="NUIT">Nuit</mat-option>
              <mat-option value="SEMAINE">Semaine</mat-option>
              <mat-option value="WEEKEND">Week-end</mat-option>
              <mat-option value="SAMEDI">Samedi</mat-option>
              <mat-option value="DIMANCHE_FERIE">Dimanche / jour férié</mat-option>
              <mat-option value="CRECHE">Crèche</mat-option>
            </mat-select>
            <mat-error *ngIf="form_astreinte.get('type')?.hasError('required')"> Le type est <strong>obligatoire</strong> </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-8" style="display: none">
          <mat-form-field floatLabel="always" style="width: 100%" class="field-select-disabled">
            <mat-label>Direction organisatrice</mat-label>
            <mat-select formControlName="directionOrganisatriceId" [(value)]="selectedDirection" matNativeControl>
              <mat-option *ngFor="let item of directionList" [value]="item.id">{{ item.libelle }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form_astreinte.get('directionOrganisatriceId')?.hasError('required')">
              L'organisation organisatrice est <strong>obligatoire</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <mat-divider style="border-top-width: 3px" class="mb-3 mt-2"></mat-divider>

      <div class="row g-0 mt-0">
        <div class="col-md-4">
          <mat-form-field class="champ-disabled" floatLabel="always">
            <mat-label>Agent</mat-label>
            <input class="pt-0" matInput formControlName="agent" />
            <mat-error *ngIf="!selectedAgent"> Agent est <strong>obligatoire</strong> </mat-error>
          </mat-form-field>
        </div>

        <ng-container>
          <div class="col-md-4">
            <mat-form-field class="champ-disabled" style="padding-left: 20px" floatLabel="always">
              <mat-label>Direction d'origine </mat-label>
              <input class="pt-0" matInput formControlName="direction_origine" />
              <mat-error *ngIf="!selectedAgentDirection"> Direction d'origine est <strong>obligatoire</strong> </mat-error>
            </mat-form-field>
          </div>
        </ng-container>

        <div class="col-md-4">
          <mat-form-field class="champ-disabled" style="padding-left: 20px" floatLabel="always">
            <mat-label>Service </mat-label>
            <input class="pt-0" matInput formControlName="service" />
            <mat-error *ngIf="!selectedAgentService"> Agent service est <strong>obligatoire</strong> </mat-error>
          </mat-form-field>
        </div>
      </div>

      <mat-divider style="border-top-width: 3px" class="mb-3 mt-2"></mat-divider>

      <div class="row g-0 mt-0" style="margin-bottom: 23px">
        <mat-form-field style="width: 25%" floatLabel="always">
          <mat-label>Date début </mat-label>
          <input
            matInput
            [matDatepicker]="dateDebutPicker"
            [min]="minDateDebut"
            [max]="maxDateDebut"
            [matDatepickerFilter]="dateDebutFilter"
            formControlName="date_debut"
            id="date_debut"
            placeholder="JJ/MM/AAAA"
            (dateChange)="changeDateAstreinte($event)"
          />
          <mat-datepicker-toggle matIconSuffix [for]="dateDebutPicker"></mat-datepicker-toggle>
          <mat-datepicker #dateDebutPicker></mat-datepicker>
          <mat-error *ngIf="form_astreinte.get('date_debut')?.hasError('required')">
            Date début est <strong>obligatoire</strong>
          </mat-error>
          <mat-error *ngIf="form_astreinte.controls['date_debut'].hasError('erreurDateAstreinte')"
            >La date de début ne peut être supérieure ou égale à la date de fin</mat-error
          >
          <mat-error *ngIf="form_astreinte.get('date_debut')?.hasError('matDatepickerMax')"
            >La date maximum autorisée est le
            {{ form_astreinte.get('date_debut')?.errors?.['matDatepickerMax']['max'] | date: 'dd/MM/yyyy' }}</mat-error
          >
          <mat-error *ngIf="form_astreinte.get('date_debut')?.hasError('matDatepickerMin')"
            >La date minimum autorisée est le
            {{ form_astreinte.get('date_debut')?.errors?.['matDatepickerMin']['min'] | date: 'dd/MM/yyyy' }}</mat-error
          >
        </mat-form-field>

        <mat-form-field style="width: 25%; margin-left: 20px" floatLabel="always">
          <mat-label>Date fin </mat-label>
          <input
            matInput
            [matDatepicker]="dateFinPicker"
            [min]="minDateFin"
            [max]="maxDateFin"
            [matDatepickerFilter]="dateFinFilter"
            formControlName="date_fin"
            [readonly]="dateFinRO"
            id="date_fin"
            placeholder="JJ/MM/AAAA"
            (dateChange)="changeDateAstreinte($event)"
          />
          <mat-datepicker-toggle matIconSuffix [for]="dateFinPicker" *ngIf="!dateFinRO"></mat-datepicker-toggle>
          <mat-datepicker #dateFinPicker></mat-datepicker>
          <mat-error *ngIf="form_astreinte.get('date_fin')?.hasError('required')"> Date fin est <strong>obligatoire</strong> </mat-error>
          <mat-error *ngIf="form_astreinte.controls['date_fin'].hasError('erreurDateAstreinte')"
            >La date de fin ne peut être inférieure ou égale à la date de début</mat-error
          >
          <mat-error *ngIf="form_astreinte.get('date_fin')?.hasError('matDatepickerMax')"
            >La date maximum autorisée est le
            {{ form_astreinte.get('date_fin')?.errors?.['matDatepickerMax']['max'] | date: 'dd/MM/yyyy' }}</mat-error
          >
          <mat-error *ngIf="form_astreinte.get('date_fin')?.hasError('matDatepickerMin')"
            >La date minimum autorisée est le
            {{ form_astreinte.get('date_fin')?.errors?.['matDatepickerMin']['min'] | date: 'dd/MM/yyyy' }}</mat-error
          >
        </mat-form-field>

        <mat-form-field style="width: 25%; margin-left: 20px" floatLabel="always" class="champ-disabled">
          <mat-label>Nombre </mat-label>
          <input type="number" matInput formControlName="nombre" [readonly]="isNombreRO" min="1" />
          <mat-error *ngIf="form_astreinte.get('nombre')?.hasError('required')"> Le nombre est <strong>obligatoire</strong> </mat-error>
          <mat-error *ngIf="form_astreinte.get('nombre')?.hasError('min')"> Le nombre doit être supérieur à 1</mat-error>
        </mat-form-field>
      </div>
    </form>

    <mat-divider style="border-top-width: 3px" class="mb-3 mt-2"></mat-divider>

    <h5 class="text-footer">Les éléments marqués d'une étoile (*) sont obligatoires</h5>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions style="background-color: rgba(250, 250, 252, 1)" align="end">
  <button mat-stroked-button mat-dialog-close class="mx-3" color="primary">Annuler</button>
  <button [disabled]="form_astreinte.invalid" mat-flat-button color="primary" (click)="validerAstreinte()">Valider</button>
</mat-dialog-actions>
