<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-6">
      <mat-card-title><h2 class="pl-20">Modifier ses notifications</h2></mat-card-title>
    </div>

    <div class="col-md-6 text-end mb-2 mr-0 ml-auto gx-0">
      <button (click)="onClose()" class="close-icon" class="py-0" mat-icon-button aria-label="Fermer la modale">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<mat-dialog-content class="mat-typography suppression-scroll">
  <form [formGroup]="edit_form" class="row">
    <div class="row g-0 mt-0">
      <div class="col-md-6" style="margin-top: -8px; flex: 0 0 auto; width: 100%">
        <mat-label class="label-etat">Recevoir les notifications emails envoyées par l'application</mat-label>
        <div style="margin-top: 20px">
          <mat-checkbox color="primary" formControlName="flagNotifsAll">Activer les notifications</mat-checkbox>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="background-color: rgba(250, 250, 252, 1)" align="end">
  <button mat-stroked-button mat-dialog-close class="mx-3" color="primary">Annuler</button>
  <button (click)="saveAgent()" mat-flat-button color="primary" [disabled]="edit_form.invalid">Valider</button>
</mat-dialog-actions>
