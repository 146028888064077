import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { CreateAstreinteModel } from '../models/astreintes.model';

@Injectable({
  providedIn: 'root'
})
export class AstreintesService {
  module = environment.webService.server + environment.webService.url + environment.webService.api;
  constructor(
    private http: HttpClient,
    private broadcast: Broadcast
  ) {}

  //get heuresSupplémaentaires
  getAstreintesWithParams(offset: number, limit: number, filtre: string | null) {
    filtre = filtre || '';
    const params = new HttpParams().set('page', offset).set('size', limit);
    let url = this.module + '/astreintes';
    if (filtre != '') {
      url = url + '?' + filtre;
    }
    return this.http.get<any>(url, { params });
  }

  getAllAstreintes() {
    return this.http.get<any>(this.module + '/astreintes');
  }

  // Astreintes archivées
  getAstreinteArchive() {
    return this.http.get<any>(this.module + '/astreintes/archived');
  }

  // Astreintes archivées + filtres
  getAstreinteArchiveWithParams(offset: number, limit: number, filtre: string | null) {
    filtre = filtre || '';
    const params = new HttpParams().set('page', offset).set('size', limit);
    let url = this.module + '/astreintes/archived';
    if (filtre != '') {
      url = url + '?' + filtre;
    }
    return this.http.get<any>(url, { params });
  }

  // creation HS
  createAstreinte(data: CreateAstreinteModel) {
    let obj = this.http.post<any>(this.module + '/astreintes', data);
    setTimeout(() => {
      this.broadcast.broadcastEvent('reloadNotification');
    }, 1000);
    return obj;
  }

  // Bulk creation
  createAstreinteBulk(data: CreateAstreinteModel[]) {
    let obj = this.http.post<any>(this.module + '/astreintes/bulk', data);
    setTimeout(() => {
      this.broadcast.broadcastEvent('reloadNotification');
    }, 1000);
    return obj;
  }

  // modification HS
  modifieAstreinte(data: any, id: number) {
    return this.http.put<any>(this.module + '/astreintes/' + id, data);
  }

  // Delete HS
  deleteAstreinte(id: number) {
    let obj = this.http.delete(this.module + '/astreintes/' + id);
    setTimeout(() => {
      this.broadcast.broadcastEvent('reloadNotification');
    }, 1000);
    return obj;
  }

  // supprimer plusieurs lignes à la fois
  deleteAstreinteBulk(data: any) {
    let obj = this.http.delete(this.module + '/astreintes/bulk', { body: data });
    setTimeout(() => {
      this.broadcast.broadcastEvent('reloadNotification');
    }, 1000);
    return obj;
  }

  // Agent Direction
  getAgentDirectionAstrainte() {
    return this.http.get<any>(this.module + '/astreintes/totalAstreintes-par-agent');
  }

  // Totaux Heures Supplémentaire
  getTotauxAstrainte() {
    return this.http.get<any>(this.module + '/astreintes/totalAstreintes-par-direction');
  }

  // Agent Direction
  getAgentDirection() {
    return this.http.get<any>(this.module + '/astreintes/liste-agents-par-direction');
  }

  // Get PDF Astreintes
  getPdfAstreinte(id: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get(this.module + '/astreintes/' + id + '/pdf', httpOptions);
  }
}
