<app-ariane-nav [label]="arianeLabel" [icon]="arianeIcon" [arianeList]="this.arianeList"></app-ariane-nav>

<mat-divider class="my-3"></mat-divider>

<div class="row mt-3">
  <div class="col flex-container end align-items-center">
    <div>
      <button style="display: none" mat-stroked-button class="bt-table-defaut mr-5" (click)="openExportDialog()">
        <mat-icon>download</mat-icon>Exporter
      </button>

      <button style="display: none" mat-stroked-button class="bt-table-defaut mr-5" (click)="togglePanel()">
        <mat-icon>filter_alt</mat-icon>
        <span *ngIf="!panelOpenState"> Filtres avancés </span>
        <span *ngIf="panelOpenState"> Liste des filtres </span>
      </button>

      <button mat-raised-button class="bt-table-defaut bt-table-ajouter mr-5 mb-3" (click)="openCampagneDialog()">
        <mat-icon>add</mat-icon> Ajouter campagne
      </button>
    </div>
  </div>
</div>

<div class="mt-1">
  <mat-expansion-panel [expanded]="panelOpenState">
    <div class="div-champ-filtre">
      <div class="div-field">
        <mat-form-field class="field-filtre field-filtre-simple">
          <mat-label>Filtrer</mat-label>
          <input matInput (keyup)="appDataTable.applyFilter($event)" />
        </mat-form-field>
      </div>
      <div class="div-field">
        <mat-form-field class="field-filtre">
          <mat-label>Date début</mat-label>
          <input matInput [matDatepicker]="picker" placeholder="MM/DD/YYYY" />
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="div-field">
        <mat-form-field class="field-filtre">
          <mat-label>Date fin</mat-label>
          <input matInput [matDatepicker]="picker_2" placeholder="MM/DD/YYYY" />
          <mat-datepicker-toggle matIconSuffix [for]="picker_2"></mat-datepicker-toggle>
          <mat-datepicker #picker_2></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="div-field">
        <mat-form-field class="field-filtre">
          <mat-label>Type d'heure</mat-label>
          <mat-select>
            <mat-option>None</mat-option>
            <mat-option value="option1">Option 1</mat-option>
            <mat-option value="option2">Option 2</mat-option>
            <mat-option value="option3">Option 3</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="div-field">
        <mat-form-field class="field-filtre">
          <mat-label>Campagne</mat-label>
          <mat-select>
            <mat-option>None</mat-option>
            <mat-option value="option1">Option 1</mat-option>
            <mat-option value="option2">Option 2</mat-option>
            <mat-option value="option3">Option 3</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="div-field">
        <mat-form-field class="field-filtre">
          <mat-label>Emetteur</mat-label>
          <mat-select>
            <mat-option>None</mat-option>
            <mat-option value="option1">Option 1</mat-option>
            <mat-option value="option2">Option 2</mat-option>
            <mat-option value="option3">Option 3</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center">
      <div>
        <button mat-stroked-button class="mr-5" style="height: 40px">
          <mat-icon>trash</mat-icon>
          Effacer les filtres
        </button>

        <button mat-raised-button class="mr-5 bt-table-ajouter" style="height: 40px; width: 100px">Filtrer</button>
      </div>
    </div>
  </mat-expansion-panel>
</div>

<app-data-table
  #appDataTable
  [displayedColumns]="campagneDisplayedColumns"
  [cellStyleCss]="campagneCellStyleCss"
  [data]="dataTable"
  [filter]="false"
  [expendable]="false"
  [editable]="true"
  [delatable]="true"
  (deleteElement)="deleteCampagne($event)"
  (clicModalModifier)="openCampagneEdit($event)"
  [length]="lenght"
  (updatePagination)="updatePagination($event)"
  (updateFiltre)="updateFiltreExport($event)"
  [customFilter]="true"
  [hasView]="true"
  (showDetail)="openCampagneNotifsModale($event)"
  zoomTooltip="Gérer les notifications"
  zoomIcon="mail"
></app-data-table>
