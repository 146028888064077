import { Component, OnInit, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormsModule, ReactiveFormsModule, FormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { AgentsService } from 'src/app/services/agents.service';
import { delay } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-edit-agent',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDividerModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    DragDropModule
  ],
  templateUrl: './edit-agent.component.html',
  styleUrls: ['./edit-agent.component.scss']
})
export class EditAgentComponent implements OnInit {
  edit_form: FormGroup;

  autoComplete: MatAutocompleteTrigger;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditAgentComponent>,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,

    private agentService: AgentsService
  ) {
    this.edit_form = this.formBuilder.group({
      nom: [this.data.rowElement.nom, [Validators.required]],
      prenom: [this.data.rowElement.prenom, [Validators.required]],
      email: [
        this.data.rowElement.email,
        [Validators.required, Validators.email, Validators.pattern('^[a-z0-9-zA-Z._%+-]+@[a-z0-9-zA-Z.-]+\\.[a-zA-Z]{2,4}$')]
      ],
      login: [this.data.rowElement.login, [Validators.required]],
      actif: [this.data.rowElement.actif],
      verrouAdmin: [this.data.rowElement.verrouAdmin],
      flagNotifsAll: [this.data.rowElement.flagNotifsAll]
    });
  }

  ngOnInit() {
    console.log('from dialog', this.data.rowElement);
    console.log('FormData', this.edit_form.value);
  }
  onClose() {
    this.dialogRef.close();
  }

  saveAgent() {
    if (this.edit_form.valid) {
      const id = this.data.id;
      const data = this.edit_form.value;
      this.data.rowElement.nom = data.nom;
      this.data.rowElement.prenom = data.prenom;
      this.data.rowElement.email = data.email;
      this.data.rowElement.login = data.login;
      this.data.rowElement.actif = data.actif;
      this.data.rowElement.verrouAdmin = data.verrouAdmin;
      this.data.rowElement.flagNotifsAll = data.flagNotifsAll;

      this.agentService
        .ModifierAgent(this.data.rowElement, id)
        .pipe(delay(1000))
        .subscribe({
          next: (result) => {
            this.dialog
              .open(ValidationModalComponent, {
                width: '600px',
                data: {
                  title: 'Confirmation',
                  content: 'L’agent a été modifié avec succès.'
                }
              })
              .afterClosed()
              .subscribe((action) => {
                if (action) {
                  this.dialogRef.close('validation');
                }
              });
          }
        });
    } else {
      console.log('Form is not valid');
    }
  }
}
