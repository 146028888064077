import { Component, Inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormsModule, ReactiveFormsModule, FormGroup, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CustomDateAdapter } from 'src/app/shared/utils/custom-date-adapter';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { take } from 'rxjs';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DirectionService } from 'src/app/services/direction.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EvenementsService } from 'src/app/services/evenements.service';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-campagne-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatRadioModule,
    MatIconModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatAutocompleteModule,
    ValidationModalComponent,
    ConfirmModalComponent,
    MatNativeDateModule,
    MatCheckboxModule,
    DragDropModule
  ],
  templateUrl: './evenement-modal.component.html',
  styleUrls: ['./evenement-modal.component.scss'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }]
})
export class EvenementModalComponent {
  rowData: any = {};
  dateP = new DatePipe('fr-FR');
  action: string = '';
  titre_action: string = 'Ajouter';
  selectedType: string = '';
  dataCampagne: any = '';
  texte_erreur_date: string = '';
  minDateDebutSaisie: Date;
  minDateFinSaisie: Date;
  minDateDebutValidation: Date;
  minDateFinValidation: Date;
  minDateArchivage: Date;
  directionList: any[] = [];
  listDirection: any = [];
  val_direction: any = null;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EvenementModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private evenementsService: EvenementsService,
    private directionService: DirectionService
  ) {}

  form: FormGroup = this.formBuilder.group({
    libelle: [this.data['libelle'], [Validators.required]],
    direction: [''],
    numeroAffectation: [this.data['numeroAffectation'], [Validators.required]],
    actif: [this.data['actif']]
  });

  ngOnInit() {
    this.rowData = this.data;

    this.loadDirectionList();
    this.initForm();
  }

  // chargement des dates dans les datepicker
  initForm() {
    let _data = this.data;
    console.log('initForm _data:', _data);

    // par défaut : en mode ajout
    this.action = 'ajouter';

    if (Object.keys(_data).length > 0) {
      if (_data['edition']) {
        this.titre_action = 'Modifier';
        this.action = 'modifier';
      } else {
        this.action = 'ajouter';
      }
    }
  }

  loadDirectionList() {
    this.directionService
      .getAllDirections()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.listDirection = data.filter((o: any) => {
            if (o.libelle !== '') {
              return o;
            }
          });
          this.directionList = [...this.listDirection];

          this.form.controls['direction'].setValue(this.rowData?.direction);
        } else {
          console.log('error');
        }
      });
  }
  directionFilter(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.directionList = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.directionList = this.listDirection.filter((item: any) => item.libelle.toLowerCase().split(' ').join('').includes(filterValue));
  }
  directionDisplayFn(direction?: any): string {
    return direction ? direction.libelle : undefined;
  }

  fermerModale() {
    // modification
    if (this.data['edition']) {
      this.dialogRef.close({ action: false });
    }
    // création
    else {
      this.confirmQuitterModale();
    }
  }

  confirmQuitterModale() {
    // création uniquement
    if (!this.data['edition']) {
      if (this.form.pristine) {
        const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
          width: '600px',
          data: {
            title: 'Confirmation',
            content1: "Souhaitez vous abandonner la création de l'évènement ?",
            content2: 'Les données que vous avez saisies ne seront pas enregistrées.'
          }
        });

        dialogConfirm.afterClosed().subscribe((data) => {
          if (data) {
            this.dialogRef.close({ action: false });
          }
        });
      } else {
        this.dialogRef.close({ action: false });
      }
    }
  }

  validerForm() {
    if (this.action == 'ajouter') {
      this.ajouterEvenement();
    } else {
      this.modifierEvenement();
    }
  }

  // Ajouter une campagne
  ajouterEvenement() {
    let _data: any = {};

    _data['libelle'] = this.form.controls['libelle'].value;
    let direction = this.form.controls['direction'].value;
    _data['direction'] = direction?.id ? { id: direction?.id } : null;
    _data['numeroAffectation'] = this.form.controls['numeroAffectation'].value;
    _data['actif'] = this.form.controls['actif'].value;

    this.evenementsService
      .ajouterEvenement(_data)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          console.log('result ok: ', result);
          this.spinner.hide();
          this.dialogRef.close({ action: true });
          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: "L'évènenement a été créée avec succès."
            }
          });
        },
        error: (error) => {
          console.log('error:', error);

          let erreur_texte = error.error.detail || error.error || error.statusText || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Erreur lors de la création de l'évènenemnt !",
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  // Modifier une campagne
  modifierEvenement() {
    let _data: any = {};

    _data['id'] = this.rowData.id;
    _data['libelle'] = this.form.controls['libelle'].value;
    let direction = this.form.controls['direction'].value;
    _data['direction'] = direction?.id ? { id: direction.id } : null;
    _data['numeroAffectation'] = this.form.controls['numeroAffectation'].value;
    _data['actif'] = this.form.controls['actif'].value;

    this.evenementsService
      .modifierEvenement(_data, _data['id'])
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          console.log('result ok: ', result);
          this.spinner.hide();
          this.dialogRef.close({ action: true });
          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: "L'évènenement a été modifiée avec succès."
            }
          });
        },
        error: (error) => {
          console.log('error: ', error);
          let erreur_texte = error.error.detail || error.error || error.statusText || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: "Erreur lors de la modification de l'évènenemnt !",
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }
}
